import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Footer from 'components/Footer/Footer.js'
import Button from 'components/CustomButtons/Button.js'
import styles from 'assets/jss/material-kit-react/views/profilePage.js'
import { applyActionCode, confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth'
import { setAccessToken } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import ErrorLine from 'components/ErrorLine'
import { displayError } from 'util/screenUtils'
import { useEffect } from 'react'
import EditableTextField from 'components/EditableTextField'
import { Snackbar } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(styles)

export default function EmailConfirmation() {
  const params = new URLSearchParams(window.location.search)
  const mode = params.get('mode')
  const oobCode = params.get('oobCode')
  const { postSignInPage } = useSelector(state => state.harmonize)
  console.log(`EmailConfirmation ${mode} ${oobCode}`)
  const [error, setError] = useState()
  const [accountEmail, setAccountEmail] = useState()
  const [newPassword, setNewPassword] = useState('')
  const [passwordChanged, setPasswordChanged] = useState(false)
  const history = useHistory()
  const classes = useStyles()

  /** When the email is confirmed, the user is redirected to the SignIn page. */
  const emailConfirm = async () => {
    console.log(`emailConfirm ${oobCode} postSignInPage ${postSignInPage}`)
    try {
      setAccessToken(null)
      setAccount(null)
      await applyActionCode(getAuth(), oobCode)
      history.replace('/SignIn')
    } catch (error) {
      displayError({ message: `Error confirming email ${error.message}` }, setError)
    }
  }

  const changePasswordForm = () => {
    if (accountEmail) {
      return (
        <div>
          <p>Your email address is {accountEmail}.</p>
          <p>Enter your new password for Harmonize, then click the save icon.</p>
          <EditableTextField
            label='New password'
            multiline={false}
            fullWidth={false}
            getter={() => {
              return newPassword
            }}
            isEditing={true}
            setter={setNewPassword}
            updater={updatePassword}
            noBlur
          />
          {displayPasswordChanged()}
        </div>
      )
    }
  }

  const displayPasswordChanged = () => {
    if (passwordChanged) {
      return (
        <Snackbar
          open={passwordChanged}
          autoHideDuration={5000}
          onClose={() => { setPasswordChanged(false); history.replace('/SignIn') }}
          message='Password changed'
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >

        </Snackbar>
      )
    }
  }

  const updatePassword = async () => {
    if (newPassword.length) {
      try {
        await confirmPasswordReset(getAuth(), oobCode, newPassword)
        setPasswordChanged(true)
      } catch (error) {
        displayError({ message: `Error changing password ${error.message}` }, setError)
      }
    }
  }

  const resetPassword = async () => {
    console.log(`resetPassword ${oobCode}`)
    try {
      // Verify the password reset code is valid.
      const email = await verifyPasswordResetCode(getAuth(), oobCode)
      if (email) {
        setAccountEmail(email)
      }
    } catch (error) {
      displayError({ message: `Error confirming password reset ${error.message}` }, setError)
    }
  }

  const getActionLabel = () => {
    switch (mode) {
      case 'verifyEmail':
        return 'Confirm Email Address'

      case 'resetPassword':
        return 'Reset Password'
    }
  }

  const displayAction = () => {
    switch (mode) {
      case 'verifyEmail':
        return (<>
          <h4>Email confirmed</h4>

          <p>
            <Button
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={() => history.replace('/SignIn')}
            >
              Sign In
            </Button>
          </p>
        </>)

      case 'resetPassword':
        return (<>
          <h4>Change Your Password</h4>
          {changePasswordForm()}
        </>)
    }
  }
  const displayMain = () => {
    return (
      <div style={{ textAlign: 'center', paddingLeft: '1em', paddingRight: '1em' }}>
        <ErrorLine error={error} />
        {displayAction()}

      </div>
    )
  }
  useEffect(() => {
    document.title = getActionLabel()
    switch (mode) {
      case 'verifyEmail':
        emailConfirm()
        break

      case 'resetPassword':
        resetPassword()
        break
    }
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      {(!oobCode || (mode !== 'verifyEmail' && mode !== 'resetPassword')) ? <h3>Invalid page request</h3> : displayMain()}
      <Footer />
    </div>
  )
}
