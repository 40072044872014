import React, { useState } from 'react'
import { io } from 'socket.io-client'
import { AddCircle, Face, HelpOutline } from '@material-ui/icons'
import { primaryColor } from 'assets/jss/material-kit-react.js'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import {
  useMediaQuery,
} from '@material-ui/core'
import DisplayHarmonizePost from './DisplayHarmonizePost'
import { canWriteRoom } from 'util/transactionUtils'
import { displayError } from 'util/screenUtils.js'
import { getAndConnectRoom } from 'controllers/VortexController.js'
import { getPublicUserById } from 'controllers/AccountController.js'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useRef } from 'react'
import { logWithTime } from 'util/screenUtils.js'
import { isAdminUser } from 'util/adminUtils.js'
import BlurDialog from 'components/utility/BlurDialog.js'
import harmonySmall from '../images/harmony-small.jpg'

import { setShowWelcome } from 'redux/actions/helpActions.js'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom.js'

import { setWaitingForHost } from 'redux/actions/chatActions.js'
import { incrementRoomViews } from 'controllers/VortexController.js'
import { setPlayList } from 'redux/actions/harmonizeActions'
import { setPlayListIndex } from 'redux/actions/harmonizeActions'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import HarmonizePlayer from 'components/harmonize/HarmonizePlayer'
import { isGooglebot } from 'util/screenUtils'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { setMessages } from 'redux/actions/messagesActions'
import { setCurrentChannel } from 'redux/actions/messagesActions'
import { getMessage } from 'controllers/VortexController'
import { setPlayerMode } from 'redux/actions/harmonizeActions'
import { setCurrentCollection } from 'redux/actions/harmonizeActions'
import { setContinuousPlay } from 'redux/actions/harmonizeActions'
import { setCollections } from 'redux/actions/harmonizeActions'
import { getCollections } from 'controllers/HarmonizeController'
import ErrorLine from 'components/ErrorLine'
import CreateHarmonizePost from './CreateHarmonizePost'
import Chat from 'components/harmonize/Chat'
import { setSongMediaIcons } from 'redux/actions/songActions'

import { createRsaPublicKey } from 'util/encryptUtils'
import { encryptAudioUrl } from 'util/encryptUtils'
import { LIKED_COLLECTION } from 'util/postUtils'
import { setFullscreen } from 'redux/actions/harmonizeActions'
import { useCallback } from 'react'
import { setHideOverlay } from 'redux/actions/harmonizeActions'
import { STUDIO_COLLECTION } from 'util/postUtils'
import { COLLECTION_TYPE } from 'util/postUtils'
import StudioGrid from 'components/harmonize/StudioGrid'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import Footer from 'components/Footer/Footer'
import { isSupport } from 'util/postUtils'
import AIChat from './AIChat'
import { isNewFormat } from 'util/postUtils'
import { setShowCollections } from 'redux/actions/harmonizeActions'
import { getArtistSong, getSamplerSongs } from 'controllers/HarmonizeController'
import { getRoomById } from 'controllers/VortexController'
import DisplaySignIn from 'components/DisplaySignIn'
import { getAccountId } from 'redux/actions/accountActions'
import { setCurrentRoomOwner } from 'redux/actions/messagesActions'
import { setPlayerState } from 'redux/actions/harmonizeActions'
import { PLAYER_STATES } from 'redux/reducers/harmonizeReducer'
import { isPreviews } from 'util/postUtils'
import { getEntireCollection } from 'controllers/HarmonizeController'
import { setPlayCurrentSong } from 'redux/actions/harmonizeActions'


/* This map saves encrypted audio urls to avoid the overhead of the WebCrypto encryption */
let urlMap = new Map()
let socket
export default function Studio({ defaultRoom, publicRoomName, roomHandle, roomUsername, startWithPost = false, requestedSong }) {
  //See https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const { requestedCollectionId, requestedRoomId, songId } = useParams()
  const [requestedMessageId, setRequestedMessageId] = useState(songId)
  const { showWelcome } = useSelector((state) => state.help)
  const { chatSessionId } = useSelector(state => state.chat)
  const accessToken = getStorageAccessToken()
  const { requestedRoom, requestedPublicRoom } = useParams()

  const storageAccount = getStorageAccount()
  const userId = getAccountId()
  const username = storageAccount ? storageAccount.username : null
  const { currentCollection, forceSignIn, fullscreen, playerMode, playerState, playList, playListIndex,
    reactorPlay, showAi, showChat, showCollections } = useSelector(state => state.harmonize)
  const { currentChannel, currentRoom, messages, currentRoomOwner } = useSelector(state => state.messages)
  const [requestedMessages, setRequestedMessages] = useState()
  const [error, setError] = useState()
  const [createPost, setCreatePost] = useState(startWithPost)
  const [hasAccessToken, setHasAccessToken] = useState(accessToken !== null)
  const [loading, setLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [loadFailed, setLoadFailed] = useState(false)
  const [editingMessageId, setEditingMessageId] = useState(null)
  const [commentToAdd, setCommentToAdd] = useState()
  const [commentToDelete, setCommentToDelete] = useState()
  const [messageToUpdate, setMessageToUpdate] = useState()
  const [messageToDelete, setMessageToDelete] = useState()
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef()

  const getSocket = () => {
    return socket
  }

  const displayIntro = () => {
    const fontSize = veryNarrow ? '0.9em' : 'inherit'
    return (
      <div onClick={() => dispatch(setShowWelcome(false))}>
        <p style={{ textAlign: 'center', fontSize }}>A music site for discerning musicians and listeners</p>
        <hr />
        <p>To display all songs in this studio, click 'Playlists' and select 'Studio'</p>
        <p style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <HelpOutline style={{ fontSize: '2em' }} />&nbsp;Click this icon on the music player for a description of the player controls
        </p>
        <div style={{ display: 'flex', alignItems: 'center', fontSize }}>
          <Face style={{ fontSize: '2em' }} />&nbsp;<div>To change your profile, select <b>My Profile</b> from the <b>Account</b> menu
            on the home page</div>
        </div>
      </div>
    )
  }
  const getPublicRoomName = (roomToCheck) => {
    if (roomToCheck) {
      const { accessType, name } = roomToCheck
      if (accessType === ACCESS_TYPES.PUBLIC) {
        return name
      }
    } else if (requestedPublicRoom) {
      return requestedPublicRoom
    }
    return publicRoomName
  }

  /* The following methods are used to track the visibility state of the browser. */
  const [visible, _setVisible] = useState('visible')
  const visibleRef = useRef(visible)
  const setVisible = (data) => {
    visibleRef.current = data
    //logWithTime(`setVisible to ${visibleRef.current}`)
    _setVisible(data)
  }


  /**
     * This will be called e.g. when another page in Harmonize is selected, so don't null the currentRoom.
     * Otherwise going back to this page will have a null currentRoom and do nothing.
  
     * @param {*} resetRoom If true (default) room and messages are reset.
     */
  const channelDisconnected = (resetRoom = true) => {

    setError()
    socket = null
  }

  const [scrollFetchOff, _setScrollFetchOff] = useState(false)

  const scrollFetchOffRef = useRef(scrollFetchOff)
  const setScrollFetchOff = (data) => {
    scrollFetchOffRef.current = data
    _setScrollFetchOff(data)
  }

  const [endOfMessages, _setEndOfMessages] = useState(false)
  const endOfMessagesRef = useRef(endOfMessages)
  const setEndOfMessages = (data) => {
    endOfMessagesRef.current = data
    _setEndOfMessages(data)
  }

  const [messagesOffset, _setMessagesOffset] = useState(0)

  const messagesOffsetRef = useRef(messagesOffset)
  const setMessagesOffset = (data) => {
    messagesOffsetRef.current = data
    _setMessagesOffset(data)
  }


  const deleteMessage = () => {
    if (messageToDelete) {
      console.log('deleteMessage', messageToDelete)
      const updatedMessages = messages.toSpliced(
        messages.findIndex((m) => m._id === messageToDelete.messageId),
        1
      )
      dispatch(setMessages(updatedMessages))
      createPlaylist(updatedMessages)
      setMessageToDelete(null)
    }
  }

  const updateLike = (messageId, liked) => {
    const likeMessage = messages.find((m) => m._id === messageId)
    if (likeMessage) {
      const updatedMessages = messages.toSpliced(
        messages.findIndex((m) => m._id === messageId),
        1,
        { ...likeMessage, liked }
      )
      dispatch(setMessages(updatedMessages))
    }
  }


  const updateMediaIcons = (media) => {
    console.log(`updateMediaIcons`, media)
    dispatch(setSongMediaIcons([]))
    let icons = []
    media.forEach((m, index) => {
      if (m) {
        const { mimeType, imageType, source, sourceName } = m
        icons.push({ index, name: sourceName, source, mimeType, imageType })
      }
    })
    dispatch(setSongMediaIcons(icons))
  }

  /**
   * When we receive a broadcast updated Message, it does not contain the liked flag.
   * Therefore preserve it from the current state of the Message.
   * 
   * An update may change the studio (room) for a Message. Therefore filter out updates
   * that don't belong in the current studio

   * @param {*} message 
   */
  const updateMessage = () => {
    if (messageToUpdate) {
      const { _id: messageId, media: updatedMedia, roomName } = messageToUpdate
      const { name: currentRoomName } = currentRoom
      const currentMessage = messages.find((m) => m._id === messageId)
      const liked = currentMessage ? currentMessage.liked : false
      const media = [...updatedMedia]
      const revisedMessage = { ...messageToUpdate, liked, media }
      updateMediaIcons(media)
      //console.log('==> revisedMessage', revisedMessage)
      const messageIx = messages.findIndex((m) => m._id === messageToUpdate._id)
      if (messageIx !== -1) {
        console.log(`messageToUpdate roomName ${roomName} currentRoomName ${currentRoomName}`)
        const updatedMessages = roomName.toLowerCase() === currentRoomName ? messages.toSpliced(messageIx, 1,
          revisedMessage
        ) : messages.toSpliced(messageIx, 1)

        console.log('==> updatedMessages', updatedMessages)
        dispatch(setMessages(updatedMessages))
        setMessagesOffset(0)
        createPlaylist(updatedMessages, true)
      }
      setMessageToUpdate(null)
    }
  }

  /**
   * Look for the parent Message in messages, and if found push the newComment into its
   * comments array. If the message is not found the comment is ignored.
   * @returns
   */
  const addCommentToMessage = () => {
    if (commentToAdd) {
      console.log('addCommentToMessage', commentToAdd)
      const { comment, messageId } = commentToAdd
      const message = messages.find((m) => m._id === messageId)
      if (message) {
        if (message.comments) {
          message.comments.push(commentToAdd)
        } else {
          message.comments = [commentToAdd]
        }
        console.log(`...Added comment ${comment} to messageId ${messageId}`)
        dispatch(setMessages([...messages]))
      }
    }
  }

  const deleteCommentFromMessage = () => {
    if (commentToDelete) {
      const { commentId, messageId } = commentToDelete
      const message = messages.find((m) => m._id === messageId)
      if (message && message.comments) {
        message.comments = message.comments.filter((c) => c._id !== commentId)
        console.log(`Deleted comment ${commentId} from messageId ${messageId}`)
        dispatch(setMessages([...messages]))
      }
    }
  }

  /**
   * We DO NOT have to use the redux state in here because the local state is not seen and I don't got
   * the time to figure out why. OK now we know. See:
   * https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
   * @param {*} channel
   */
  const channelConnected = (channel, studioOwner) => {
    console.log(`channelConnected ${channel} `)
    dispatch(setCurrentChannel(channel))
    setError('')
  }


  /** 
   * The only difference between this and connectExisting is that this will get the initial service messages;
   * however there appears to be a race condition in that the socket connection event does not mean
   * the Social Service is listening yet.
   * 
   * Here is some bogosity: if the accessToken is provided as an element in the io constructor, it cannot
   *  be updated after the first connection. So e.g. if it starts null, and you sign in, even though everything
   * else in here sees the new accessToken, the io instance does not. SO we changed this up to send
   * a base64 encoded string on the connection request et voila.
   */
  const connectNew = (channel, studioOwner, studio) => {
    try {
      const channelAddress = `${process.env.REACT_APP_SOCIAL_CHAT_SERVICE}${channel}`
      logWithTime(`New connection to ${channelAddress} currentCollection`, currentCollection)
      socket = io(channelAddress, {
        reconnectionDelay: 10000
      })
      socketActions(socket, channel, studioOwner)
      socket.on('joined', () => {
        //On join, do not request service messages for a specific request since
        //those messages were already read
        if (isSpecificRequest()) {
          if (requestedSong || requestedMessageId) {
            dispatch(setCurrentCollection(null))
          }
        } else {
          //Only show currentCollection if it belongs to this room
          logWithTime('...joined to studio, now getServiceMessages', studio)
          if (studio && currentCollection && currentCollection.roomId === studio._id) {
            const { user } = currentCollection
            console.log(`\n ***>initial currentCollection request ${user} `, studioOwner)
            if (studioOwner.user === user) {
              getServiceMessages(currentCollection)
            } else {
              dispatch(setCurrentCollection(null))
              getServiceMessages()
            }
          } else {
            getServiceMessages()
          }
        }

      })
    } catch (error) {
      console.error(error)
      displayError(error, setError)
    }
  }

  const connectExisting = () => {
    logWithTime(`Connect to existing ${currentChannel}`)
    try {
      const channelAddress = `${process.env.REACT_APP_SOCIAL_CHAT_SERVICE}${currentChannel}`
      //console.log(`Reconnect to ${channelAddress}`)
      socket = io(channelAddress, {
        reconnectionDelay: 10000,
      })
      socketActions(socket, currentChannel, currentRoomOwner)
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  /**
   * "Event handlers shouldn't be registered in the connect handler itself, 
   * as a new handler will be registered every time the socket instance reconnects:"
   * 
   * @see https://socket.io/docs/v4/client-api/#event-connect
   * @param {*} socket 
   */
  const socketActions = (socket, channel, studioOwner) => {
    //When the Social Service is killed with active connections this gets called back with a null socket
    if (socket) {
      socket.on('connect', () => {
        channelConnected(channel, studioOwner)
      })
      socket.on('connect_error', () => {
        displayError({ message: `Unable to connect to Harmonize Social Service` }, setError)
        if (socket) {
          socket.disconnect()
        }
      })

      socket.on('unauthorized', () => {
        channelDisconnected(channel)
        setError('You are not signed in')
        history.replace('/SignIn')
      })
      socket.on('disconnect', () => {
        //console.log(`received socket disconnect IGNORED`)
        //channelDisconnected()
      })
      socket.on('addComment', (comment) => {
        console.log('addComment received comment', comment)
        setCommentToAdd(comment)
      })
      socket.on('deleteComment', (comment) => {
        console.log('deleteComment', comment)
        setCommentToDelete(comment)
      })
      socket.on('addMessage', async (message) => {
        console.log('addMessage received message', message)

        const { _id } = message
        const existingMessage = messages.findIndex((m) => m._id === _id) !== -1
        if (existingMessage) {
          console.error(`received existingMessage ${_id} IGNORED`)
        } else {
          //Disable autoPlay so you don't get a "need to touch first" audio error
          dispatch(setContinuousPlay(false))
          const updatedMessages = [message, ...messages]
          dispatch(setMessages(updatedMessages))
          createPlaylist([...updatedMessages])
        }
      })
      socket.on('liked', async (messageId) => {
        console.log('liked', messageId)
        updateLike(messageId, true)
      })
      socket.on('unliked', async (messageId) => {
        console.log('unliked', messageId)
        updateLike(messageId, false)
      })
      socket.on('updateMessage', async (message) => {
        console.log('updateMessage received message', message)
        setMessageToUpdate(message)
      })
      socket.on('deleteMessage', (message) => {
        console.log('deleteMessage', message)
        setMessageToDelete(message)
      })
      socket.on('requestedMessages', async (messages) => {
        setRequestedMessages(messages)
        setScrollFetchOff(false)
      })
      socket.on('error', (message) => {
        displayError(message, setError)
      })
    }
  }
  /**
   * This disconnects the socket but leaves everything else in place.
   */
  const disconnectExisting = () => {

    logWithTime(
      socket ? 'disconnectExisting' : 'WARNING no socket for disconnectExisting'
    )
    leaveRoom()
    socket = null
  }

  const disconnectChannel = (resetRoom) => {
    console.log(`disconnectChannel resetRoom ${resetRoom}`, socket)
    leaveRoom()
    channelDisconnected(resetRoom)
  }

  /**
   * Request messages from the Social Service either:
   * 1) By the currentCollection. This works in any Studio.
   * 2) For this Studio. This only makes sense in an artist Studio, i.e., a Studio in which Messages have been posted
   * @param {*} requestedCollection 
   * @param {*} noDefault If true don't fall back to requestedMessageId or requestedCollectionId
   */
  const getServiceMessages = (requestedCollection, noDefault) => {
    console.log('\n ***>getServiceMessages', requestedCollection)

    let query = { userId, start: 0, count: parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE) }
    setMessagesOffset(0)
    setEndOfMessages(false)
    dispatch(setMessages([]))
    dispatch(setPlayList([]))
    dispatch(setPlayListIndex(0))
    //dispatch(setContinuousPlay(false))
    /* Disable scroll on fetch because if the messages retrieved are too short display-wise another fetch is triggered */
    setScrollFetchOff(true)
    if (requestedCollection) {
      setRequestedMessageId(null)
      const { _id: collectionId, collectionType } = requestedCollection
      if (collectionType !== COLLECTION_TYPE.STUDIO) {
        query = { userId, collectionId, collectionType }
      }
    } else if (!noDefault) {
      if (requestedMessageId) {
        query = { userId, requestedMessageId }
      } else if (requestedCollectionId) {
        query = { userId, collectionId: requestedCollectionId }
      }
    }
    console.log('...getServiceMessages query', query)
    safeEmit('getMessages', query)
  }

  const leaveRoom = () => {
    if (socket) {
      safeEmit('leaveRoom', { userId, username })
    }
  }

  const leaveChat = () => {
    if (socket) {
      safeEmit('chatleave', { userId, username })
    }
  }

  const getRoomOwner = async (room) => {
    console.log('getRoomOwner', room)
    const { user } = room
    const publicUser = await getPublicUserById(user)
    if (publicUser.status === 200) {
      const owner = { ...publicUser, user }
      console.log('Room owner', owner)
      dispatch(setCurrentRoomOwner(owner))
      if (!isSpecificRequest() && playerState === PLAYER_STATES.SONG) {
        console.log(`getRoomOwner set playerState to PLAYER_STATES.STUDIO`)
        dispatch(setPlayerState(PLAYER_STATES.STUDIO))
      }
      return owner
    } else {
      return null
    }
  }

  /**
   * Builds the encrypted URL parameters for the audio or video and returns that and some other things
   * @param {*} md 
   * @param {*} newFormat   True for any audio created after some date
   * @param {*} rsaKeyPair  I only the pub key in production
   * @returns { mimeType, duration, source: url, sourceName, mediaSource }
   */
  const buildEntryMedia = async (md, newFormat, rsaKeyPair) => {
    let url
    const { pub } = rsaKeyPair
    const { source: mediaSource, mimeType, imageType, sourceName, duration } = md
    switch (mimeType) {
      case 'audio/mpeg':
        const mapKey = `${mediaSource}/${sourceName}`
        url = urlMap.get(mapKey)
        if (!url) {
          url = await encryptAudioUrl(mediaSource, sourceName, pub, !newFormat)
          urlMap.set(mapKey, url)
        }
        break
      case 'video/mp4':
        //:apiKey/:username/:foldername/:subfoldername/:videoname
        //mediaSource is e.g. cjvilla-photo/home/PerfectLife and is defined when Message is stored on Core Service
        //Can't get the emcrypted call to work with React-Player. The m3u8 comes back but ReactPlayer never makes
        //a call for the TS files.
        const videoParameters = `${process.env.REACT_APP_API_KEY}/${mediaSource}/${sourceName}`
        //const videoEncryptedParams = await encryptString(videoParameters, pub)
        url = `${process.env.REACT_APP_CORE_SERVICE}media/video/${videoParameters}`
        break
      default:
        break
    }
    //console.log(`buildEntryMedia completed mimeType ${mimeType} ${url}`, md)
    return { mimeType, imageType, duration, source: url, sourceName, mediaSource }

  }

  const buildAllEntryMedia = async (msg, entryMedia, rsaKeyPair) => {
    //console.log('buildAllEntryMedia')
    const { _id: messageId, name, media, createdAt, updatedAt, username } = msg
    return Promise.all(
      media.map(async (md) => {
        const msgMedia = await buildEntryMedia(md, isNewFormat(msg), rsaKeyPair)
        const { mimeType, imageType, duration, source: url, sourceName, mediaSource } = msgMedia
        entryMedia.push({ messageId, name, createdAt, mimeType, imageType, duration, source: url, sourceName, username, mediaSource })
      })
    )
  }

  /**
   * The default values assigned to firstName etc are for the Messages created before we started
   * collecting this info when the Message is created.
   * @param {*} m 
   * @param {*} playlist 
   * @param {*} rsaKeyPair 
   */
  const buildPlaylistEntry = async (m, playlist, rsaKeyPair) => {
    const { _id: messageId, name, media, userId, totalCredits, firstName = 'CJ', lastName = 'Villa', handle = 'cj', highlightTime } = m
    if (media && media.length) {
      let entryMedia = [], hasMp3
      await buildAllEntryMedia(m, entryMedia, rsaKeyPair)
      hasMp3 = entryMedia.findIndex(em => em.mimeType === 'audio/mpeg') !== -1
      if (hasMp3) {
        playlist.push({ messageId, userId, entryMedia, name, totalCredits, firstName, lastName, handle, highlightTime })
      }
    }
  }

  const buildNewPlaylistEntries = (newMessages, playlist, rsaKeyPair) => {
    //console.log(`buildNewPlaylistEntries ${messages.length}`)
    dispatch(setPlayListIndex(0))
    return Promise.all(
      newMessages.map(async (m, ix) => {
        //console.log(`buildPlaylistEntry ${ix}`)
        await buildPlaylistEntry(m, playlist, rsaKeyPair)
      })
    )
  }

  /**
   * A playList comprises one entry for every message that has an mp3.
   * The entry comprises an array with an entry for each mp3 or mp4 file.
   * 
   * NOTE: On 8/1/2024 MP3s started being created on the Core Service and not in Backblaze.
   * As a result there are two Core Service endpoints.
  
   * @param {*} newMessages The messages for the playList.
   * @param {*} initialize  If true this is the first set in the playlist
   */
  const createPlaylist = async (newMessages, initialize) => {
    console.log(`createPlaylist initialize ${initialize} ${newMessages.length} 
    newMessages messagesOffset ${messagesOffsetRef.current} currentCollection:`, currentCollection)
    if (initialize) {
      dispatch(setMessages(newMessages))
      setMessagesOffset(newMessages.length)
      console.log(`...createPlaylist initialize true set playerState to PLAYER_STATES.SONG`)
      dispatch(setPlayerState(PLAYER_STATES.SONG))
    } else {
      dispatch(setMessages([...messages, ...newMessages]))
      setMessagesOffset(messagesOffsetRef.current + newMessages.length)
    }
    let newPlayList = []
    const rsaKeyPair = await createRsaPublicKey()
    console.log('...public key created')
    await buildNewPlaylistEntries(newMessages, newPlayList, rsaKeyPair)
    const updatedPlayList = initialize ? newPlayList : [...playList, ...newPlayList]
    completeCreatePlaylist(updatedPlayList, initialize)
  }

  /**
   * 
   * @param {*} updatedPlayList The playList as just updated in createPlaylist
   * @param {*} initialize      If true and requestedMessageId is not null then set the playListIndex to the
   *                            entry corresponding to the requested Message
   */
  const completeCreatePlaylist = (updatedPlayList, initialize) => {
    console.log(`**************completeCreatePlaylist playerState ${playerState} playListLength ${updatedPlayList.length} 
      initialize ${initialize}`)
    if (initialize) {
      if (requestedMessageId || requestedSong) {
        dispatch(setPlayList(updatedPlayList))
        const ix = updatedPlayList.findIndex(pl => pl.messageId === requestedMessageId)
        if (ix !== -1) {
          dispatch(setPlayListIndex(ix))
          dispatch(setCurrentCollection(null))
          setRequestedMessageId(null)
        } else {
          dispatch(setPlayListIndex(0))
        }
      } else {
        dispatch(setPlayList(updatedPlayList))
      }

    } else {
      dispatch(setPlayList(updatedPlayList))
    }
    if (requestedMessageId || requestedSong) {
      console.log(`**** setPlayerMode from completeCreatePlaylist ${PLAYER_MODES.POST}`)
      dispatch(setPlayerMode(PLAYER_MODES.POST))
    }
    if (isPreviews(currentRoom)) {
      console.log(`**** setPlayerMode from completeCreatePlaylist ${PLAYER_MODES.GRID}`)
      dispatch(setPlayerMode(PLAYER_MODES.GRID))
    }

  }

  /**
   * Create the playlist from the collection. If that is null, reset the currentCollection.
   * Then call requestMessages
   * @param {*} collection
   */
  const collectionPlaylist = async (collection) => {
    console.log('collectionPlaylist', collection)
    setError('')
    dispatch(setCurrentCollection(collection))
    if (collection) {
      console.log(`**** setPlayerMode from collectionPlaylist ${PLAYER_MODES.LIST}`)
      dispatch(setPlayerMode(PLAYER_MODES.LIST))
    }
    getServiceMessages(collection, true)

  }

  /** To shuffle the playList you actually must shuffle the containing messages, then rebuild the playList */
  const shufflePlaylist = () => {
    const originalMessages = [...messages]
    originalMessages.sort(() => Math.random() - 0.5)
    console.log('shuffled messages', originalMessages)
    createPlaylist(originalMessages, true)
  }

  /**
   * Always display the HarmonizePlayer
   * @returns 
   */
  const displayHarmonizePlayer = () => {
    return (
      <HarmonizePlayer
        messageSocket={getSocket}
        setCollectionPlaylist={(col) => collectionPlaylist(col)}
        enterFullScreen={fullScreenHandle.enter}
        shuffle={() => shufflePlaylist()} />
    )
  }

  const isAccessAllowed = () => {
    const { accessType, user } = currentRoom
    return isAdminUser() ||
      accessType !== ACCESS_TYPES.PRIVATE || user === userId
  }

  const updatePlayListSelection = (messageId) => {
    console.log(`selected messageID ${messageId}`, playList)
    const selectedIndex = playList.findIndex(pl => pl.messageId === messageId)
    if (selectedIndex !== -1) {
      dispatch(setPlayListIndex(selectedIndex))
      dispatch(setPlayCurrentSong(playList[selectedIndex].name))
    }
  }

  const displayCreatePost = () => {
    if (accessToken) {
      return (
        <CreateHarmonizePost socket={getSocket} close={() => { setCreatePost(false); setEditingMessageId(null) }} messageId={editingMessageId}
        />
      )
    } else {
      history.replace('/')
    }
  }

  const displayPost = (msg, fromSupport) => {
    if (msg && !reactorPlay) {
      return (
        <DisplayHarmonizePost
          fromSupport={fromSupport}
          key={msg._id}
          content={msg}
          selected={() => {
            updatePlayListSelection(msg._id)
          }}
          accessType={
            getPublicRoomName()
              ? ACCESS_TYPES.PUBLIC
              : ACCESS_TYPES.PROTECTED
          }
          room={currentRoom}
          messageSocket={socket}
          displayTitle={playerMode === PLAYER_MODES.POST}
          editPost={() => setEditingMessageId(msg._id)}

        />
      )
    }
  }


  const
    displayNoSongs = () => {
      console.log(`displayNoSongs  userId ${userId}`, currentCollection)
      const noSongs = currentCollection && currentCollection.user === userId ? ` for ${currentCollection.name}` : ' in your studio'
      return (
        <div style={{
          width: '100%', padding: '1em', color: 'white',
          justifyContent: 'center', border: '1px solid white',
          textAlign: 'center'
        }}>
          <h4>There are no songs {noSongs} yet</h4>
          <p>Once you like some songs, they will appear in your Liked playlist</p>
        </div>
      )
    }
  /**
   * If we are crawled by Googlebot then return all posts as in LIST mode.
   * Otherwise return one or more messages using displayPost.
   * 
   * @returns 
   */
  const displayMessagesForMode = () => {
    //console.log(`\n---> displayMessagesForMode ${messages.length} messages mode ${playerMode} state ${playerState}`)
    if (isGooglebot(window.navigator.userAgent)) {
      return messages.map((m => displayPost(m)))
    }
    if (requestedMessageId || requestedSong) {
      switch (playerMode) {
        case PLAYER_MODES.GRID:
          if (!reactorPlay) {
            return <StudioGrid />
          } else {
            return null
          }
        default:
          return displayPost(messages[0])
      }
    }

    if (endOfMessagesRef.current && !messages.length) {
      return displayNoSongs()
    }

    if (!showCollections) {
      switch (playerMode) {

        case PLAYER_MODES.GRID:
          if (fullscreen) {
            return messages.map((m => displayPost(m)))
          } else if (!reactorPlay) {
            return <StudioGrid />
          } else {
            return null
          }
        case PLAYER_MODES.POST:
          return messages.map((m => displayPost(m)))


        case PLAYER_MODES.LIST:
          return messages.map((m => {
            return displayPost(m)
          }))
        default:
          const { accessType } = currentRoom
          return displayPost(messages.find(m => m._id === (!playList.length && accessType === ACCESS_TYPES.PRIVATE ? messages[playListIndex].messageId : playList[playListIndex].messageId)))
      }
    }
    //}

  }

  /** Posts can only be added:
   * 
   * - in the PRIVATE room that belongs to the artist.
   * - in the Support room by the room owner
   * 
   *  */
  const displayAddPost = () => {
    if (accessToken && currentRoom) {
      const { accessType } = currentRoom
      if (isSupport(currentRoom) || accessType === ACCESS_TYPES.PRIVATE) {
        return (
          <div style={{
            display: 'flex', position: 'fixed', bottom: '4vh',
            right: veryNarrow ? '0.25em' : '0.5em'
          }}>
            {!(reactorPlay || showChat) && canWriteRoom(currentRoom, userId) ? (
              <div
                title='Click to add a song'
                onClick={() => setCreatePost(!createPost)}
              >
                <AddCircle
                  color='primary'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderRadius: '4em',
                    fontSize: '4em',
                  }}
                />
              </div>) : null}
          </div>
        )
      }
    }
  }

  /** If in player mode, we only ever display the post for the current audio */
  const displayOnCondition = () => {
    if (loadFailed) {
      return (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Unable to load this room
        </p>
      )
    } /*else if (loading) {
      return (
        <div style={{ backgroundColor: 'white', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TimerProgress label='Loading...' />
        </div>)
    } */ else {

      if (!isAccessAllowed()) {
        return (
          <p style={{ fontWeight: 'bold', textAlign: 'center', color: 'white' }}>
            This room is private
          </p>
        )
      } else {
        return (
          <div>
            <div >
              {displayMessagesForMode()}
            </div>
            {accessToken && showChat ? <div style={{ position: 'fixed', bottom: '4vh', width: '100%' }}>
              <Chat socket={getSocket} setError={setError} currentRoomOwner={currentRoomOwner} /></div> : null}
            {displayAddPost()}
          </div>
        )
      }
    }
  }

  const displaySignIn = () => {
    if (!accessToken && forceSignIn && currentRoom && currentRoomOwner) {
      const { name: roomName } = currentRoom
      const { handle } = currentRoomOwner
      const returnPath = requestedMessageId ? `/song/${requestedMessageId}` :
        requestedSong ? `/@${handle}/${requestedSong}` :
          requestedRoomId && requestedCollectionId ? `/collection/${requestedRoomId}/${requestedCollectionId}`
            : `/@${handle}/${roomName}`
      return <DisplaySignIn returnPath={returnPath} />
    }
  }

  /**
   * Don't show the Welcome screen if accessed by googlebot or none of the content gets indexed because it
   * actually waits long enough to see the BlurDialog.
   * @returns 
   */
  const displayWelcomeScreen = () => {
    //displayMessagesForMode()
    if (currentRoom && currentRoomOwner) {
      const { accessType } = currentRoom
      if (accessToken && accessType !== ACCESS_TYPES.PUBLIC) {
        if (!startWithPost && showWelcome && !isGooglebot(window.navigator.userAgent)) {
          console.log(`welcome ${accessType}`)
          return (<div onClick={() => dispatch(setShowWelcome(false))}>
            <BlurDialog content={displayIntro}
              image={harmonySmall}
              imageStyle={{ display: 'flex', width: '40vw', marginLeft: 'auto', marginRight: 'auto' }}
              hideHelp closeIcon close={() => dispatch(setShowWelcome(false))} top={veryNarrow ? '5vh' : '10vh'} />
          </div>)
        }
      }
    }
  }

  const displayFullScreen = () => {
    return (
      <div style={{ position: 'relative' }}>
        {displayMessagesForMode()}
        <div style={{ position: 'sticky', bottom: '2vh', zIndex: '1' }}>
          {displayHarmonizePlayer()}
        </div>

      </div>
    )
  }

  const displayMusic = () => {
    return (
      <div><div style={{ position: 'sticky', top: 0, zIndex: '1' }}>
        {displayHarmonizePlayer()}
      </div>
        <div style={{
          paddingBottom: showChat ? '30vh' : reactorPlay ? '0' : '100px',
          backgroundColor: reactorPlay ? 'white' : primaryColor,
        }}>{displayOnCondition()}</div></div>
    )
  }

  const displayAiOrSupport = () => {
    if (showAi) {
      return (

        <div style={{
          position: 'absolute', top: '5em', width: '100%', left: 0,
          backgroundColor: 'white', paddingLeft: '0.5em', paddingRight: '0.5em'
        }}>
          <AIChat category='Support' ownerOnly={true} chatHeight={veryNarrow ? '68vh' : '80vh'} useSessionId={chatSessionId} /> </div>
      )

    } else {
      return (<div><div style={{ paddingBottom: '5vh' }}>
        {messages.map(msg => displayPost(msg, true))}
      </div>
        {displayAddPost()}
      </div>)
    }
  }

  const displaySupport = () => {
    return (
      <div>
        <HarmonizeHeader />
        {displayAiOrSupport()}
        <Footer />
      </div>)
  }
  /**
   * The sticky position only works if:
   * - zIndex is set to >0. WIthout this content scrolls over the player.
   * - The height of the container is set larger than the scrolled container. Without this the sticky only stays
   *   through part of the list
   * @returns 
   */
  const displayMain = () => {
    if (currentRoom) {
      //console.log('Studio displayMain currentRoom', currentRoom)
      if (fullscreen) {
        return displayFullScreen()
      } else {
        const { category } = currentRoom
        return (
          <div>
            <ErrorLine error={error} />

            {createPost || editingMessageId ? displayCreatePost() :
              category === 'Music' ? displayMusic() : displaySupport()}
            {displayWelcomeScreen()}
            {displaySignIn()}
          </div>
        )
      }
    } else {
      return null
    }
  }

  /**
   * Put the Studio collection at the top -- this gets us back to all songs in the studio.
   * Push Liked with this User's id so that if we switch Studios we don't use this Liked list.
   * @param {*} collections 
   * @param {*} owner 
   */
  const addFixedPlaylists = (collections, owner) => {
    collections.push({ ...STUDIO_COLLECTION, userId })
    if (owner.user === userId) {
      //collections.push({ name: 'Following' })
      collections.push({ ...LIKED_COLLECTION, userId })
    }

  }

  /**
   * Always load collections for the Room owner when initializing the player. 
   * 
   * 
   */

  /**
   * 
   * @param {*} collectionsRoom 
   * @param {*} owner 
   */
  const fetchRoomCollections = async (collectionsRoom, owner) => {
    console.log('fetchRoomCollections', collectionsRoom)
    const { _id } = collectionsRoom
    try {
      const coll = await getCollections(_id)
      addFixedPlaylists(coll, owner)
      console.log('fetchRoomCollections', coll)
      dispatch(setCollections(coll))

    } catch (error) {
      console.error(error)
    }
  }


  /**
   * @returns true if a specific Playlist or Song is requested
   */
  const isSpecificRequest = () => {
    return requestedCollectionId || requestedMessageId || requestedSong || songId
  }

  /**
   * 
   * @returns true if a specific song is requested
   */
  const isSpecificSongRequest = () => {
    return requestedMessageId || requestedSong || songId
  }

  /** Anonymous Studio access without a specific request creates a Playlist of
   * sampler songs. With a specific request no Playlist is created -- this instead
   * occurs in defineConnectionRoom.
   * 
   * In all cases currentRoom is updated.
   */
  const connectAnonymous = async (room, roomOwner, user) => {
    if (!isSpecificRequest()) {
      const songs = await getSamplerSongs(0, 15)
      console.log('Songs for anonymous access', songs)
      createPlaylist(songs, true)
    }
    await updateRoom(room, roomOwner, user)
  }

  const updateRoom = async (room, roomOwner, user) => {
    const { _id, category } = room
    let updatedRoom = { ...room, roomOwner }
    if (userId !== user) {
      //This call to the Core Service returns just the Room, not the associated Asset,
      //so until that todo above is done, we need to put the category back into currentRoom.
      const incrementedRoom = await incrementRoomViews(_id)
      updatedRoom = { ...incrementedRoom, category, roomOwner }
    }
    dispatch(setCurrentRoom(updatedRoom))
    console.log('...currentRoom is now', updatedRoom)
    return updatedRoom
  }
  /**
   * The channel address for a publicAccess room that is being accessed anonymously is:
   * wss://service.harmonize.social/[roomId]=[apikey]
   * 
   * The channel address for every other room  is:
   * wss://service.harmonize.social/[channelName]/[_id]/[b64AccessToken]
   * 
   * where
   * channelName and _id are taken from the room
   * b64AccessToken      is the signed-in User's accessToken, b64 encoded
   * @todo Move Asset category into Room so that when Room is retrieved it always has category
   * 
   * @param {*} room
   */
  const connectOwnerAndSocket = async (room) => {
    const { accessType, channel, _id, category } = room
    const { name: channelName, user } = channel
    console.log(`connectOwnerAndSocket userId ${userId} room owner ${user}`, room)
    const roomOwner = await getRoomOwner(room)
    if (!accessToken && accessType !== ACCESS_TYPES.PUBLIC) {
      await connectAnonymous(room, roomOwner, user)
    } else {
      if (accessType === ACCESS_TYPES.PUBLIC) {
        console.log(`...connect to public room ${getPublicRoomName(room)}`)
        connectNew(`/${_id}=${userId ? userId : 0}=${process.env.REACT_APP_API_KEY}`, roomOwner, room)
      } else if (accessToken) {
        console.log(`...connect to channel /${channelName}/${_id}/[accessToken]`)
        const b64AccessToken = btoa(accessToken)
        connectNew(`/${channelName}/${_id}/${b64AccessToken}`, roomOwner, room)
      } else {
        throw new Error('FATAL ERROR: no valid socket connection sequence')
      }
      const updatedRoom = await updateRoom(room, roomOwner, user)
      await fetchRoomCollections(updatedRoom, roomOwner)
    }
  }

  const connectError = (exc) => {
    console.error('connectError', exc)
    const { status } = exc
    if (status === 401) {
      history.push('/SignIn')
    } else if (status === 403) {
      history.replace('/')
    } else {
      displayError(exc, setError)
    }
  }

  /**
   * This does two things:
   * 1) For specific requests, retrieves the required message/messages. 
   * 2) For all requests, determines the information for the target Studio required to make the socket connection (if any)
   * @returns { roomToGet, roomToGetHandle, roomToGetUsername }
   */
  const defineConnectionRoom = async () => {
    let roomToGet, roomToGetHandle, roomToGetUsername
    if (requestedMessageId) {
      try {
        const requestedMessage = await getMessage(requestedMessageId, userId)
        console.log('requestedMessage', requestedMessage)
        dispatch(setMessages([requestedMessage]))
        setRequestedMessages([requestedMessage])
        roomToGet = requestedMessage.roomName
        roomToGetUsername = requestedMessage.username
      } catch (error) {
        history.replace('/')

      }
    } else if (requestedRoomId && requestedCollectionId) {
      try {
        const collection = await getEntireCollection(requestedCollectionId)
        console.log('requested collection', collection)
        const { messages: collectionMessages } = collection
        dispatch(setMessages(collectionMessages))
        setRequestedMessages(collectionMessages)
        const collectionRoom = await getRoomById(requestedRoomId)
        console.log('collectionRoom', collectionRoom)
        const { name, user } = collectionRoom
        roomToGet = name
        const collectionUser = await getPublicUserById(user)
        console.log('collectionUser', collectionUser)
        const { username, handle } = collectionUser
        roomToGetHandle = handle
        roomToGetUsername = username

      } catch (error) {
        history.replace('/')

      }
    } else if (requestedSong && roomUsername) {
      try {
        console.log(`connectRoom getArtistSong ${roomUsername} ${requestedSong}`)
        const songMessage = await getArtistSong(roomUsername, requestedSong, userId)
        console.log('songMessage', songMessage)
        dispatch(setMessages([songMessage]))
        setRequestedMessages([songMessage])
        roomToGet = songMessage.roomName
        roomToGetUsername = songMessage.username
      } catch (error) {
        history.replace('/')

      }
    }
    else {
      roomToGetHandle = roomHandle
      roomToGet = defaultRoom
        ? defaultRoom
        : requestedRoom
          ? requestedRoom
          : getPublicRoomName()
    }
    return { roomToGet, roomToGetHandle, roomToGetUsername }
  }

  const connectRoom = async () => {
    console.log(`connectRoom playerState ${playerState}`)
    if (!isSpecificSongRequest()) {// || (playerState !== PLAYER_STATES.SONG && playerState !== PLAYER_STATES.STUDIO)) {
      console.log(`connectRoom set playerState to PLAYER_STATES.INIT`)
      dispatch(setPlayerState(PLAYER_STATES.INIT))
    }
    setLoading(true)
    setLoadFailed(false)
    setLoaded(false)

    const { roomToGet, roomToGetHandle, roomToGetUsername } = await defineConnectionRoom()
    console.log(`\n*** connectRoom ${roomToGet} roomHandle ${roomHandle} requestedMessageId ${requestedMessageId} requestedSong ${requestedSong}`)
    if (!roomToGet) {
      setError('No valid room was provided')
      dispatch(setMessages())
      setLoading(false)
      setLoadFailed(true)
    } else {
      try {
        const room = await getAndConnectRoom(
          roomToGet,
          roomToGetHandle,
          roomToGetUsername,
          accessToken,
          history,
          dispatch,
          (exc) => connectError(exc)
        )
        if (room) {
          console.log('getAndConnectRoom returned', room)
          //dispatch(setPlayerState(PLAYER_STATES.STUDIO))
          await connectOwnerAndSocket(room)
          setLoading(false)
          console.log('--------- setLoaded TRUE')
          setLoaded(true)
          setLoadFailed(false)
          dispatch(setWaitingForHost(false))
          dispatch(setPlayListIndex(0))
        } else {
          setLoading(false)
          setLoadFailed(true)
        }

      } catch (error) {
        setLoading(false)
        setLoadFailed(true)
        displayError(error, setError)
      }

    }
  }

  const safeEmit = (type, message) => {
    if (socket) {
      socket.emit(type, message)
    }
  }

  /**
   * 
   * * NOTE * An end of div scroll event occurs whenever we go into Fullscreen.
   * 
   * Height factors:
   * See https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
   * and
   * scrollHeight: the height of an element's content, including content not visible on the screen due to overflow.
   *  the minimum height the element would require in order to fit all the content in the viewport without using a vertical scrollbar
   * clientHeight: the inner height of an element in pixels. It includes padding but excludes borders, margins, and horizontal scrollbars (if present).
   * scrollTop: the distance from the element's top to its topmost visible content. May be floating point on scaled displays bvut
   *    supposedly never negative
   *
   * So hidden content is scrollHeight-scrollTop: the amount of content above the visible top. clientHeight is the height of the scrolling area.
   * If clientHeight and hidden height are equal then we are at the bottom of current content (it's all scrolled up). If we test for
   * a difference of 100 or less then it means there is still some content showing in the scrolling area. But it could also mean that
   * e.g. clientHeight or scrollTop on the mobile browser is not accurate because of the soft keys at the bottom or the address bar at the top.
   *
   * Hacks: Testing for absolute bottom does not work on actual Android mobile browsers (though in DevTools it works). We throw 100 at it for now.
   * 50 didn't work
   *
   * scrollTop issues:
   *
   * scrollTop is floating point on Android Chrome browser.
   * Suggested to use this: element.scrollHeight - Math.floor(element.scrollTop) === element.clientHeight
   *
   * See https://stackoverflow.com/questions/58972770/javascript-code-is-not-working-latest-chrome-in-android
   *
   * State issues:
   *
   * See https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
   * @param {*} e
   */
  const handleScroll = async (e) => {
    if (!isPreviews(currentRoom)) {
      const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement
      const bottom = scrollHeight - scrollTop - 100 <= clientHeight

      if (!(scrollFetchOffRef.current || endOfMessagesRef.current) && bottom && currentRoom) {
        /* This is also triggered by entering Fullscreen mode */
        console.log(`\n ***> End of div scrollFetchOff ${scrollFetchOffRef.current} endOfMessages ${endOfMessagesRef.current} getMessages ${messagesOffsetRef.current} offset *************`)
        setScrollFetchOff(true)
        try {
          safeEmit('getMessages', { userId, start: messagesOffsetRef.current, count: parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE) })
        } catch (error) {
          console.error('Error getting new messages', error)
        }
        //setScrollFetchOff(false) safeEmit does not block so do not call this until response from Social Service
      }
    }
  }

  /**
   * Called when the requestMessages response from Social Service is received. 
   */
  useEffect(() => {
    console.log('\n ----------- useEffect 1')
    console.log(`\n ***>Studio useEffect fullscreen ${fullscreen} requestedSong ${requestedSong} requestedMessages ${requestedMessages ? requestedMessages.length : 'null'}`, requestedMessages)
    if (requestedMessages) {
      if (requestedMessages.length) {
        createPlaylist(requestedMessages, true).then(() => {
          if (requestedMessages.length < parseInt(process.env.REACT_APP_MESSAGES_BLOCK_SIZE)) {
            console.log(`\n*** end of messages, offset ${messagesOffsetRef.current} new block length ${requestedMessages.length}`)
            setEndOfMessages(true)
          }
          setRequestedMessages(null)
        })
      } else {
        if (!messages.length) {
          dispatch(setPlayerState(PLAYER_STATES.EMPTY_STUDIO))
        }
        setEndOfMessages(true)
        setMessagesOffset(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedMessages])

  const scrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  /**
   * This is the only way to do it. All those posts that show a div handling onScroll are
   * not relevant when using Material UI.
   */
  useEffect(() => {
    console.log('\n ----------- useEffect currentRoom')
    if (currentRoom) {
      document.title = `Harmonize | ${currentRoom.name}`
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      //console.log('remove scroll listener')
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom])

  const handleVisibilityChange = () => {
    //console.log(`visibilityChange ${document.visibilityState}`)
    if (document.visibilityState === 'hidden') {
      console.log('---> Studio disconnecting due to visibility change')
      disconnectExisting()
    } else if (currentRoom && !socket) {
      console.log('---> Studio Reconnecting due to visibility change')
      connectExisting()
    }
    setVisible(document.visibilityState)
  }


  useEffect(() => {
    //console.log('\n ----------- useEffect 3')
    addCommentToMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentToAdd])

  useEffect(() => {
    //console.log('\n ----------- useEffect 4')
    deleteCommentFromMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentToDelete])

  useEffect(() => {
    //console.log('\n ----------- useEffect 5')
    updateMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageToUpdate])

  useEffect(() => {
    //console.log('\n ----------- useEffect 6')
    deleteMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageToDelete])

  useEffect(() => {
    //console.log('\n ----------- useEffect 7')
    //console.log(`useEffect visibility ${document.visibilityState}`)
    window.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      //console.log('remove visibilityChange listener')
      window.removeEventListener('visibilitychange', handleVisibilityChange)
      disconnectChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //console.log('\n ----------- useEffect 8')
    console.log(`Harmonize useEffect showChat ${showChat}`)
    if (!showChat) {
      leaveChat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat])

  useEffect(() => {
    //console.log('\n ----------- useEffect 9')
    //dispatch(setContinuousPlay(false))
    if (requestedMessageId || requestedSong) {
      dispatch(setShowCollections(false))
      dispatch(setCurrentCollection())
    }
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * If the user enters a protected Studio without being signed in, connectRoom executes but does not connect to the Social Service.
   * In this case hasAccessToken is false. When the sign in completes, accessToken is updated and that triggers this method again.
   * At that point because hasAccessToken is still false, the connectRoom executes again. This time it does connect
   * to the Social Service, getting the initial messages.
   */
  useEffect(() => {
    console.log('\n ----------- useEffect 10')
    console.log(`Studio useEffect hasAccessToken ${hasAccessToken} loading ${loading} loaded ${loaded} playListIndex ${playListIndex} playerMode ${playerMode}`)
    if (!(loaded || loadFailed) || (!hasAccessToken && accessToken)) {
      dispatch(setMessages([]))
      connectRoom()
      if (accessToken) {
        setHasAccessToken(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, playerMode, accessToken])

  // If you disable right click no one can copy text from the posts which isn't good
  //if (process.env.REACT_APP_MODE === 'development') {
  const fullScreenHandle = useFullScreenHandle()
  const fullScreenChange = useCallback((state) => {
    console.log(`fullScreenChange ${state} playerMode ${playerMode}`)
    dispatch(setFullscreen(state))
    if (!state) {
      dispatch(setHideOverlay(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenHandle])

  return (
    <div style={{ backgroundColor: 'white', height: '100%', width: '100%', position: 'absolute', top: 0 }}>
      <FullScreen handle={fullScreenHandle}
        onChange={fullScreenChange}
      >
        {displayMain()}
      </FullScreen>
    </div>
  )

}

