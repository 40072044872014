import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { BarChart, LineChart } from '@mui/x-charts'
import { useEffect } from 'react'
import { useState } from 'react'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { displayError } from 'util/screenUtils'
import moment from 'moment'
import { getListenersForSong } from 'controllers/HarmonizeController'
import UserImage from 'components/image/UserImage'
import { getAccruedCreditInterval } from 'util/creditUtils'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
export default function SongListeners({ messageId }) {
    const accessToken = getStorageAccessToken()
    const [loading, setLoading] = useState()
    const [songListeners, setSongListeners] = useState([])
    const [error, setError] = useState()


    const displayLoadingSong = () => {
        return (
            <div style={{ width: '100%' }}>
                <h4>Loading song listeners...</h4>
                <TimerProgress />
            </div>
        )
    }

    const displaySongListener = (listener, ix) => {
        const { firstName, lastName, handle, count } = listener
        return (
            <div key={ix} style={{ display: 'flex', alignItems: 'center' }}>
                <UserImage image={listener} style={imagesStyles.bidProfileImageContainer} />{firstName} {lastName} {handle} {Intl.NumberFormat('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 1, maximumFractionDigits: 2 }).format(count / getAccruedCreditInterval(false))} listens
            </div>
        )
    }
    const displaySongListeners = () => {
        return (
            songListeners.map((sl, ix) => displaySongListener(sl, ix))
        )
    }
    const getSongListeners = async () => {
        try {
            setError()
            setLoading(true)
            const listeners = await getListenersForSong(messageId, accessToken)
            setSongListeners(listeners)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            displayError(error, setError)
        }
    }
    useEffect(() => {
        getSongListeners()
    }, [])

    return (
        <div style={{ overflowY: 'scroll' }}>
            <ErrorLine error={error} />
            {loading && displayLoadingSong()}
            {displaySongListeners()}
        </div>

    )
}