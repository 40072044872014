import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import Footer from 'components/Footer/Footer.js'

import styles from 'assets/jss/material-kit-react/views/profilePage.js'

import { useDispatch, useSelector } from 'react-redux'
import DisplayStorage from 'components/DisplayStorage'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { updateUser } from 'controllers/AccountController'
import { setAccount } from 'redux/actions/accountActions'
import { setSettingsCurrency } from 'redux/actions/settingsActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { reloadReduxSettings } from 'redux/actions/settingsActions'
import { reloadReduxAccount } from 'redux/actions/accountActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { getStorageAccount } from 'redux/actions/accountActions'

const useStyles = makeStyles(styles)

let initialized
export default function Settings() {
  const brandName = process.env.REACT_APP_NAME
  const dispatch = useDispatch()

  if (!initialized) {
    console.log('INIT Settings')
    initialized = true
    dispatch(reloadReduxSettings())
    dispatch(reloadReduxAccount())
  }

  const [currencies, setCurrencies] = useState([])
  const [error, setError] = useState()

  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { currency } = useSelector((state) => state.settings)

  const classes = useStyles()
  const userUpdate = async (revisedUser) => {
    try {
      const updatedUser = await updateUser(revisedUser, accessToken)
      console.log('Received updated user', updatedUser)
      const updatedAccount = { ...updatedUser, img: account.img }
      setAccount(updatedAccount)
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }
  const displayCurrencies = () => {
    return (
      <div style={{ color: 'black' }}>
        <h3>Select Your Currency</h3>
        <p style={{ textAlign: 'left' }}>
          Select the default currency used to display ether (ETH or &Xi;) rate
          conversions. These displays are for information purposes only, to help
          you understand {brandName} NFT prices in a familiar currency.{' '}
          {brandName} NFTs are always transacted in ether, the native currency
          of Ethereum.
        </p>
        <Autocomplete
          id='currency-select'
          style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
          options={currencies}
          defaultValue={currency}
          onChange={(evt, value, reason) => {
            console.log(`autcomplete ${value} ${reason}`)
            if (reason === 'select-option') {
              const updatedUser = { ...account, currency: value }
              dispatch(setSettingsCurrency(value))
              userUpdate(updatedUser)
            }
          }}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </div>
    )
  }

  return (
    <>
      <HarmonizeHeader />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            {error && (
              <h4 style={{ color: 'red', textAlign: 'center' }}>{error}</h4>
            )}
            {displayCurrencies()}
            <DisplayStorage />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
