
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
//The following did not work: We had to take the scss from the Github lib and include it in our scss files.
//import 'react-h5-audio-player/lib/styles.css'
import { setPlayerMode, setPlayListIndex } from 'redux/actions/harmonizeActions'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import {
    Add, Apps, Close, InsertEmoticon, Favorite, FavoriteBorderTwoTone,
    List, ListAlt, MusicNote, OndemandVideo, PersonAdd, PersonOutline, QueueMusic, Shuffle,
    SkipNext, SkipPrevious, TrackChanges, Chat, DataUsage, HelpOutline,
    FavoriteBorder, Lock, LockOpen, Public, Fullscreen, Share, Edit, Cancel
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { useState, createRef } from 'react'
import { isAdminUser } from 'util/adminUtils.js'
import UserImage from 'components/image/UserImage'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import { primaryColor } from 'assets/jss/material-kit-react.js'
import { useHistory } from 'react-router-dom'
import { isVeryNarrow } from 'util/screenUtils'
import { Snackbar, SnackbarContent, useMediaQuery } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.js'
import { displayHomeIcon } from 'util/brandUtils'
import { updateSubscribed } from 'controllers/AccountController'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { logWithTime } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'
import { setAccount } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { canLikePost } from 'util/transactionUtils'
import { addLike } from 'util/socialServiceUtils'
import { deleteLike } from 'util/socialServiceUtils'
import { useEffect } from 'react'
import { downloadCoreFile } from 'controllers/BackblazeController'
import ErrorLine from 'components/ErrorLine'
import { setPlayTime } from 'redux/actions/harmonizeActions'
import { setEngaged } from 'redux/actions/harmonizeActions'
import ShareContent from 'components/harmonize/ShareContent'
import BlurDialog from 'components/utility/BlurDialog'
import { setContinuousPlay } from 'redux/actions/harmonizeActions'
import useRefsCollection from 'react-refs-collection'
import EmojiPicker from 'emoji-picker-react'
import SmallProfileImage from 'components/auction/SmallProfileImage'
import moment from 'moment'
import { setReactorPlay } from 'redux/actions/harmonizeActions'
import { addReaction } from 'controllers/HarmonizeController'
import { getReactions } from 'controllers/HarmonizeController'
import { setShowChat } from 'redux/actions/harmonizeActions'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'
import Explain from 'components/Explain'
import { getAvailableCredits } from 'controllers/AccountController'
import Footer from 'components/Footer/Footer'
import { setHideOverlay } from 'redux/actions/harmonizeActions'
import { COLLECTION_TYPE } from 'util/postUtils'
import { isGooglebot } from 'util/screenUtils'
import { checkCreditUsage } from 'util/creditUtils'
import { setShowCollections } from 'redux/actions/harmonizeActions'
import Playlists from './Playlists'
import { setForceSignIn } from 'redux/actions/harmonizeActions'
import { getAccountId } from 'redux/actions/accountActions'
import { deleteReaction } from 'controllers/HarmonizeController'
import { PLAYER_STATES } from 'redux/reducers/harmonizeReducer'
import TimerProgress from 'components/mint/TimerProgress'
import { isPreviews } from 'util/postUtils'
import { needPreviewWarning } from 'redux/reducers/harmonizeReducer'
import { setLastPreviewWarning } from 'redux/actions/harmonizeActions'
import { setPostBuyCreditsPage } from 'redux/actions/harmonizeActions'
import { setPlayCurrentSong } from 'redux/actions/harmonizeActions'
import { setPostSignInPage } from 'redux/actions/harmonizeActions'

export const REACTOR_REACTIONS = ['1f44d', '2764-fe0f', '1f603', '1f483', '1f57a', '1f3b8', '1f3b9', '1f941']
const REACTOR_HIGHLIGHT_START = '1f449' //Finger pointing to the right: 'Point right'

let currentPlayTime
let reactorLines = []
/** We use user instead of userId because that is what the Social Service db uses. */
export default function HarmonizePlayer({
    setCollectionPlaylist, shuffle, messageSocket, enterFullScreen }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const videoWidth = '240px'
    const playIconTop = 120 * .56 - 20

    const playerStyles = {
        chatIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em'
        },
        controlIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em',
            marginRight: veryNarrow ? '0.25em' : '1em'
        },
        fullscreen: { color: 'gold', fontWeight: 'bolder', WebkitTextStroke: '0.05px black' },
        randomControlIcon: {
            fontSize: veryNarrow ? '1em' : '1.5em',
            marginRight: veryNarrow ? '0.25em' : '.5em'
        },
        likeIcon: {
            fontSize: veryNarrow ? '1.5em' : '2em',
        },
        helpLine: {
            display: 'flex', alignItems: 'center', fontSize: veryNarrow ? '0.9em' : 'inherit', borderBottom: '0.5px dashed black'
        },
        shareIcon: {
            fontSize: veryNarrow ? '1em' : '1.5em',
            marginRight: veryNarrow ? '0.25em' : '1em'
        },
    }
    const PREVIEW_LENGTH = parseInt(process.env.REACT_APP_AUDIO_PREVIEW_LENGTH)
    const accessToken = getStorageAccessToken()
    const account = getStorageAccount()
    const { availableCredits, subscribedRooms } = account ? account : { availableCredits: 0, subscribedRooms: [] }
    const user = getAccountId()
    const { accruedCredits, continuousPlay, collections, creditPlayTime,
        currentCollection, fullscreen, hideOverlay,
        lastPreviewWarning, playCurrentSong,
        playerMode, playerState, playList, playListIndex, playTime, reactorPlay, showChat,
        showCollections } = useSelector(state => state.harmonize)
    const { currentRoom, currentRoomOwner, messages } = useSelector(state => state.messages)
    const { accessType } = currentRoom
    const isPrivateRoom = accessType === ACCESS_TYPES.PRIVATE
    const [error, setError] = useState()
    const [paused, setPaused] = useState(false)
    const [playStart, setPlayStart] = useState(null)
    const [firstPlay, setFirstPlay] = useState(true)
    const [showShare, setShowShare] = useState(false)
    const [showShareCollection, setShowShareCollection] = useState(false)
    const [copiedUrl, setCopiedUrl] = useState()
    const [outOfCredits, setOutOfCredits] = useState(false)
    const [previewWarning, setPreviewWarning] = useState(false)
    const [ownerUrl, setOwnerUrl] = useState()
    const [timeOverlay, setTimeOverlay] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [randomPlay, setRandomPlay] = useState(false)
    const [randomPlayList, setRandomPlayList] = useState([])
    const [randomPlayStart, setRandomPlayStart] = useState(0)
    const [receivedReaction, setReceivedReaction] = useState()
    const [displayReactorLines, setDisplayReactorLines] = useState([])
    const [helpTarget, setHelpTarget] = useState()
    const [explain, setExplain] = useState()
    const { getRefHandler, getRef } = useRefsCollection()
    const dispatch = useDispatch()
    const history = useHistory()
    const player = createRef()

    const displayIntro = () => {
        const { name: roomName } = currentRoom
        return (
            <div onClick={() => setExplain(null)}>
                <p style={playerStyles.helpLine}>
                    <span style={{ fontWeight: 'bold', color: 'gold', backgroundColor: 'blue', margin: '0.25em' }}>Playlists</span>&nbsp;Displays all Playlists for this Studio
                </p>
                <p style={playerStyles.helpLine}>
                    <PersonAdd style={{ fontSize: '2em' }} />&nbsp;Subscribe to this Studio. You will receive an email notification whenever a new song is posted.
                </p>
                <p style={playerStyles.helpLine}>
                    <DataUsage style={{ fontSize: '2em' }} />&nbsp;Displays your current streaming credits. Click to buy more. If you
                    don't see this, you are not charged for streaming in the {roomName} studio.
                </p>
                <p style={playerStyles.helpLine}>
                    <Shuffle style={{ fontSize: '2em' }} />&nbsp;Shuffles the current playlist
                </p>
                <p style={playerStyles.helpLine}>
                    <Fullscreen style={{ fontSize: '2em' }} />&nbsp;Switch to a full screen song player
                </p>
                <p style={playerStyles.helpLine}>
                    <ListAlt style={{ fontSize: '2em' }} />&nbsp;Switch to compact song listings
                </p>
                <p style={playerStyles.helpLine}>
                    <List style={{ fontSize: '2em' }} />&nbsp;Switch to full song listings
                </p>
                <p style={playerStyles.helpLine}>
                    <Apps style={{ fontSize: '2em' }} />&nbsp;Switch to a song grid
                </p>
                <div style={playerStyles.helpLine}>
                    <div>
                        <QueueMusic style={{ fontSize: '2em' }} />&nbsp;<MusicNote style={{ fontSize: '2em' }} /></div>
                    Switch between continuous play and single play
                </div>
                <p style={playerStyles.helpLine}>
                    <FavoriteBorder style={{ fontSize: '2em' }} />&nbsp;Add the current song to your favorites
                </p>
                <p style={playerStyles.helpLine}>
                    <TrackChanges style={{ fontSize: '2em' }} />&nbsp;Play {process.env.REACT_APP_AUDIO_PREVIEW_LENGTH} seconds each from a random selection in the current playlist
                </p>
                <p style={playerStyles.helpLine}>
                    <InsertEmoticon style={{ fontSize: '2em' }} />&nbsp;Open the Reactor to record your reactions to the current song
                </p>
                <p style={playerStyles.helpLine}>
                    <Chat style={{ fontSize: '2em' }} />&nbsp;Chat with other listeners in the {roomName} studio
                </p>
            </div>
        )
    }
    const explainPlayer = {
        contentFunction: displayIntro
    }

    const message = () => {
        if (playList[playListIndex]) {
            const msg = messages.find(m => m._id === playList[playListIndex].messageId)
            if (msg) {
                //console.log(`messageId at ${playListIndex} ${msg._id}`)
                return msg
            }
        }
        return { numLikes: 0, liked: false }
    }

    /**
     * 
     * @returns true if there is no accessToken and the Room access is PUBLIC
     */
    const isAnonymousAccess = () => {
        const anonymous = !accessToken && accessType === ACCESS_TYPES.PUBLIC
        return anonymous
    }

    const isHighlightEmoji = (unifiedEmoji) => {
        return unifiedEmoji === REACTOR_HIGHLIGHT_START
    }

    const isOwner = () => {
        const { userId } = message()
        return (user === userId)
    }

    const getPreviewLength = () => {
        const { highlightTime } = getPlaylistEntry()
        return highlightTime ? highlightTime + PREVIEW_LENGTH : PREVIEW_LENGTH
    }

    const getPreviewStart = () => {
        const { highlightTime } = getPlaylistEntry()
        return highlightTime ? highlightTime : 0
    }

    const addLikeToMessage = async () => {
        if (isAnonymousAccess()) {
            console.log('SignIn 5')
            doSignIn()
        } else {
            try {
                const { _id: messageId, userId } = message()
                if (canLikePost(user, userId, isAnonymousAccess())) {

                    try {
                        addLike(messageId, user, messageSocket())
                    } catch (error) {
                        console.error('error adding like', error)
                        displayError(error, setError, 1)
                    }
                }

            } catch (error) {
                displayError(error, setError, 2)
            }
        }
    }
    const deleteLikeFromMessage = () => {
        const { _id: messageId, userId } = message()
        if (canLikePost(user, userId, isAnonymousAccess())) {
            try {
                deleteLike(messageId, user, messageSocket())
            } catch (error) {
                console.error('error deleting like', error)
                displayError(error, setError, 3)
            }
        }
    }

    const displayShareSong = () => {
        return (
            <div title='Click to share' onClick={(evt) => {
                evt.stopPropagation()
                setShowShare(!showShare)
            }}>
                <Share style={playerStyles.shareIcon} />
            </div>
        )
    }

    const displayLikes = () => {
        const { numLikes, liked } = message()
        return (
            <div style={{ display: 'flex', paddingRight: numLikes > 0 ? '10px' : '0', alignItems: 'center' }}>
                {liked ?
                    <Favorite style={playerStyles.likeIcon}
                        onClick={(evt) => {
                            evt.stopPropagation()
                            deleteLikeFromMessage()
                        }} />
                    :
                    <FavoriteBorderTwoTone style={{ ...playerStyles.likeIcon, ...{ marginRight: 0 } }}
                        onClick={(evt) => {
                            evt.stopPropagation()
                            checkAccess(() => addLikeToMessage())
                        }} />
                }
                {numLikes > 0 ? `${numLikes}` : null}
            </div>
        )

    }

    const displayCredits = () => {
        if (accessType !== ACCESS_TYPES.PUBLIC && accessToken) {
            return (
                <div
                    onClick={() => history.push('/BuyCredits')}
                    style={{
                        cursor: 'pointer',
                        zIndex: 200,
                        position: 'relative'
                    }}>
                    <DataUsage

                        style={{
                            color: availableCredits <= 5 ? 'red' : 'gold',

                            fontSize: '3em'
                        }} />
                    <span
                        title='Credits'
                        style={{
                            zIndex: 201, position: 'absolute', fontSize: '0.75em', top: '1.5em',
                            left: 0, color: 'white', width: '100%', textAlign: 'center'
                        }}>{Intl.NumberFormat({ maximumFractionDigits: 0, useGrouping: false }).format(availableCredits)}</span>
                </div>
            )
        }
    }

    const displayModeSelector = () => {
        switch (playerMode) {
            default:
            case PLAYER_MODES.LIST:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to a song grid' style={{ cursor: 'pointer' }}>
                            <Apps style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }}
                                onClick={() => checkAccess(() => dispatch(setPlayerMode(PLAYER_MODES.GRID)), true)} /></div>
                    </div>
                )

            case PLAYER_MODES.POST:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to compact listings' style={{ cursor: 'pointer' }}>
                            <ListAlt style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }}
                                onClick={() => checkAccess(() => dispatch(setPlayerMode(PLAYER_MODES.LIST)), true)} /></div>
                    </div>
                )
            case PLAYER_MODES.GRID:
                return (
                    <div style={{ display: 'flex' }}>

                        <div title='Switch to full listings' style={{ cursor: 'pointer' }}>
                            <List style={{ ...playerStyles.controlIcon, ...{ marginRight: 0 } }}
                                onClick={() => checkAccess(() => dispatch(setPlayerMode(PLAYER_MODES.POST)), true)} /></div>
                    </div>
                )
        }

    }

    /**
     * Displays the Shuffle and Player mode selector buttons.
     * @returns 
     */
    const displayAdditionalControls = () => {
        if (!fullscreen) {
            const visibility = playerState === PLAYER_STATES.SONG ? 'visible' : 'hidden'
            return (
                <div style={{ display: 'flex', visibility, alignItems: 'center' }}>
                    <div title='Shuffle' style={{ cursor: 'pointer' }}>
                        <Shuffle style={playerStyles.controlIcon} onClick={() => checkAccess(() => shuffle())} /></div>
                    {displayModeSelector()}
                </div>
            )
        }
    }

    /**
     * Displays the Share and Like buttons
     * @returns 
     */
    const displayCustomVolumeControls = () => {
        if (!fullscreen) {
            const visibility = playerState === PLAYER_STATES.SONG ? 'visible' : 'hidden'
            return (
                <div style={{ display: 'flex', visibility, alignItems: 'center' }}>
                    {displayShareSong()}
                    {displayLikes()}
                </div>
            )
        }
    }
    /**
     * @returns 
     */
    const displayQueuePlay = () => {
        if (continuousPlay) {
            return (
                <div title='Click for Single Play' style={{ display: 'flex', cursor: 'pointer' }}>
                    <QueueMusic style={playerStyles.controlIcon} onClick={() => dispatch(setContinuousPlay(false))} /></div>)

        } else {
            return (
                <div title='Click for Continuous Play' style={{ display: 'flex', cursor: 'pointer' }}>
                    <MusicNote style={playerStyles.controlIcon} onClick={() => {
                        //dispatch(setQueuePlay(true))
                        dispatch(setContinuousPlay(true))
                    }} />
                </div>)
        }

    }
    const displayCopied = () => {
        if (copiedUrl) {
            return (
                <Snackbar
                    open={copiedUrl}
                    autoHideDuration={2000}
                    onClose={() => setCopiedUrl(false)}
                    message='Copied'
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >

                </Snackbar>
            )
        }
    }


    const displayShare = () => {
        if (showShare && !isGooglebot(window.navigator.userAgent)) {
            const { name, messageId } = playList[playListIndex]
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    setShowShare(false)
                }}>
                    <BlurDialog content={() => <ShareContent messageId={messageId}
                        name={name}
                        setCopied={setCopiedUrl} />} hideHelp title={`Share ${name}`} closeIcon />
                </div>
            )
        }
    }

    const displayShareCollection = () => {
        if (showShareCollection && currentCollection) {
            const { name, _id: collectionId } = currentCollection
            const { name: roomName } = currentRoom
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    setShowShareCollection(false)
                }}>
                    <BlurDialog content={() => <ShareContent name={name} collectionId={collectionId} roomName={roomName}
                        setCopied={setCopiedUrl} />} hideHelp title={`Share the ${name} collection`} closeIcon top='0px' />
                </div>
            )
        }
    }

    const subscribeRoom = async (subscribe) => {
        if (accessToken) {
            const { _id } = currentRoom
            try {
                const updatedUser = await updateSubscribed(_id, subscribe ? 1 : 0, accessToken)
                logWithTime('subscribeRoom Received updated user', updatedUser)
                const { subscribedRooms } = updatedUser
                //Don't change anything especially availableCredits excpet subscribedRooms
                const updatedAccount = { ...account, subscribedRooms }
                setAccount(updatedAccount)
            } catch (error) {
                displayError(error, setError, 4)
            }
        } else {
            console.log('SignIn 6')
            doSignIn()
        }
    }

    const displaySubscribed = () => {
        const username = account ? account.username : undefined
        const { friendlyName } = currentRoom
        if (username !== currentRoomOwner.username) {
            let subscribed
            if (subscribedRooms && subscribedRooms.length) {

                const { _id } = currentRoom
                subscribed = subscribedRooms.findIndex(r => r.roomId === _id) !== -1
            }
            return (
                <div
                    onClick={() => checkAccess(() => subscribeRoom(!subscribed))}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        width: 40,
                        height: 40,
                        borderRadius: 20,
                        border: '0.5px solid white',
                        backgroundColor: 'blue',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    title={`Click to ${subscribed ? 'unsubscribe from' : 'subscribe to'} to ${friendlyName}`}
                >
                    {subscribed ? <PersonOutline style={{
                        height: '30px',
                        width: '30px'
                    }} /> :
                        <PersonAdd
                            style={{
                                height: '30px',
                                width: '30px'
                            }}
                        />}
                </div>
            )
        }

    }
    const displayCollections = () => {
        if (showCollections) {
            return (
                <div style={{
                    backgroundColor: primaryColor,
                    color: 'gold',
                    paddingTop: '0.75em',
                    paddingLeft: '0.75em'
                }}>{displayHeader()}
                    <div style={{ marginTop: '2em' }}>
                        <Playlists setCollectionPlaylist={setCollectionPlaylist} />
                    </div>
                </div>)
        }
    }

    const displayCollectionsButton = () => {
        //console.log(`displayCollectionsButton ${collections.length} collections`)
        if (accessType !== ACCESS_TYPES.PUBLIC && collections.length)
            return (
                <div title={`Click to show ${showCollections ? 'Songs' : 'Playlists'}`}
                    style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', }}
                    onClick={() => checkAccess(() => dispatch(setShowCollections(!showCollections)))}><span style={{
                        border: '1px solid gold',
                        padding: '0.2em',
                        fontWeight: 'bold', borderRadius: '4px'
                    }}>{showCollections ? 'Songs' : 'Playlists'}</span></div>
            )
    }
    const displayPreviewsButton = () => {
        if (isPreviews(currentRoom))
            return (
                <div title='Playing in preview mode. Click to buy credits'
                    style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', }}
                    onClick={() => history.push('/BuyCredits')}><span style={{
                        border: '1px solid gold',
                        color: 'darkorange',
                        padding: '0.2em',
                        fontWeight: 'bold', borderRadius: '4px'
                    }}>Previews</span></div>
            )
    }
    const displayAccessType = () => {
        switch (accessType) {
            default:
            case ACCESS_TYPES.PRIVATE:
                return <div title='Private'><Lock /></div>
            case ACCESS_TYPES.PROTECTED:
                return <div title='Protected'><LockOpen /></div>
            case ACCESS_TYPES.PUBLIC:
                return <div title='Public'><Public /></div>
        }

    }

    const displayFullScreenHeader = () => {
        if (fullscreen) {
            const { firstName, lastName, name } = playList[playListIndex]
            return (
                <div style={{
                    position: 'fixed', top: 0, width: '100%', padding: '1.5em', fontSize: veryNarrow ? '1.5em' : '2em', justifyContent: 'space-between', fontWeight: 'bold', color: 'white',
                    backgroundColor: 'transparent',
                    display: hideOverlay ? 'none' : 'flex'
                }}>
                    <span style={playerStyles.fullscreen}>{firstName} {lastName}</span>
                    <span style={playerStyles.fullscreen}>{name}</span>
                </div>
            )
        }
    }

    const isAccessAllowed = () => {
        const { accessType, user: roomOwner } = currentRoom
        return isAdminUser() ||
            accessType !== ACCESS_TYPES.PRIVATE || roomOwner === user
    }

    const displayInitHeader = () => {
        if (currentRoomOwner) {
            return (
                <div style={{ display: 'flex' }}>
                    {displayHomeIcon(history)}
                    <div style={{ display: 'flex', position: 'absolute', left: 0, width: '100%', justifyContent: 'center' }}>
                        <UserImage
                            title={`${currentRoomOwner.firstName}`}
                            image={{}}
                            style={imagesStyles.vortexProfileImageContainer}

                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <TimerProgress isWhite height={5} />
                        </div>

                    </div>

                </div>
            )
        }
    }

    const displayNoAccessHeader = () => {
        return (
            <div style={{ display: 'flex' }}>
                {displayHomeIcon(history)}
                <div style={{ display: 'flex', position: 'absolute', left: 0, width: '100%', justifyContent: 'center' }}>
                    Access denied
                </div>

            </div>
        )

    }
    const displaySongHeader = (entry) => {
        if (currentRoomOwner) {
            const { img, providerImgUrl, firstName, lastName, username, handle } = currentRoomOwner
            const { name: roomName } = currentRoom
            let roomOwnerUrl = `${process.env.REACT_APP_HARMONIZE_HOST}/@${handle}`
            if (roomName.toLowerCase() !== 'home') {
                roomOwnerUrl += `/${roomName}`
            }
            return (
                <div style={{ display: 'flex' }}>
                    {displayHomeIcon(history)}
                    <div style={{ display: 'flex', position: 'absolute', left: 0, width: '100%', justifyContent: 'center' }}>

                        <UserImage
                            title={`${currentRoomOwner.firstName}`}
                            clickTitle={`Click to display ${firstName} ${lastName}`}
                            image={{ img, providerImgUrl }}
                            style={imagesStyles.vortexProfileImageContainer}
                            click={() => {
                                history.push(`/UserProfile/${username}`)
                            }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ fontSize: '0.75em', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={() => setOwnerUrl(roomOwnerUrl)}>
                                {firstName}&nbsp;{lastName}&nbsp;@{handle}
                            </div>
                            {entry ? <span style={{ fontWeight: 'bold', fontSize: entry.name.length > 24 ? '0.75em' : '1em', paddingTop: '0.25em', paddingBottom: '0.25em' }}>{entry.name}</span> : null}
                            <div style={{ fontSize: '0.75em', display: 'flex', alignItems: 'center' }}>{displayAccessType()}&nbsp;{roomName}
                            </div>
                            {showCollections ? displayCollectionsButton() : null}
                        </div>

                    </div>
                    {displaySubscribed()}
                </div>
            )
        }
    }
    const displayStudioHeader = (empty) => {
        const { img, providerImgUrl, firstName, lastName, handle } = currentRoomOwner
        const { name: roomName } = currentRoom
        return (
            <div style={{ display: 'flex' }}>
                {displayHomeIcon(history)}
                <div style={{ display: 'flex', position: 'absolute', left: 0, width: '100%', justifyContent: 'center' }}>

                    <UserImage
                        title={`${currentRoomOwner.firstName}`}
                        image={{ img, providerImgUrl }}
                        style={imagesStyles.vortexProfileImageContainer}

                    />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ fontSize: '0.75em', display: 'flex', alignItems: 'center' }}>
                            {firstName}&nbsp;{lastName}&nbsp;@{handle}
                        </div>
                        {!empty ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <TimerProgress isWhite height={3} showTime={false} />
                        </div> : null}
                        <div style={{ fontSize: '0.75em', display: 'flex', alignItems: 'center' }}>{displayAccessType()}&nbsp;{roomName}
                        </div>
                        {showCollections ? displayCollectionsButton() : null}
                    </div>

                </div>
                {displaySubscribed()}
            </div>
        )
    }

    const displayHeader = (entry) => {
        if (!isAccessAllowed()) {
            return displayNoAccessHeader()
        } else if (!fullscreen) {
            switch (playerState) {
                default:
                case PLAYER_STATES.INIT:
                    return displayInitHeader()
                case PLAYER_STATES.STUDIO:
                    return displayStudioHeader()
                case PLAYER_STATES.EMPTY_STUDIO:
                    return displayStudioHeader(true)
                case PLAYER_STATES.SONG:
                case PLAYER_STATES.MESSAGE:
                    return displaySongHeader(entry)
            }
        }
    }

    const closePreviewWarning = () => {
        setPreviewWarning(false)
        dispatch(setLastPreviewWarning(Date.now()))
    }

    const displayPreviewWarning = () => {
        if (previewWarning && needPreviewWarning(lastPreviewWarning) && currentRoomOwner) {
            const { handle: roomOwnerHandle } = currentRoomOwner
            const { name: roomName } = currentRoom
            const message = `Your playback is limited to previews of ${PREVIEW_LENGTH} seconds. Buy streaming credits to enjoy full songs.`
            const clickMessage = 'Click to buy credits'
            const target = '/BuyCredits'
            const postBuyCreditsPage = `/@${roomOwnerHandle}/${roomName}`
            console.log(`displayPreviewWarning:\ntarget ${target} postBuyCreditsPage ${postBuyCreditsPage}`)
            return (
                <Snackbar
                    open={previewWarning}
                    autoHideDuration={5000}
                    onClose={closePreviewWarning}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                >
                    <SnackbarContent
                        style={{
                            backgroundColor: 'blue', border: '1px solid white',
                            marginTop: '5em'
                        }}
                        message={message}
                        action={<div style={{ width: '60vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Cancel onClick={closePreviewWarning} />
                            <div style={{ cursor: 'pointer', color: 'gold' }} onClick={() => {
                                closePreviewWarning()
                                dispatch(setPostBuyCreditsPage(postBuyCreditsPage))
                                history.push(target)
                            }}>
                                <i>{clickMessage}</i>
                            </div></div>} />
                </Snackbar>
            )

        }
    }

    const displayOwnerUrl = () => {
        if (ownerUrl) {
            const message = `The URL for this studio is ${ownerUrl}`
            return (
                <Snackbar
                    open={ownerUrl}
                    autoHideDuration={5000}
                    onClose={() => setOwnerUrl()}
                    message={message}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                >

                </Snackbar>
            )
        }
    }

    const displayOutOfCredits = () => {
        if (outOfCredits) {
            let message, clickMessage, target
            const isPublic = accessType === ACCESS_TYPES.PUBLIC
            if (isPublic) {
                message = 'There are no more streaming credits available in this Studio'
                clickMessage = 'Click to go home'
                target = '/'
            } else {
                message = `Your playback will be limited to previews of ${PREVIEW_LENGTH} seconds until you purchase streaming credits.`
                clickMessage = 'Click to buy credits'
                target = '/BuyCredits'
            }
            return (
                <Snackbar
                    open={outOfCredits}
                    autoHideDuration={isPublic ? 5000 : undefined}
                    onClose={() => {
                        setOutOfCredits(false)
                        if (isPublic) {
                            history.push(target)
                        }
                    }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                >
                    <SnackbarContent
                        style={{ backgroundColor: 'blue', border: '1px solid white' }}
                        message={message}
                        action={<div style={{ cursor: 'pointer', color: 'gold' }} onClick={() => {
                            setOutOfCredits(false)
                            history.push(target)
                        }}>
                            <i>{clickMessage}</i>
                        </div>} />
                </Snackbar>
            )

        }
    }

    const checkCredits = async () => {
        if (!isPreviews(currentRoom)) {
            try {
                const currentCredits = await checkCreditUsage(accruedCredits, creditPlayTime, currentRoom, getPlaylistEntry(),
                    account, accessToken, dispatch)
                if (currentCredits < 1) {
                    setOutOfCredits(true)
                }
            } catch (error) {
                displayError(error, setError, 5)
                //If applyCredit FAILS we still have to reset the accruedCredits array or we blow up the server
                //with a huge array. There should only be max 6 entries (with a 1 minute server update interval and a 10 second accruedCredit interval)
                dispatch(setCreditPlayTime(1))
                dispatch(setAccruedCredits([]))
            }
        }
    }

    const playSong = () => {
        setFirstPlay(false)
        dispatch(setPlayCurrentSong(null))
        currentPlayTime = Math.round(player.current.audio.current.currentTime)
        const { messageId, highlightTime, name } = getPlaylistEntry()
        console.log(`playSong ${name} paused ${paused} time ${currentPlayTime}`)
        setError('')
        if (paused) {
            setPaused(false)
        } else {
            if (isPreviews(currentRoom)) {
                player.current.audio.current.currentTime = currentPlayTime = highlightTime ? highlightTime : 0
            } else if (randomPlay) {
                player.current.audio.current.currentTime = randomPlayStart
            }
            if (accessToken) {
                const handle = account ? account.handle : undefined
                console.log(`onPlay send playing ${messageId}`)
                safeEmit('playing', { handle, messageId, name, currentPlayTime })
            }
            console.log(`onPlay play from time ${currentPlayTime}`)
            setPaused(false)
            setPlayStart(Date.now())
            dispatch(setPlayTime(0))
            dispatch(setEngaged(false))
            if (fullscreen) {
                dispatch(setHideOverlay(false))
                setTimeOverlay(true)
            }
        }
    }
    /**
     * If this is a PRIVATE studio, then it is possible to have an undefined audio source so that messages can be
     * created; otherwise, no audio source means display just a header.
     * 
     * @See https://lhz516.github.io/react-h5-audio-player/?path=/docs/layouts-advanced--stacked
     * @returns 
     */
    const displayPlayer = () => {
        const entry = getMediaEntry('audio/mpeg')
        if (entry) {
            const { source } = entry
            //console.log(`\n---> displayPlayer firstPlay ${firstPlay} state ${playerState} source ${source}`)
            return (
                <AudioPlayer
                    ref={player}
                    header={displayHeader(entry)}
                    footer={displayAudioFooter(entry)}
                    autoPlayAfterSrcChange={playCurrentSong || (!firstPlay && continuousPlay)}
                    progressJumpSteps={{
                        forward: 10000,
                        backward: 10000
                    }}
                    src={source}
                    //showJumpControls={source !== undefined}  -- These are the next/prev/ff/rewind icons
                    customAdditionalControls={[displayAdditionalControls()]}
                    customProgressBarSection={
                        hideOverlay ? [] : [

                            RHAP_UI.CURRENT_TIME,
                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.CURRENT_LEFT_TIME,
                            displayCredits()
                        ]
                    }
                    customVolumeControls={[displayCustomVolumeControls()]}
                    showFilledVolume
                    showSkipControls={true}
                    onPause={() => setPaused(true)}
                    onPlay={() => checkPreviewAccess(() => playSong(), true, () => player.current.audio.current.pause())
                    }
                    onClickNext={() => playNext()}
                    onEnded={() => continuousPlay ? playNext() : setPlayStart(null)}
                    onClickPrevious={() => playPrevious()}
                    onListen={() => {
                        if (player.current && player.current.audio && !paused) {
                            checkCredits()
                            currentPlayTime = Math.round(player.current.audio.current.currentTime)
                            if (isPreviews(currentRoom) &&
                                (currentPlayTime > getPreviewLength()
                                    || currentPlayTime < getPreviewStart())) {
                                //player.current.audio.current.currentTime = getPreviewStart()
                                if (currentPlayTime > getPreviewLength() && continuousPlay) {
                                    playNext()
                                } else {
                                    player.current.audio.current.currentTime = getPreviewStart()
                                    player.current.audio.current.pause()
                                }
                            } else if (randomPlay && currentPlayTime > PREVIEW_LENGTH) {
                                playNext()
                            }
                            /*
                            if (playStart) {
                                recordEngagement(entry)
                            }
                            */
                        }
                    }}
                    /* We are ignoring these errors because the tend to occur when the page is reloaded
                       and they have no real conseuqnce we think */
                    onError={(error) => {
                        console.error('Error playing audio', error)
                        //setError(`Unable to play this audio`)
                    }}
                    onPlayError={(error) => {
                        console.error('PlayError playing audio', entry)
                        //setError(`Unable to play this audio (play error)`)
                    }}
                    style={{
                        backgroundColor: fullscreen ? 'transparent' : primaryColor,
                        color: 'gold'
                    }}
                />

            )

        }
    }

    const displayPlayVideo = () => {
        const fontSize = veryNarrow ? '2em' : '3em'
        return (
            <div style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}
            >
                {imageSrc ? (<div><img
                    alt=''
                    src={imageSrc}
                    style={{ width: videoWidth }} />
                    <OndemandVideo style={{ fontSize, position: 'absolute', top: playIconTop, left: 0, width: videoWidth }} /></div>)
                    : <OndemandVideo style={{ fontSize }} />}
            </div>
        )


    }

    const getActionableCollectionName = () => {
        const { name, user: collectionUser, _id } = currentCollection
        return (
            <>
                <div style={{ cursor: 'pointer', position: 'absolute', left: 0 }} title={`Click to close ${name}`}
                    onClick={(evt) => {
                        evt.stopPropagation()
                        setCollectionPlaylist()
                    }}><Close />
                </div>
                {currentCollection.name}
                {user === collectionUser ?
                    <div style={{ cursor: 'pointer', position: 'absolute', right: 10 }} title={`Click to edit ${name}`}>
                        <Edit style={{ cursor: 'pointer', margin: '0.5em' }} onClick={() => {
                            history.push(`/EditCollection/${_id}`)
                        }} /></div>
                    : null}</>
        )
    }
    const displayCollectionHeader = () => {
        const { _id } = currentRoom
        if (accessType !== ACCESS_TYPES.PUBLIC && currentCollection && currentCollection.roomId === _id) {
            const { name, collectionType, user: collectionUser, _id } = currentCollection
            if (collectionType !== COLLECTION_TYPE.STUDIO) {
                return (
                    <div style={{
                        backgroundColor: 'white', alignItems: 'center', display: 'flex',
                        color: 'black', justifyContent: 'center', border: '1px solid gold',
                        fontWeight: 'bold',
                        padding: '0.2em',
                        borderRadius: '1em',
                        marginTop: '0.5em',
                        position: 'relative'
                    }}>
                        {isPreviews(currentRoom) ? `${name}` : getActionableCollectionName()}
                    </div>
                )
            }
        }
    }

    /**
     * Force a sign in. We tried setForceSignIn; however when creating an account by email this resulted
     * in the dialog being hmmm OK the issue was that the Firebase dialog does not stopPropagation and
     * clicking a field was cascading down to a click listener in DisplaySignIn. We removed that
     * and problem solved. No it didn't eff it.
     */
    const doSignIn = () => {
        const { handle: roomOwnerHandle } = currentRoomOwner
        const { name } = currentRoom
        dispatch(setPostSignInPage(`/@${roomOwnerHandle}/${name}`))
        //dispatch(setForceSignIn(true))
        history.push(`/SignIn/${roomOwnerHandle}/${name}`)
    }

    /**
     * Check if either there is an accessToken, or public performance is allowed. If not,
     * then go to the SignIn page.
     * @param {*} perform        Function to perform
     * @param {*} allowPublic    True if allowing access from PUBLIC
     */
    const checkAccess = (perform, allowPublic) => {
        const { handle: roomOwnerHandle } = currentRoomOwner
        const { name } = currentRoom
        console.log(`checkAccess`)
        if (accessToken || (allowPublic && accessType === ACCESS_TYPES.PUBLIC)) {
            console.log(`checkAccess PERFORM allowPublic ${allowPublic} accessType ${accessType}`)
            perform()
        } else {
            console.log('checkAccess SignIn 1')
            doSignIn()
        }
    }

    /**
     * 
     * @param {*} perform 
     * @param {*} allowPublic 
     * @param {*} contraAction 
     */
    const checkPreviewAccess = (perform, allowPublic, contraAction) => {
        console.log(`checkPreviewAccess allowPublic ${allowPublic} currentRoom ${currentRoom}`)
        if (accessToken || isPreviews(currentRoom) || (allowPublic && accessType === ACCESS_TYPES.PUBLIC)) {
            console.log(`checkPreviewAccess PERFORM isPreviews ${isPreviews(currentRoom)} allowPublic ${allowPublic} accessType ${accessType}`)
            perform()
        } else {
            console.log('checkPreviewAccess SignIn 2')
            doSignIn()
            if (contraAction) {
                contraAction()
            }
        }
    }

    const displayRandomAndCollections = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div title='Help' style={{ cursor: 'pointer' }}>
                        <HelpOutline style={playerStyles.controlIcon} onClick={(event) => {
                            setHelpTarget(event.currentTarget)
                            setExplain(explainPlayer)
                        }} />
                    </div>
                    <div title='Sampler' style={{ cursor: 'pointer' }}>
                        <TrackChanges style={playerStyles.controlIcon} onClick={() =>
                            checkAccess(() => randomPlay ? resetRandomPlay() : createRandomPlayList())

                        } />
                    </div>
                    <div title='Switch to fullscreen' style={{ cursor: 'pointer' }}>
                        <Fullscreen style={playerStyles.controlIcon} onClick={() => {
                            checkAccess(() => enterFullScreen(), true)
                        }} /></div>
                </div>
                {isPreviews(currentRoom) ? displayPreviewsButton() : displayCollectionsButton()}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {displayQueuePlay()}
                    <div title='Reactor' style={{ cursor: 'pointer' }}>
                        <InsertEmoticon style={playerStyles.controlIcon} onClick={() => checkAccess(() => startOrStopReactor(reactorPlay))} />
                    </div>
                    <div title='Chat' style={{ visibility: isPrivateRoom ? 'hidden' : 'visible', cursor: 'pointer' }}>
                        <Chat style={playerStyles.chatIcon} title='Chat'
                            onClick={(evt) => {
                                evt.stopPropagation()
                                checkAccess(() => { dispatch(setShowChat(!showChat)) })
                            }} />
                    </div>

                </div>
            </div>
        )
    }
    const displayAudioFooter = (entry) => {
        if (!fullscreen && isAccessAllowed()) {
            switch (playerState) {
                default:
                case PLAYER_STATES.INIT:
                case PLAYER_STATES.STUDIO:
                    return null
                case PLAYER_STATES.EMPTY_STUDIO:
                    return (
                        <div>
                            {displayCollectionsButton()}</div>
                    )
                case PLAYER_STATES.SONG:
                case PLAYER_STATES.MESSAGE:

                    const { source } = entry
                    if (source) {
                        return (
                            <div>
                                {displayRandomAndCollections()}
                                {displayCollectionHeader()}
                                {displayVideoPlayer()}
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {displayCollectionsButton()}</div>
                        )
                    }
            }
        }
    }
    /**
     * 
     * * NOTE * VideoPlayer does not work on some mobile (iPhones in Google emulator). Ignoring for now
     * 
     * This function creates an HREF to the VideoPlayer page. That page then uses the encrypted URL in the mediaEntry
     * to read the video from the Core Service.
     * 
     * The imageMediaSource is used to create the thumbnailUrl for structured data in VideoPlayer. It must be obtained from the same
     * Message that contains the video/mp4 media entry. There must always be an image in any Message that has a video.
     * @todo Switch to using HLSPlayer to avoid mobile rendering issues
     * @returns 
     */
    const displayVideoPlayer = () => {
        //if (!veryNarrow) {
        const entry = getMediaEntry('video/mp4')
        //console.log(`displayVideoPlayer ${entry.sourceName}`, entry)
        if (entry && entry.sourceName) {
            const imageEntry = getMediaEntry('image/jpeg')
            const mediaSource = imageEntry ? imageEntry.mediaSource : undefined
            const { name } = entry

            const url = `/videoplayer`
            return (
                <a href={url} style={{ textDecoration: 'none', color: 'inherit' }} >
                    <div title={`Click to play ${name} video`}
                        style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', flexDirection: 'column', textAlign: 'center' }}
                    >
                        {displayPlayVideo(mediaSource)}
                        <span style={{ fontSize: '.9em', fontStyle: 'italic' }}>Click to play {name} video</span>
                    </div>
                </a>
            )
        }
        //}
    }

    const getPlaylistEntry = () => {
        if (randomPlayList.length) {
            return randomPlayList[playListIndex]
        } else if (playList.length) {
            return playList[playListIndex]
        } else if (isPrivateRoom && messages.length) {
            const { _id: messageId, name, userId } = messages[playListIndex]
            return { entryMedia: [{ name, messageId, userId, source: undefined, mimeType: 'audio/mpeg' }] }
        } else {
            return { entryMedia: [{ name: 'No song', messageId: 0, userId: '', mimeType: undefined }] }
        }
    }

    /**
     * Get the media element for the current playList entry that matches the
     * requested mimeType
     * @param {*} mt
                        * @returns The entry or if not found a dummy {source: undefined, name: '--' }
                        */
    const getMediaEntry = (mt) => {
        if (isAccessAllowed()) {
            const { entryMedia } = getPlaylistEntry()
            for (const entry of entryMedia) {
                const { mimeType } = entry
                if (mimeType === mt) {
                    return entry
                }
            }
        }
        return { source: undefined, name: '--' }
    }


    const getReactionsForEntry = (entry) => {
        if (reactorPlay) {
            const { messageId } = entry
            populateReactions(messageId)
        }
    }
    const playNext = () => {
        if (playListIndex < playList.length - 1) {
            const entry = playList[playListIndex + 1]
            dispatch(setPlayListIndex(playListIndex + 1))
            const startPlayTime = isPreviews(currentRoom) ? getPreviewStart() : 0
            dispatch(setPlayTime(startPlayTime))
            player.current.audio.current.currentTime = startPlayTime
            setPlayStart(Date.now())
            getReactionsForEntry(entry)
            if (fullscreen) {
                dispatch(setHideOverlay(false))
                setTimeOverlay(true)
                setTimeout(() => {
                    //console.log('...execute timeOverlay timeout disable hideOverlay')
                    dispatch(setHideOverlay(true))
                }, 5000)
            }
        } else {
            if (isPreviews(currentRoom)) {
                player.current.audio.current.pause()
            }
            setPlayStart(null)
        }
    }

    const playPrevious = () => {
        if (playListIndex > 0) {
            const entry = playList[playListIndex - 1]
            dispatch(setPlayListIndex(playListIndex - 1))
            dispatch(setPlayTime(0))
            setPlayStart(Date.now())
            getReactionsForEntry(entry)
            if (fullscreen) {
                dispatch(setHideOverlay(false))
                setTimeOverlay(true)
                setTimeout(() => {
                    //console.log('...execute timeOverlay timeout disable hideOverlay')
                    dispatch(setHideOverlay(true))
                }, 5000)
            }
        } else {
            setPlayStart(null)
        }
    }

    /**
     * Get Reactions for this Message, then start reactorPlay. If Reactions cannot be retrieved
     * reactorPlay does not start.
     */
    const populateReactions = async (messageId) => {
        try {
            const reactorReactions = await getReactions(messageId, accessToken)
            console.log(`<*>reactions for ${messageId}`, reactorReactions)
            reactorLines = []
            reactorReactions.forEach(r => {
                const { playTime } = r
                reactorLines.push({ ...r, reactorTime: playTime })
            })
            setDisplayReactorLines(reactorLines)
            dispatch(setReactorPlay(true))
        } catch (error) {
            displayError(error, setError, 6)
        }
    }

    const safeEmit = (type, message) => {
        if (messageSocket()) {
            messageSocket().emit(type, message)
        }
    }

    const startOrStopReactor = (stop) => {
        console.log(`<*>reactorPlay ${stop}`)
        if (stop) {
            reactorLines = []
            setDisplayReactorLines([])
            dispatch(setReactorPlay(false))
        } else if (accessToken) {
            dispatch(setContinuousPlay(false))
            player.current.audio.current.currentTime = 0
            const { _id: messageId } = message()
            populateReactions(messageId)
            player.current.audio.current.play()
        } else {
            console.log('SignIn 3')
            doSignIn()
        }
    }

    const displayReactor = () => {
        //console.log(`displayReactor ${reactorPlay}`)
        if (reactorPlay) {
            return displayReactions()
        }
    }

    /**
     * Delete the Reaction associated with the current Message
     * @param {*} idToDelete id of Reaction to delete
     */
    const deleteExistingReaction = async (idToDelete) => {
        console.log(`<*>deleteExistingReaction ${idToDelete}`, reactorLines)
        if (idToDelete) {
            const { _id: messageId } = message()
            try {
                await deleteReaction(messageId, idToDelete, accessToken)
            } catch (error) {
                displayError(error, setError)
            }
        } else {
            console.warn(`<*>WARNING: no Reaction idToDelete to delete`)
        }
    }

    const removeExistingHighlightReaction = () => {
        let reactorIx = 0, idToDelete
        const updatedReactorLines = [...reactorLines]
        console.log('<*>removeExistingHighlightReaction', updatedReactorLines)
        for (let reactorLine of reactorLines) {
            console.log(`<*>...reactorIx ${reactorIx}`, reactorLine)
            const { reactions } = reactorLine
            const ix = reactions.findIndex(r => r.isHighlight)
            if (ix !== -1) {
                reactions.splice(ix, 1)
                if (reactions.length) {
                    const updatedReactorLine = { ...reactorLine, reactions }
                    console.log(`<*>Found existing highlight at ${reactorIx}`, updatedReactorLine)
                    reactorLines.splice(reactorIx, 1, updatedReactorLine)
                } else {
                    console.log(`<*>Found existing highlight at ${reactorIx}, deleted reactor line`, reactorLine)
                    const { _id } = reactorLine
                    reactorLines.splice(reactorIx, 1)
                    idToDelete = _id
                }
                setDisplayReactorLines([...reactorLines])
                break
            }
            reactorIx++
        }
        console.log(`<*>removeExistingHighlightReaction returnd ${idToDelete}`, reactorLines)
        return idToDelete
    }

    /**
     * Take the received emojiObject of {unifiedEmoji, user, playTime} and add a reaction
    * to the reactions array in reactorLines at currentPlayTime. The completed reactorLine
    * for a given playTime is then {reactorTime, reactions}. If sendToServer is true this is then posted to the server for the
    * current messageId.
    * 
    * If sendToServer is true the Reaction returned from the server is used to add an _id to the local reactionLine. This is needed
    * if that line is later deleted.
    *
    * The caller must ensure the Reaction added is targeted for the current messageId.
    *
    * The Reaction added to the Social Service db does not need user, since it is taken from the signed-in User.
    * When posted to the messageSocket, user is required so that when it is received at other session
    * listeners, the proper User profile image is displayed.
    *
    * @param {*} emojiObject {unifiedEmoji, user, playTime} where user is the ID of the reacting User
    */
    const addMediaReaction = async (reaction, sendToServer = true) => {
        if (accessToken) {
            console.log(`<*> addMediaReaction sendToServer ${sendToServer}`, reaction)
            console.log('<*>...current reactorLines', reactorLines)
            const { playTime, unifiedEmoji, user } = reaction
            const highlightReaction = isHighlightEmoji(unifiedEmoji)
            const reactionToPush = { user, unifiedEmoji }
            let idToDelete
            if (highlightReaction) {
                reactionToPush.isHighlight = true
                idToDelete = removeExistingHighlightReaction()
            }
            const reactorLineIx = reactorLines.findIndex(r => r.reactorTime === playTime)
            if (reactorLineIx !== -1) {
                const reactorLine = reactorLines[reactorLineIx]
                console.log(`<*>Received new reaction at playTime ${playTime} ${reactorLine.reactorTime}`, reactorLine)
                reactorLine.reactions.push(reactionToPush)
                reactorLines.splice(reactorLineIx, 1, reactorLine)
                setDisplayReactorLines([...reactorLines])
            } else {
                const reactions = [reactionToPush]
                reactorLines.push({ reactorTime: playTime, reactions })
                reactorLines.sort((a, b) => {
                    return a.reactorTime < b.reactorTime ? -1 : a.reactorTime > b.reactorTime ? 1 : 0
                })
                setDisplayReactorLines([...reactorLines])
            }
            //console.log(`reactorLines at ${playTime}`, reactorLines)
            if (sendToServer) {
                try {
                    const { _id: messageId } = message()
                    const reaction = { messageId, unifiedEmoji, playTime }
                    if (highlightReaction) {
                        reaction.isHighlight = true
                        if (idToDelete) {
                            await deleteExistingReaction(idToDelete)
                        }
                    }
                    const updatedReaction = await addReaction(reaction, accessToken)
                    logWithTime('<*>added reaction to Social Service', updatedReaction)
                    const { _id, playTime: reactorTime } = updatedReaction
                    const reactorLineIx = reactorLines.findIndex(r => r.reactorTime === reactorTime)
                    if (reactorLineIx !== -1) {
                        reactorLines[reactorLineIx]._id = _id
                        setDisplayReactorLines([...reactorLines])
                        console.log('<*>reactorLines after server update', reactorLines)
                    } else {
                        console.warn(`<*>WARNING: No local reactorLine found for playTime ${reactorTime}`)
                    }
                    logWithTime('<*>added reaction to Social Service', updatedReaction)
                    safeEmit('reaction', { ...reaction, user })
                } catch (error) {
                    displayError(error, setError, 7)
                }
            }

        } else {
            console.log('SignIn 4')
            doSignIn()
        }
    }

    const [showReactions, setShowReactions] = useState(true)

    const createReaction = (emojiObject) => {
        const { unified: unifiedEmoji } = emojiObject
        return { unifiedEmoji, playTime: currentPlayTime, user }
    }

    /** Don't allow adding reactions unless playing */
    const displayReactionsOrPicker = () => {
        if (playStart) {
            if (showReactions) {
                const reactions = [...REACTOR_REACTIONS]
                if (isOwner()) {
                    reactions.push(REACTOR_HIGHLIGHT_START)
                }
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EmojiPicker reactionsDefaultOpen={true} allowExpandReactions={false}
                            reactions={reactions}
                            onReactionClick={async (eo) => { await addMediaReaction(createReaction(eo)); setShowReactions(true) }}
                        />
                        <Add style={playerStyles.controlIcon} onClick={() => { setShowReactions(false) }} />
                    </div>)
            } else {
                return (
                    <div >
                        <Close style={playerStyles.controlIcon} onClick={() => { setShowReactions(true) }} />
                        <EmojiPicker
                            hiddenEmojis={[REACTOR_HIGHLIGHT_START]}
                            onEmojiClick={async (eo) => { await addMediaReaction(createReaction(eo)); setShowReactions(true) }}
                        /></div>)
            }
        }
    }

    /**
     * It appears that the unifiedEmoji may comprise multiple hyphen-separated values. To solve this see:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/fromCodePoint
     * and
     * https://www.bennadel.com/blog/4084-rending-emoji-glyphs-using-hexadecimal-codepoints-in-javascript.htm
     * @param {*} reaction
                        * @param {*} ix
                        * @returns
                        */
    const displayReaction = (reaction, ix) => {
        const { unifiedEmoji, user } = reaction
        const actualEmoji = unifiedEmoji.split('-')
        const codePoints = []
        actualEmoji.forEach(e => {
            codePoints.push(parseInt(e, 16))
        })
        try {
            return (
                <div key={ix} style={{ display: 'flex', alignItems: 'center', overflowX: 'auto' }}>
                    <SmallProfileImage userId={user} />
                    {String.fromCodePoint.apply(null, codePoints)}
                </div>
            )
        } catch (error) {
            console.error(`Bad ${unifiedEmoji}`, error)
        }

    }
    const displayReactionLine = (l) => {
        const { reactorTime, reactions } = l
        const displayDuration = moment.duration(reactorTime, 'seconds')
        const backgroundColor = reactorTime === currentPlayTime ? 'gold' : 'white'
        return (
            <div key={reactorTime} style={{ display: 'flex', paddingLeft: '0.5em', backgroundColor, alignItems: 'center' }}
                onClick={(evt) => { evt.stopPropagation(); player.current.audio.current.currentTime = reactorTime }}>
                {displayDuration.format(reactorTime >= 60 ? 'm:ss' : '0:ss')} {reactions.map((r, ix) => displayReaction(r, ix))}
            </div>
        )
    }
    const displayReactions = () => {
        return (
            <div >
                {displayReactionsOrPicker()}
                {displayReactorLines.map(l => displayReactionLine(l))}
            </div>
        )
    }

    const createRandomPlayList = () => {
        // eslint-disable-next-line
        const newPlayList = playList.reduce(([a, b]) => (b.push(...a.splice(Math.random() * a.length | 0, 1)), [a, b]), [[...playList], []])[1]
        console.log('randomPlayList', newPlayList)
        setRandomPlayList(newPlayList)
        setRandomPlay(true)
        dispatch(setPlayListIndex(0))
        dispatch(setContinuousPlay(true))

    }

    const gotoRandomPlaySong = (mid) => {
        const ix = playList.findIndex(pl => pl.messageId === mid)
        if (ix !== -1) {
            setRandomPlay(false)
            setRandomPlayList([])
            dispatch(setPlayListIndex(ix))
            //player.current.audio.current.play()
        }
    }

    const displayRandomPlayLine = (rp, ix) => {
        return (
            <div key={rp.messageId}
                ref={getRefHandler(rp.messageId)}
                style={{ cursor: 'pointer', backgroundColor: ix === playListIndex ? 'gold' : 'white' }}
                onClick={(evt) => {
                    evt.stopPropagation()
                    gotoRandomPlaySong(rp.messageId)
                }}>
                {rp.name}
            </div>
        )
    }
    const displayRandomPlayList = () => {
        return (
            <div style={{ overflow: 'auto', height: '50vh' }} >
                {randomPlayList.map((rp, ix) => displayRandomPlayLine(rp, ix))}
            </div>
        )
    }

    const randomPlayHeader = () => {

        return (<div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', borderBottom: '1px solid gray' }}>
            <span style={{ fontStyle: 'italic' }}>Sampler</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>Start at&nbsp;<Button
                color='primary' size='sm' onClick={(evt) => {
                    evt.stopPropagation()
                    setRandomPlayStart(randomPlayStart === 0 ? 30 : 0)
                }}>{randomPlayStart}</Button></div>
            <SkipPrevious style={playerStyles.controlIcon} onClick={(evt) => {
                evt.stopPropagation()
                playPrevious()
            }
            } />
            <SkipNext style={playerStyles.controlIcon} onClick={(evt) => {
                evt.stopPropagation()
                playNext()
            }
            } /></div>)

    }

    const displayRandomPlay = () => {
        if (randomPlay) {
            return (
                <div onClick={(evt) => {
                    evt.stopPropagation()
                    resetRandomPlay()
                }}>
                    <BlurDialog content={displayRandomPlayList} hideHelp titleFunc={() => randomPlayHeader()} closeIcon top='50px' />
                </div>
            )
        }
    }

    const resetRandomPlay = () => {
        setRandomPlay(false)
        setRandomPlayList([])
        dispatch(setPlayListIndex(0))
        //dispatch(setContinuousPlay(false))
    }

    const loadMedia = async (imageEntry) => {
        const { mediaSource } = imageEntry
        console.log(`loadMedia playlistIndex ${playListIndex} ${mediaSource}`)
        if (mediaSource) {
            try {
                const data = await downloadCoreFile(mediaSource, 'image')
                const src = URL.createObjectURL(data)
                setError('')
                return { src, mediaSource }
            } catch (error) {
                displayError(error, setError, 8)
            }
        }
        return null
    }

    const displayMain = () => {
        return (
            <div>
                <ErrorLine error={error} />
                {showCollections ? displayCollections() : displayPlayer()}
                {displayFullScreenHeader()}
                {displayShare()}
                {displayShareCollection()}
                {displayCopied()}
                {displayRandomPlay()}
                {displayReactor()}
                {displayOutOfCredits()}
                {displayPreviewWarning()}
                {displayOwnerUrl()}


                {explain && (
                    <Explain
                        source={explain}
                        target={helpTarget}
                        close={() => setExplain(null)}
                    />
                )}
                {!fullscreen ? <Footer /> : null}
            </div>
        )
    }

    const initSession = (credits) => {
        console.log(`initSession credits ${credits}`)
        if (credits) {
            const account = getStorageAccount()
            setAccount({ ...account, availableCredits: credits })
        }
        startOrStopReactor(true)
        if (messageSocket() && accessToken) {
            messageSocket().on('reaction', async (reaction) => {
                setReceivedReaction(reaction)
            })

        }
    }

    useEffect(() => {
        if (isPreviews(currentRoom) && !previewWarning) {
            setPreviewWarning(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(`HarmonizePlayer useEffect playCurrentSong ${playCurrentSong}`)
        if (playCurrentSong) {
            const ix = playList.findIndex(pl => pl.name === playCurrentSong)
            if (ix !== -1) {
                console.log(`...play at ${ix}`)
                dispatch(setPlayListIndex(ix))
                playSong()
            }
        }
    }, [playCurrentSong])
    useEffect(() => {

        if (timeOverlay) {
            dispatch(setHideOverlay(false))
            setTimeOverlay(false)
            setTimeout(() => {
                //console.log('...execute timeOverlay timeout disable hideOverlay')
                dispatch(setHideOverlay(true))
            }, 5000)
        }
        /*
        return () => {
            dispatch(setHideOverlay(false))
            clearTimeout(timeoutId)
        }
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeOverlay])


    useEffect(() => {
        if (receivedReaction) {
            const { messageId: reactionMessageId } = receivedReaction
            logWithTime(`useEffect received reaction for ${reactionMessageId} current messageId ${getPlaylistEntry().messageId}`, receivedReaction)
            if (getPlaylistEntry().messageId === reactionMessageId) {
                addMediaReaction(receivedReaction, false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedReaction])


    /** Reset reactorPlay on first load of page. Connect the socket listener. */
    useEffect(() => {
        console.log(`HarmonizePlayer accessToken`, accessToken)
        if (accessType !== ACCESS_TYPES.PUBLIC && accessToken) {
            getAvailableCredits(accessToken).then(ac => ac < 1 ? setOutOfCredits(true) : initSession(ac)).catch(error => displayError(error, setError, 9))
        } else {
            initSession()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * This gets an image mediaEntry from the playList at the current playListIndex. This is so
     * that any image used in the player is correct for the current playList entry.
     */
    useEffect(() => {
        console.log(`\n *HarmonizePlayer useEffect messages ${messages.length} playListIndex ${playListIndex} playTime ${playTime}`, playList)

        if (playList.length) {
            const imageEntry = getMediaEntry('image/jpeg')
            if (imageEntry) {
                loadMedia(imageEntry).then(result => {
                    if (result) {
                        const { src } = result
                        setImageSrc(src)
                    }
                })
            }
        }
        dispatch(setEngaged(false))
        const { name } = getPlaylistEntry()
        if (name && name.length) {
            document.title = name
        }

        if (randomPlay) {
            const itemNode = getRef(randomPlayList[playListIndex].messageId)
            if (itemNode) {
                itemNode.scrollIntoView(false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playList, playListIndex, playerMode, reactorPlay])

    const handleVisibilityChange = () => {
        console.log(`**> HarmonizePlayer visibilityChange ${document.visibilityState} currentState ${document.visibilityState}`)
    }
    useEffect(() => {
        console.log(`**> HarmonizePlayer useEffect add visibilityChange listener visibility ${document.visibilityState}`)
        window.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            console.log('**> HarmonizePlayer remove visibilityChange listener')
            window.removeEventListener('visibilitychange', handleVisibilityChange)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return displayMain()
}