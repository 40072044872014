import * as REDUX_TYPES from '../types'
export const PLAYER_MODES = { NONE: 0, POST: 1, LIST: 2, GRID: 3 }

export const ONE_DAY_MS = 24 * 60 * 60 * 1000
export const PLAYER_STATES = {
  INIT: 0,
  STUDIO: 1,
  SONG: 2,
  MESSAGE: 3,
  EMPTY_STUDIO: 4
}

/**
 * 
 * @param {*} lastWarningDate A Date instance
 * @returns True if the lastWarningData is undefined or more than ONE_DAY_MS in the past
 */
export const needPreviewWarning = (lastWarningDate) => {
  console.log(`needPreviewWarning lastWarningData ${lastWarningDate} Date.now ${Date.now()}`)
  return !lastWarningDate || (Date.now() - lastWarningDate > ONE_DAY_MS)
}

const INITIAL_STATE = {
  accruedCredits: [],
  accruedCreditsRoom: undefined, //Track the room in which credits accrue so that, if the room changes, the pending credits are written to the original
  continuousPlay: true,
  collections: [],
  creditPlayTime: 1,
  currentCollection: undefined,
  engaged: false,
  forceSignIn: false,
  fullscreen: false,
  hideOverlay: false,
  lastDuration: 0,
  lastPlayListIndex: undefined,
  lastPreviewWarning: undefined,
  playCurrentSong: false,
  playList: [],
  playListIndex: 0,
  playerMode: PLAYER_MODES.LIST,
  playerState: PLAYER_STATES.INIT,
  playTime: 0,
  postBuyCreditsPage: undefined, //The page to which we transfer after BuyCredits
  postSignInPage: undefined, //The page to which we transfer after SignIn
  reactorPlay: false,
  showAi: false,
  showAttachments: false,
  showChat: false,
  showCollections: false
}

const harmonizeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case REDUX_TYPES.HARMONIZE.POST_BUY_CREDITS_PAGE:
      return {
        ...state,
        postBuyCreditsPage: action.postBuyCreditsPage,
      }
    case REDUX_TYPES.HARMONIZE.POST_SIGN_IN_PAGE:
      return {
        ...state,
        postSignInPage: action.postSignInPage,
      }
    case REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS:
      return {
        ...state,
        accruedCredits: action.accruedCredits,
      }
    case REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS_ROOM:
      return {
        ...state,
        accruedCreditsRoom: action.accruedCreditsRoom,
      }
    case REDUX_TYPES.HARMONIZE.SET_CONTINUOUS_PLAY:
      return {
        ...state,
        continuousPlay: action.continuousPlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_COLLECTIONS:
      return {
        ...state,
        collections: action.collections,
      }
    case REDUX_TYPES.HARMONIZE.SET_CREDIT_PLAY_TIME:
      return {
        ...state,
        creditPlayTime: action.creditPlayTime,
      }
    case REDUX_TYPES.HARMONIZE.SET_CURRENT_COLLECTION:
      return {
        ...state,
        currentCollection: action.currentCollection,
      }
    case REDUX_TYPES.HARMONIZE.SET_ENGAGED:
      return {
        ...state,
        engaged: action.engaged,
      }
    case REDUX_TYPES.HARMONIZE.SET_FORCE_SIGN_IN:
      return {
        ...state,
        forceSignIn: action.forceSignIn,
      }
    case REDUX_TYPES.HARMONIZE.SET_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen,
      }
    case REDUX_TYPES.HARMONIZE.SET_HIDE_OVERLAY:
      return {
        ...state,
        hideOverlay: action.hideOverlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_LAST_PREVIEW_WARNING:
      return {
        ...state,
        lastPreviewWarning: action.lastPreviewWarning,
      }
    case REDUX_TYPES.HARMONIZE.PLAY_CURRENT_SONG:
      return {
        ...state,
        playCurrentSong: action.playCurrentSong,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYLIST:
      return {
        ...state,
        playList: action.playList,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYLIST_INDEX:
      return {
        ...state,
        playListIndex: action.playListIndex,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYER_MODE:
      return {
        ...state,
        playerMode: action.playerMode,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAYER_STATE:
      return {
        ...state,
        playerState: action.playerState,
      }
    case REDUX_TYPES.HARMONIZE.SET_PLAY_TIME:
      return {
        ...state,
        playTime: action.playTime,
      }

    case REDUX_TYPES.HARMONIZE.SET_REACTOR_PLAY:
      return {
        ...state,
        reactorPlay: action.reactorPlay,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_AI:
      return {
        ...state,
        showAi: action.showAi,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_ATTACHMENTS:
      return {
        ...state,
        showAttachments: action.showAttachments,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_CHAT:
      return {
        ...state,
        showChat: action.showChat,
      }
    case REDUX_TYPES.HARMONIZE.SET_SHOW_COLLECTIONS:
      return {
        ...state,
        showCollections: action.showCollections,
      }
    default:
      return state
  }
}
export default harmonizeReducer
