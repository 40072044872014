import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { getMediaThumbnail } from 'util/imageUtils'
import { isVeryNarrow } from 'util/screenUtils'
import { getMessage } from 'controllers/VortexController'
import { displayError } from 'util/screenUtils'
import ErrorLine from 'components/ErrorLine'
export default function PlaylistCard({ collection, featured }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)

    const styles = {
        imageCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            width: veryNarrow ? '40vw' : '30vw',
            height: veryNarrow ? '40vw' : '30vw'
        },
        nameCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            height: veryNarrow ? '20vw' : '10vw'
        },
        hovered: {
            margin: 'auto',
            transform: 'scale3d(1.05, 1.05, 1)',
        },
        notHovered: {
            margin: 'auto',
        },
    }
    const [error, setError] = useState()
    const [hovered, setHovered] = useState(false)
    const { name, description, media, messages } = collection
    const [imageSrc, setImageSrc] = useState()
    const [artistName, setArtistName] = useState()

    const displayImageCard = () => {
        return (

            <Card
                className='plain'
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
                style={{
                    ...imageSrc ? styles.imageCard : styles.nameCard, ...hovered ? styles.hovered : styles.notHovered, ...imageSrc ? {
                        backgroundImage: 'url(' + imageSrc + ')',
                        backgroundSize: 'cover',
                        opacity: 1
                    } : { backgroundColor: 'gold' }
                }}

            >
                <h4 style={{
                    textAlign: 'center', fontWeight: 'bolder', color: 'white',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 1)',
                    marginBottom: 0
                }}>{name}</h4>
                <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>{artistName}</span>
                <ErrorLine error={error} />
                <span
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        fontSize: veryNarrow ? '0.75em' : '1em',
                        lineHeight: 'normal',
                        fontWeight: hovered ? 'bold' : 'normal',
                        color: hovered ? 'gold' : 'white',
                        backgroundColor: 'grey'
                    }}
                >
                    {description}
                </span>
            </Card>


        )
    }

    const getMediaForMessage = async (collectionMedia, collectionMessage) => {
        console.log(`PlaylistCard media`, collectionMedia)
        const imageMedia = getMediaThumbnail(collectionMedia)
        //console.log(`PlaylistCard imageMedia`, imageMedia)
        const { source } = imageMedia
        const data = await downloadCoreFile(source, 'image')
        const src = URL.createObjectURL(data)
        setImageSrc(src)
        if (featured) {
            const { firstName, lastName } = collectionMessage
            setArtistName(`${firstName} ${lastName}`)
        }
    }

    /** 
     * If the Playlist has defined media, use it for the cover image, otherwise
     * use that from the first song in the Playlist. 
     * Retrieval errors will occur if the collection references non-existing messages. */
    const getMedia = async () => {
        try {
            if (messages && messages.length) {
                console.log('&& getMedia collection', collection)
                let collectionMedia = media
                let collectionMessage
                if (!collectionMedia || featured) {
                    const { messageId } = messages[0]
                    collectionMessage = await getMessage(messageId)
                    if (collectionMessage && !collectionMedia) {
                        //console.log(`&& getMedia ${name} ${messageId}`, collectionMessage)
                        const { media } = collectionMessage
                        collectionMedia = media
                    }

                }
                await getMediaForMessage(collectionMedia, collectionMessage)
            }
        } catch (error) {
            displayError(error, setError)
        }
    }

    useEffect(() => {
        getMedia()
    }, [])

    return (
        displayImageCard()
    )
}