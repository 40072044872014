
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { applyCredit } from 'controllers/HarmonizeController'
import { chargeCreditToStudio } from 'controllers/HarmonizeController'
import { getAccountId } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'

export const CREDIT_TYPES = {
    AUDIO: 0,
    VIDEO: 1,
    PUBLIC_AUDIO: 2,
    ARTIST_AUDIO: 3
}

const ACCRUE_CREDIT_TIME = parseInt(process.env.REACT_APP_ACCRUE_CREDIT_TIME)
const CHARGE_CREDIT_TIME = parseInt(process.env.REACT_APP_CHARGE_CREDIT_TIME)
const ARTIST_ACCRUE_CREDIT_TIME = parseInt(process.env.REACT_APP_ARTIST_ACCRUE_CREDIT_TIME)
const ARTIST_CHARGE_CREDIT_TIME = parseInt(process.env.REACT_APP_ARTIST_CHARGE_CREDIT_TIME)

/**
 * Determine the number of accrued credits per Credit.
 * @param {*} artistUsage True if the Credit is charged to the song artist
 * @returns 
 */
export const getAccruedCreditInterval = (artistUsage) => {
    let accrueCreditTime, chargeCreditTime
    if (artistUsage) {
        accrueCreditTime = ARTIST_ACCRUE_CREDIT_TIME
        chargeCreditTime = ARTIST_CHARGE_CREDIT_TIME
    } else {
        accrueCreditTime = ACCRUE_CREDIT_TIME
        chargeCreditTime = CHARGE_CREDIT_TIME
    }
    return chargeCreditTime / accrueCreditTime
}

/**
 * This should be called from the AudioPlayer onListen with an interval of one second, except for when
 * the artist streams own music, where it is once every ten seconds. 
 * 
 * NO MORE PARTIAL CREDITS
 * @param {*} accruedCredits    Array of partial credits accrued to artist in the current Credit period
 * @param {*} creditPlayTime    Elapsed time in seconds for the current Credit period
 * @param {*} currentRoom       Studio in which Credits are being recorded
 * @param {*} playlistEntry     {userId,messageId} Identifies the artist and song for which the Credit is generated
 * @param {*} account           User account to which Credits are charged for non-PUBLIC Studios
 * @param {*} accessToken 
 * @param {*} dispatch 
 * @returns remaining credits. <1 means out of credits
 */
export const checkCreditUsage = async (accruedCredits, creditPlayTime, currentRoom, playlistEntry, account, accessToken, dispatch) => {
    const { accessType } = currentRoom
    const { userId, messageId } = playlistEntry
    const accountId = getAccountId()
    let accrueCreditTime, chargeCreditTime
    const artistUsage = accessType !== ACCESS_TYPES.PUBLIC && userId === accountId
    if (artistUsage) {
        accrueCreditTime = ARTIST_ACCRUE_CREDIT_TIME
        chargeCreditTime = ARTIST_CHARGE_CREDIT_TIME
    } else {
        accrueCreditTime = ACCRUE_CREDIT_TIME
        chargeCreditTime = CHARGE_CREDIT_TIME
    }
    //console.log(`...checkCreditUsage chargeCreditTime ${chargeCreditTime} accrueCreditTime ${accrueCreditTime} creditPlayTime ${creditPlayTime}`, playlistEntry)
    let currentCredits = 1
    if (creditPlayTime % accrueCreditTime === 0) {
        if (!(userId && messageId)) {
            throw new Error(`Warning: not enough information to record artist credit (${userId} ${messageId})`)
        } else if (accruedCredits.length > getAccruedCreditInterval(artistUsage)) {
            throw new Error(`Warning: too many accrued credits (${userId} ${messageId})`)
        } else {
            const ix = accruedCredits.findIndex(ac => ac.artist === userId && ac.messageId === messageId)
            if (ix !== -1) {
                accruedCredits[ix].count += 1
                console.log(`...increase accruedCredits for ${userId} to ${accruedCredits[ix].count}`)
            } else {
                const accruedCredit = { count: 1, artist: userId, messageId }
                accruedCredits.push(accruedCredit)
            }
            console.log(`...accruedCredit to ${userId} for song ${messageId}`, accruedCredits)
            dispatch(setAccruedCredits([...accruedCredits]))
        }
    }
    if (creditPlayTime >= chargeCreditTime) {
        currentCredits = await recordCreditUsage(currentRoom, accruedCredits, 1, account, artistUsage, accessToken, dispatch)
    } else {
        dispatch(setCreditPlayTime(creditPlayTime + 1))
    }
    return currentCredits
}

/*
 * Write the credits associated with currentRoom, then reset playTime to 1, empty the
 * accruedCredits array, and set the accruedCreditsRoom to currentRoom.
 * 
 * @param {*} creditsUsed
 * @return currentCredits. If this is <1 the user is out of streaming credits
 
const recordCreditUsage = async (creditsUsed, accruedCredits, currentRoom, account, accessToken, dispatch) => {
    let currentCredits = await writeCreditsForRoomOrAccount(currentRoom, accruedCredits, creditsUsed, account, accessToken, dispatch)
    dispatch(setCreditPlayTime(1))
    dispatch(setAccruedCredits([]))
    //dispatch(setAccruedCreditsRoom(currentRoom))
    return currentCredits
}
*/

/**
 * Write the Credit record either for an account, or for the creditsRoom.
 * 
 * In a PUBLIC studio, credits are charged against the studio owner. Since that account may be accessed
 * from multiple users, get the current credits available for the studio owner before recording the new
 * charge. This is done on the Core Service.
 * 
 * 
 * 
 * @param {*} creditsRoom       {_id,accessType}
 * @param {*} accruedCredits    Array of artist partial credits for this Credit
 * @param {*} creditsUsed       Number of Credits charged ro creditsRoom or account
 * @param {*} account           User when Credits are charged to non-PUBLIC Studio
 * @param {*} artistUsage       If true the charge is for the Song artist usage
 * @param {*} accessToken 
 * @param {*} dispatch 
 * @returns currentCredits. If this is <1 the user is out of streaming credits
 */
const recordCreditUsage = async (creditsRoom, accruedCredits, creditsUsed, account, artistUsage, accessToken, dispatch) => {
    let currentCredits = 0
    const { _id: roomId, accessType } = creditsRoom
    if (accessType === ACCESS_TYPES.PUBLIC) {
        const credit = { action: CREDIT_TYPES.PUBLIC_AUDIO, roomId, accruedCredits, creditsUsed }
        const updatedCredit = await chargeCreditToStudio(credit)
        currentCredits = updatedCredit.availableCredits
    } else {
        const { availableCredits } = account
        const action = artistUsage ? CREDIT_TYPES.ARTIST_AUDIO : CREDIT_TYPES.AUDIO
        const credit = { action, roomId, accruedCredits, creditsUsed }
        await applyCredit(credit, accessToken)
        currentCredits = availableCredits - 1
        setAccount({ ...account, availableCredits: currentCredits })
    }
    dispatch(setCreditPlayTime(1))
    dispatch(setAccruedCredits([]))
    //dispatch(setAccruedCreditsRoom(currentRoom))
    return currentCredits
}