import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import {
  AccountBalanceWallet,
  AccountCircle,
  Beenhere,
  Category,
  Email,
  ExitToApp,
  Face,
  Help,
  HomeWork,
  Info,
  LiveHelp,
  Lock,
  LockOpen,
  MeetingRoom,
  MonetizationOn,
  People,
  ThumbUp,
  Work,
} from '@material-ui/icons'

import Button from 'components/CustomButtons/Button.js'

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js'
import { setAccessToken } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import { getAuth, signOut } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { setShowChat } from 'redux/actions/harmonizeActions'
import { setReactorPlay } from 'redux/actions/harmonizeActions'
import { isAdminUser } from 'util/adminUtils'

const useStyles = makeStyles(styles)

export default function HeaderLinks() {

  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()

  const providerId = account ? account.providerId : null
  const artist = useSelector((state) => state.artist)
  const { currentArtist } = artist

  const iconColor = 'white'
  const [startSignOut, setStartSignOut] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const iconStyle = classes.icons
  const dropdownLinks = [
    <Link to='/MyProfile' className={classes.navLink}>
      <Face className={iconStyle} /> My Profile
    </Link>,
    <Link to='/Studios' className={classes.navLink}>
      <MeetingRoom className={iconStyle} /> Studios
    </Link>,
    <Link to='/Subscribers' className={classes.navLink}>
      <People className={iconStyle} /> Subscribers
    </Link>,
    <Link to='/Vault' className={classes.navLink}>
      <AccountBalanceWallet className={iconStyle} /> Vault
    </Link>,

    <Button
      color='transparent'
      onClick={() => setStartSignOut(true)}
      className={classes.navLink}
    >
      <ExitToApp className={iconStyle} /> Sign Out
    </Button>
  ]
  if (isAdminUser()) {
    dropdownLinks.push(<Link to='/admin/ManageApprovals' className={classes.navLink}>
      <Beenhere className={iconStyle} /> Approvals
    </Link>)
  }


  const accountSignOut = async () => {
    console.log(`accountSignOut providerId ${providerId}`)

    history.replace('/')
    setAccessToken(null)
    setAccount(null)
    dispatch(setCurrentRoom(null))
    dispatch(setShowChat(false))
    dispatch(setReactorPlay(false))

    if (providerId) {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          console.log('Signed out provider')
        })
        .catch((error) => {
          console.error(`Unable to sign out provider ${providerId}`, error)
        })
    }
  }

  useEffect(() => {

    if (startSignOut) {
      accountSignOut()
    }
  }, [startSignOut])

  return (
    <List className={classes.list} >

      {!(accessToken || currentArtist) && (
        <ListItem className={classes.listItem}>
          <Link to='/SignIn' className={classes.navLink}>
            <LockOpen className={iconStyle} /> Sign In
          </Link>
        </ListItem>
      )}
      {accessToken && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText='Account'
            buttonProps={{
              className: classes.navLink,
              color: 'transparent',
            }}
            buttonIcon={AccountCircle}
            iconColor={iconColor}
            dropdownList={dropdownLinks}
          />
        </ListItem>
      )}

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='Help'
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Help}
          iconColor={iconColor}
          dropdownList={[
            <ListItem className={classes.listItem}>
              <Link to='/About' className={classes.navLink}>
                <Info className={iconStyle} /> About
              </Link>
            </ListItem>,
            <ListItem className={classes.listItem}>
              <Link to='/help/AboutStudios' className={classes.navLink}>
                <HomeWork className={iconStyle} /> Studios
              </Link>
            </ListItem>,
            <ListItem className={classes.listItem}>
              <Link to='/help/FAQ' className={classes.navLink}>
                <LiveHelp className={iconStyle} /> FAQ
              </Link>
            </ListItem>,
            <ListItem className={classes.listItem}>
              <Link to='/Pricing' className={classes.navLink}>
                <MonetizationOn className={iconStyle} /> Pricing
              </Link>
            </ListItem>,
            <ListItem className={classes.listItem}>
              <Link to='/help/Approvals' className={classes.navLink}>
                <Beenhere className={iconStyle} /> Approvals
              </Link>
            </ListItem>,
            <ListItem className={classes.listItem}>
              <Link to='/Contact' className={classes.navLink}>
                <Email className={iconStyle} /> Contact
              </Link>
            </ListItem>
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText='Policies'
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Category}
          iconColor={iconColor}
          dropdownList={[
            <Link to='/Credits' className={classes.navLink}>
              <ThumbUp className={iconStyle} /> Credits
            </Link>,

            <Link to='/Privacy' className={classes.navLink}>
              <Lock className={iconStyle} /> Privacy
            </Link>,
            <Link to='/TermsOfService' className={classes.navLink}>
              <Work className={iconStyle} /> Terms of Service
            </Link>,

          ]}
        />
      </ListItem>

    </List>
  )
}

