import React from 'react'
import {
    useStripe,
    useElements,
    PaymentElement,
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-kit-react/views/landingPage.js'
import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

export default function CheckoutForm({ cancel }) {
    const [paymentInProgress, setPaymentInProgress] = useState(false)
    const [error, setError] = useState('')
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()
    const useStyles = makeStyles(styles)
    const classes = useStyles()

    const handleSubmit = async (e) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault()

        setPaymentInProgress(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${process.env.REACT_APP_PAYMENT_SUCCESS_PAGE}`,
            },
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. 
        if (error.type === "card_error" || error.type === "validation_error") {
            setError(error.message)
        } else {
            setError("An unexpected error occurred.")
        }

        setPaymentInProgress(false)
    }

    return (
        <form onSubmit={handleSubmit} >
            <ErrorLine error={error} />
            {paymentInProgress ? <TimerProgress label='Payment in progress...' /> : null}
            <PaymentElement />
            <div style={{ display: 'flex' }}>
                <Button
                    disabled={paymentInProgress}
                    variant='contained'
                    color='default'
                    className={classes.submit}
                    style={{
                        marginTop: '1em',
                        marginBottom: '1em',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex',
                    }}
                    onClick={() => cancel ? cancel() : history.goBack()}
                >
                    Cancel
                </Button>
                <Button
                    disabled={paymentInProgress}
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    style={{
                        marginTop: '1em',
                        marginBottom: '1em',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex',
                    }}
                    onClick={handleSubmit}
                >
                    Buy Now
                </Button>
            </div>
        </form>
    )
}
