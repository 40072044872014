import * as REDUX_TYPES from '../types'

export const setAccruedCredits = (accruedCredits) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS,
    accruedCredits
  }
}
export const setAccruedCreditsRoom = (accruedCreditsRoom) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ACCRUED_CREDITS_ROOM,
    accruedCreditsRoom
  }
}
export const setContinuousPlay = (continuousPlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_CONTINUOUS_PLAY,
    continuousPlay,
  }
}

export const setCollections = (collections) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_COLLECTIONS,
    collections,
  }
}

export const setCreditPlayTime = (creditPlayTime) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_CREDIT_PLAY_TIME,
    creditPlayTime,
  }
}

export const setCurrentCollection = (currentCollection) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_CURRENT_COLLECTION,
    currentCollection,
  }
}
export const setEngaged = (engaged) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_ENGAGED,
    engaged,
  }
}

export const setForceSignIn = (forceSignIn) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_FORCE_SIGN_IN,
    forceSignIn,
  }
}
export const setFullscreen = (fullscreen) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_FULLSCREEN,
    fullscreen,
  }
}

export const setHideOverlay = (hideOverlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_HIDE_OVERLAY,
    hideOverlay,
  }
}

export const setLastPreviewWarning = (lastPreviewWarning) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_LAST_PREVIEW_WARNING,
    lastPreviewWarning,
  }
}

export const setPlayCurrentSong = (playCurrentSong) => {
  return {
    type: REDUX_TYPES.HARMONIZE.PLAY_CURRENT_SONG,
    playCurrentSong,
  }
}
export const setPlayList = (playList) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYLIST,
    playList,
  }
}

export const setPlayListIndex = (playListIndex) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYLIST_INDEX,
    playListIndex,
  }
}

export const setPlayerMode = (playerMode) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYER_MODE,
    playerMode,
  }
}

export const setPlayerState = (playerState) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAYER_STATE,
    playerState,
  }
}

export const setPlayTime = (playTime) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_PLAY_TIME,
    playTime,
  }
}

export const setPostBuyCreditsPage = (postBuyCreditsPage) => {
  return {
    type: REDUX_TYPES.HARMONIZE.POST_BUY_CREDITS_PAGE,
    postBuyCreditsPage,
  }
}


export const setPostSignInPage = (postSignInPage) => {
  return {
    type: REDUX_TYPES.HARMONIZE.POST_SIGN_IN_PAGE,
    postSignInPage,
  }
}

export const setReactorPlay = (reactorPlay) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_REACTOR_PLAY,
    reactorPlay,
  }
}

export const setShowAi = (showAi) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_AI,
    showAi,
  }
}

export const setShowAttachments = (showAttachments) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_ATTACHMENTS,
    showAttachments,
  }
}

export const setShowChat = (showChat) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_CHAT,
    showChat,
  }
}

export const setShowCollections = (showCollections) => {
  return {
    type: REDUX_TYPES.HARMONIZE.SET_SHOW_COLLECTIONS,
    showCollections,
  }
}


