module.exports = {
  ACCOUNT: {
    ARTIST: 'accountartist',
    SET: 'accountset',
  },
  ARTIST: {
    CURRENT_ARTIST: 'artistcurrentartist',
  },
  ASSET: {
    CURRENT_ALBUM: 'assetcurrentalbum',
    CURRENT_ASSET: 'assetcurrentasset',
    CURRENT_ASSETS: 'assetcurrentassets',
    CURRENT_ISSUE: 'assetcurrentissue',
    SET: 'assetsset',
  },
  CHAT: {
    ADD: 'chatadd',
    SET: 'chatset',
    SET_CURRENT_AVAILABLE_THOUGHTS: 'chatsetcurrentavailablethoughts',
    SET_CURRENT_COLLABORATOR: 'chatsetcurrentcollaborator',
    SET_ROOM_ID: 'chatroomid',
    SET_SESSION_ID: 'chatsetsessionid',
    SET_WAITING_FOR_HOST: 'chatwaitingforhost'
  },
  DISPLAY: {
    FULLSCREEN_PLAYER: 'displayfullscreenplayer',
    SHOW_HEADER: 'displayshowheader',
    TRUEMUSIC: 'displaytruemusic',
    TRUEPLACE: 'displaytrueplace',
    TRUETRAIL: 'displaytruetrail',
    TRANSACTION_IN_PROGRESS: 'displaytransactioninprogress',
    VORTEX: 'displayvortex',
  },
  EMAIL: {
    ALBUM_NAME: 'emailalbumname',
    ASSET_ID: 'emailassetid',
  },
  HELP: {
    SHOW_AI_SIGN_IN: 'helpsetshowaisignin',
    SHOW_AI_WELCOME: 'helpshowaiwelcome',
    SHOW_PROFILE_HELP: 'helpshowprofilehelp',
    SHOW_WELCOME: 'helpshowwelcome',
  },
  HARMONIZE: {
    PLAY_CURRENT_SONG: 'harmonizeplaycurrentsong',
    POST_BUY_CREDITS_PAGE: 'harmonizepostbuycreditspage',
    POST_SIGN_IN_PAGE: 'harmonizepostsigninpage',
    SET_ACCRUED_CREDITS: 'harmonizesetaccruedcredits',
    SET_ACCRUED_CREDITS_ROOM: 'harmonizesetaccruedcreditsroom',
    SET_CONTINUOUS_PLAY: 'harmonizesetautoplay',
    SET_COLLECTIONS: 'harmonizesetcollections',
    SET_CREDIT_PLAY_TIME: 'harmonizesetcreditplaytime',
    SET_CURRENT_COLLECTION: 'harmonizesetcurrentcollection',
    SET_ENGAGED: 'harmonizesetengaged',
    SET_FORCE_SIGN_IN: 'harmonizeforcesignin',
    SET_FULLSCREEN: 'harmonizesetfullscreen',
    SET_HIDE_OVERLAY: 'harmonizesethideoverlay',
    SET_LAST_PREVIEW_WARNING: 'harmonizesetlastpreviewwarning',
    SET_PLAYLIST: 'harmonizesetplaylist',
    SET_PLAYLIST_INDEX: 'harmonizesetplaylistindex',
    SET_PLAYER_MODE: 'harmonizesetplayermode',
    SET_PLAYER_STATE: 'harmonizesetplayerstate',
    SET_PLAY_TIME: 'harmonizesetplaytime',
    SET_REACTOR_PLAY: 'harmonizesetreactorplay',
    SET_SHOW_AI: 'harmonizesetshowai',
    SET_SHOW_ATTACHMENTS: 'harmonizeshowattachments',
    SET_SHOW_CHAT: 'harmonizesetshowchat',
    SET_SHOW_COLLECTIONS: 'harmonizeshowcollections'
  },
  MESSAGES: {
    ADD: 'messagesadd',
    ADD_COMMENT: 'messagesaddcomment',
    DELETE: 'messagesdelete',
    DELETE_COMMENT: 'messagesdeletecomment',
    GET: 'messagesget',
    SET: 'messagesset',
    SET_CHANNEL: 'messagessetchannel',
    SET_ROOM: 'messagessetroom',
    SET_ROOM_OWNER: 'messagessetroomowber',
    SET_SOCKET: 'messagessetsocket',
    UPDATE: 'messagesupdate',
  },
  PURCHASE: {
    SET_TRANSACTION_TYPE: 'purchasesettransactiontype',
  },
  SETTINGS: {
    CURRENCY: 'settingscurrency',
    MAP_CENTER: 'settingsmapcenter',
    MAP_TYPE_ID: 'settingsmaptypeid',
    MAP_ZOOM: 'settingszoom',
    MAINMAP: {
      CENTER: 'settingsmainmapcenter',
      TYPE_ID: 'settingsmainmaptypeid',
      ZOOM: 'settingsmainmapzoom',
    },
    SET: 'settingsset',
    SET_MAINMAP: 'settingsmainmap'
  },
  SONG: {
    CREATED_AT: 'songcreatedat',
    DESCRIPTION: 'songdescription',
    GENRE: 'songgenre',
    IMAGE: 'songimage',
    LYRICS: 'songlyrics',
    MEDIA: 'songmedia',
    MEDIA_ICONS: 'songmediaicons',
    MP3: 'songmp3',
    RESET: 'songreset',
    SUMMARY: 'songsummary',
    TITLE: 'songtitle',
    UPDATED_AT: 'songupdatedat'
  },
  SHORT: {
    CAPTION: 'shortcaption',
    CLIP_END: 'shortclipend',
    CLIP_START: 'shortclipstart',
    DESCRIPTION: 'shortdescription',
    IMAGE: 'shortimage',
    MEDIA_ICONS: 'shortmediaicons',
    MESSAGE_ID: 'shortmessageid',
    RESET: 'shortreset',
    ROOM_ID: 'shortroomid',
    SUMMARY: 'shortsummary'
  }
}
