import React from 'react'
import Footer from 'components/Footer/Footer.js'
import { ListRevenue } from 'components/transaction/ListRevenue'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { ListTransactions } from 'components/transaction/ListTransactions'
import { useEffect } from 'react'
import { useState } from 'react'
import ChartSong from 'components/transaction/ChartSong'
import SongListeners from 'components/transaction/SongListeners'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'

const VAULT_STATES = {
  REVENUE: 0,
  TRANSACTIONS: 1,
  SONG_CHART: 2,
  SONG_LISTENERS: 3
}

export default function Vault() {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [vaultState, setVaultState] = useState(VAULT_STATES.REVENUE)
  const [songId, setSongId] = useState()
  const [songTitle, setSongTitle] = useState()
  const [artistUsage, setArtistUsage] = useState(false)
  const vaultStyles = {
    backForward: { fontSize: veryNarrow ? '1.5em' : '2em', fontWeight: 'bold' },
    container: {
      position: 'absolute', top: '0', right: '0', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'right', fontSize: '0.75em',
      fontStyle: 'italic', color: 'blue'
    },
    title: { textAlign: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }
  }

  const displayBack = (label, state, goBack) => {
    return (
      <div style={vaultStyles.container}
        onClick={() => setVaultState(state)}>

        {goBack ? <span style={{ display: 'flex', alignItems: 'center' }}><ArrowBack style={vaultStyles.backForward} />{label}</span>
          : <span style={{ display: 'flex', alignItems: 'center' }}><ArrowForward style={vaultStyles.backForward} />{label}</span>}

      </div>
    )
  }
  const displaySongChart = () => {
    return (
      <div>
        <h4 style={vaultStyles.title}>{songTitle}<br />Credits
          {displayBack('revenue', VAULT_STATES.REVENUE, true)}
        </h4>
        <ChartSong messageId={songId} artistUsage={artistUsage} />
      </div>
    )
  }


  const displaySongListeners = () => {
    return (
      <div>
        <h4 style={vaultStyles.title}>{songTitle}<br />Listeners
          {displayBack('revenue', VAULT_STATES.REVENUE, true)}
        </h4>
        <SongListeners messageId={songId} />
      </div>
    )
  }

  const displayRevenue = () => {
    return (
      <div>
        <h4 style={vaultStyles.title}>Revenue
          {displayBack('Transactions', VAULT_STATES.TRANSACTIONS)}
        </h4>

        <ListRevenue chartSong={(messageId, title, artistUsage) => {
          setVaultState(VAULT_STATES.SONG_CHART)
          setSongId(messageId)
          setSongTitle(title)
          setArtistUsage(artistUsage)
        }}
          listeners={(messageId, title) => {
            setVaultState(VAULT_STATES.SONG_LISTENERS)
            setSongId(messageId)
            setSongTitle(title)
          }}

        />
      </div>
    )
  }
  const displayTransactions = () => {

    return (
      <div >
        <h4 style={vaultStyles.title}>Transactions
          {displayBack('Revenue', VAULT_STATES.REVENUE)}
        </h4>
        <ListTransactions />
      </div>
    )
  }


  const displayComponent = () => {
    switch (vaultState) {

      case VAULT_STATES.REVENUE:
        return displayRevenue()
      case VAULT_STATES.TRANSACTIONS:
        return displayTransactions()
      case VAULT_STATES.SONG_CHART:
        return displaySongChart()
      case VAULT_STATES.SONG_LISTENERS:
        return displaySongListeners()
    }
  }
  useEffect(() => {
    document.title = 'Vault'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ margin: '0.5em', paddingBottom: '2em' }}>
        {displayComponent()}
      </div>
      <Footer />
    </div>
  )
}
