import React, { useState } from 'react'
import Footer from 'components/Footer/Footer.js'

import { getStorageAccessToken } from 'redux/actions/accountActions'
import { setCurrentRoom } from 'redux/actions/messagesActions'
import { useDispatch, useSelector } from 'react-redux'
import StandardSwitch from 'components/standard/StandardSwitch'
import { updateRoom } from 'controllers/VortexController'
import ErrorLine from 'components/ErrorLine'
import { useEffect } from 'react'
import { HelpOutlineTwoTone } from '@material-ui/icons'
import Explain from 'components/Explain'
import { ACCESS_TYPES } from './CreateVortexRoom'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, useMediaQuery } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.js'
import { isNarrow } from 'util/screenUtils'
import { updateAsset } from 'controllers/AssetController'
import { setCurrentAsset } from 'redux/actions/assetActions'
import { displayError } from 'util/screenUtils'
import { getStorageAccount } from 'redux/actions/accountActions'
import { updateUser } from 'controllers/AccountController'
import { setAccount } from 'redux/actions/accountActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'

export const EditRoom = () => {
  const narrow = isNarrow(useMediaQuery)
  const padding = narrow ? '0.5em' : '2em'
  const [error, setError] = useState('')
  const accessToken = getStorageAccessToken()
  const account = getStorageAccount()
  const { handle } = account
  const { currentRoom } = useSelector(state => state.messages)
  const { currentAsset } = useSelector(state => state.asset)
  const { caption } = currentAsset
  const { exclusive, name, friendlyName, accessType: currentAccessType, ownerPaysThoughts: roomOwnerPaysThoughts } = currentRoom
  const [description, setDescription] = useState(caption)
  const [helpTarget, setHelpTarget] = useState()
  const [explain, setExplain] = useState()
  const dispatch = useDispatch()

  const explainEdit = {
    title: `Edit ${friendlyName}`,
    description: `
      <p><b>Access Type</b>: A Private room can only be used by you. A Protected room can be used by any registered Harmonize user. A Public room can be
      read by anyone including anonymous unregistered users, but posting or commenting requires signin.</p>
      <p><b>Exclusive or Open</b>: This only applies to Protected rooms. If you choose an Exclusive room, only you will be able to post new content.
      Comments on your posts will be allowed. This kind of room is best for personal rooms in which you are highlighting your work to the exclusion of other posts.
      If you choose an Open room, registered users will be able to post new content. This is the
      best setting for topic-oriented rooms. As the room Moderator, you always have the ability to delete any post or comment in the room.</p>
      <p><b>Default Studio</b>: If this switch is on, then this room is shown to any user who accesses Harmonize through your handle (${process.env.REACT_APP_HARMONIZE_HOST}/@${handle}).
      If there is no default room, users are instead directed to your Intelligence Center and shown a list of your rooms.</p>

      `,
  }

  const updateDescription = async () => {
    const { asset } = currentRoom
    const assetToUpdate = { _id: asset, caption: description }
    try {
      const updatedAsset = await updateAsset(assetToUpdate, accessToken)
      dispatch(setCurrentAsset(updatedAsset))
    } catch (error) {
      console.error(`Unable to updateDescription ${friendlyName}`, error)
      displayError(error, setError)
    }

  }
  const updateExclusive = async (isExclusive) => {
    const roomToUpdate = { ...currentRoom, exclusive: isExclusive }
    try {
      const updatedRoom = await updateRoom(roomToUpdate, accessToken)
      dispatch(setCurrentRoom(updatedRoom))
    } catch (error) {
      console.error(`Unable to updateRoom ${friendlyName}`, error)
      displayError(error, setError)
    }

  }

  const updateAccessType = async (newAccessType) => {
    console.log(`updateAccessType`, newAccessType)
    const roomToUpdate = { ...currentRoom, accessType: newAccessType }
    try {
      const updatedRoom = await updateRoom(roomToUpdate, accessToken)
      dispatch(setCurrentRoom(updatedRoom))
    } catch (error) {
      console.error(`Unable to updateRoom ${friendlyName}`, error)
      displayError(error, setError)
    }
  }


  const displayAccessType = () => {

    return (
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Studio Access</FormLabel>
          <RadioGroup row aria-label="gender" name="gender1" value={currentAccessType} onChange={async (evt) => await updateAccessType(evt.target.value)}>
            <FormControlLabel value={ACCESS_TYPES.PRIVATE} control={<Radio />} label="Private" />
            <FormControlLabel value={ACCESS_TYPES.PROTECTED} control={<Radio />} label="Protected" />
            <FormControlLabel value={ACCESS_TYPES.PUBLIC} control={<Radio />} label="Public" />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  const displayDescription = () => {

    return (
      <div>
        Studio Description
        <TextField
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          fullWidth
          multiline
          label={`Maximum ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
        />
        <Button
          disabled={description === currentRoom.caption}
          color="success"
          onClick={updateDescription}>
          Update Description
        </Button>
      </div>
    )
  }

  const displayExclusiveSwitch = () => {
    if (currentAccessType === ACCESS_TYPES.PROTECTED)
      return (
        <div>
          {exclusive ? 'Exclusive Content' : 'Open Content'}
          <StandardSwitch
            isChecked={exclusive ? true : false}
            onChange={async () => await updateExclusive(!exclusive)}
          />
        </div>
      )
  }

  const userUpdate = async (defaultRoom) => {
    try {
      const defaultRoomName = defaultRoom ? name : ''
      const updatedUser = await updateUser({ ...account, defaultRoomName }, accessToken)
      console.log('Received updated user', updatedUser)
      const updatedAccount = { ...updatedUser }
      setAccount(updatedAccount)
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }

  const displayDefaultRoomSwitch = () => {
    const { defaultRoomName } = account
    const defaultRoom = name === defaultRoomName
    return (
      <div>
        {defaultRoom ? 'Default Studio' : 'Not default studio'}
        <StandardSwitch
          isChecked={defaultRoom ? true : false}
          onChange={async () => await userUpdate(!defaultRoom)}
        />
      </div>
    )
  }

  const displayEditRoom = () => {

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '90vw' }}>
          {/*
          {displayAccessType()}
          {displayExclusiveSwitch()}
          {displayDefaultRoomSwitch()}
    */}
          {displayDescription()}
        </div>
      </div>
    )
  }
  useEffect(() => {
    console.log('EditRoom useEffect', currentRoom)
  }, [currentRoom])

  return (

    <div style={{ backgroundColor: 'white' }}>
      <HarmonizeHeader />
      <div style={{ backgroundColor: 'white', height: '85vh', paddingLeft: padding, paddingRight: padding }}>

        <h4 style={{ textAlign: 'center' }}>
          <HelpOutlineTwoTone
            color='primary'
            style={{
              height: '100%',
              marginRight: '0.25em',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
            onClick={(event) => {
              setHelpTarget(event.currentTarget)
              setExplain(explainEdit)
            }}
          />Edit {friendlyName}</h4>
        <ErrorLine error={error} />
        {displayEditRoom()}
        {explain && (
          <Explain
            source={explain}
            target={helpTarget}
            close={() => setExplain(null)}
          />
        )}


      </div>
      <Footer />
    </div>
  )
}
