import { useMediaQuery } from '@material-ui/core'
import { CheckBoxOutlineBlank } from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine'
import { getCreditPricing } from 'controllers/HarmonizeController'
import { useState } from 'react'
import { useEffect } from 'react'
import { isVeryNarrow } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'
import MessageJsx from './MessageJsx'

export default function PriceList({ clickToPurchase, setPriceList }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const pricingStyles = {
        pricingBlock: {
            border: '1px solid blue', width: veryNarrow ? '80%' : '50%', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center',
            padding: '0.5em'
        },
        pricingHeader: { display: 'flex', flex: 1, alignItems: 'center', borderBottom: '0.5px solid gray' },
        pricingLine: { display: 'flex', flex: 1, alignItems: 'center' },
        pricingLinePurchase: { display: 'flex', flex: 1, alignItems: 'center', cursor: 'pointer' }
    }
    const [error, setError] = useState()
    const [pricing, setPricing] = useState()
    const getDisplayedPrice = (price) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(price / 100)
    }
    const displayPricingHeader = () => {
        return (
            <div style={pricingStyles.pricingHeader}>
                <span style={{ flex: 0.3 }}>Package</span>
                <span style={{ flex: 0.2 }}>Credits</span>
                <span style={{ flex: 0.5 }}>Price (USD)</span>

            </div>
        )
    }
    const displayPricingLine = (p, ix) => {
        const { price, numCredits, planName } = p
        if (clickToPurchase) {
            return (
                <div key={ix} title={`Click to purchase ${planName} package`}
                    style={pricingStyles.pricingLinePurchase} onClick={() => clickToPurchase(numCredits)}>
                    <span style={{ flex: 0.3 }}>{planName}</span>
                    <span style={{ flex: 0.2 }}>{numCredits}</span>
                    <div
                        style={{ display: 'flex', flex: 0.5, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <CheckBoxOutlineBlank />{getDisplayedPrice(price)}</div>

                </div>
            )
        } else {
            return (
                <div key={ix} style={pricingStyles.pricingLine} >
                    <span style={{ flex: 0.3 }}>{planName}</span>
                    <span style={{ flex: 0.2 }}>{numCredits}</span>
                    <div style={{ display: 'flex', flex: 0.5, alignItems: 'center' }}>
                        {getDisplayedPrice(price)}</div>

                </div>)
        }
    }
    const displayPricing = () => {
        if (pricing) {
            return (
                <>
                    <div >
                        <ErrorLine error={error} />
                        <div style={pricingStyles.pricingBlock}>
                            {displayPricingHeader()}
                            {pricing.map((p, ix) => displayPricingLine(p, ix))}
                        </div>
                        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <MessageJsx messageId='675094444b312347d64a7712' />
                        </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        getCreditPricing().then((result) => {
            setPricing(result.pricing)
            if (setPriceList) { setPriceList(result.pricing) }
        }).catch(error => displayError(error, setError))
    }, [])
    return displayPricing()
}