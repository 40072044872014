import { DeleteForeverTwoTone, Lock, LockOpen } from '@material-ui/icons'
import { useState } from 'react'
import ErrorLine from 'components/ErrorLine'
import ModalDialog from 'components/ModalDialog'
import { useEffect } from 'react'
import { logWithTime } from 'util/screenUtils'
import { useRef } from 'react'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import moment from 'moment'
import { displayError } from 'util/screenUtils'
import { getStorageAccount } from 'redux/actions/accountActions'
import { ACCESS_TYPES } from './CreateVortexRoom'
import { useDispatch, useSelector } from 'react-redux'
import { setAccessToken } from 'redux/actions/accountActions'
import { useHistory } from 'react-router-dom'
import { getSessionsForRoom, getSessionsForUser } from 'controllers/AIController'
import { deleteSession } from 'controllers/AIController'
import { getAccountId } from 'redux/actions/accountActions'
const sessionStyles = {
    sessionLine: {
        borderBottom: '0.5px dashed gray',
        cursor: 'pointer'

    }
}
export default function DisplaySessions({ roomId, handleSession, sessionDeleted, sessionsHeight }) {
    const accessToken = getStorageAccessToken()
    const userId = getAccountId()
    const { chatSessionId } = useSelector(state => state.chat)
    const [sessions, setSessions] = useState([])
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [sessionToDelete, setSessionToDelete] = useState()
    const [error, setError] = useState()
    const dispatch = useDispatch()
    const history = useHistory()
    const sessionsRef = useRef()
    const displaySessionLine = (sessionEntry, ix) => {
        const { _id } = sessionEntry
        return (
            <div key={ix} style={sessionStyles.sessionLine} title='Click to access this session'
                onClick={(evt) => {
                    evt.stopPropagation()
                    handleSession(_id)
                }}>
                {formatSessionLine(sessionEntry, ix)}
            </div>
        )
    }

    const displaySessions = () => {
        return (
            <div ref={sessionsRef}
                style={{
                    border: '1px solid black', overflowY: 'scroll', paddingLeft: '1em',
                    paddingRight: '1em',
                    height: sessionsHeight
                }}>
                {sessions.map((session, ix) => displaySessionLine(session, ix))}
            </div>
        )
    }

    const formatSessionLine = (sessionEntry, ix) => {
        console.log(`sessionEntry ${ix}`, sessionEntry)
        const { _id, createdAt, thoughts, room, userId: sessionUserId, accessType } = sessionEntry
        const roomName = room ? room.name : null
        const { query } = thoughts.length ? thoughts[0]
            : { query: _id === chatSessionId ? 'Current Session' : 'Empty Session' }
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span >{moment(createdAt).format('MM/DD/YYYY, h:mm a')} <b>{roomName}</b></span>
                    <div style={{ display: 'flex' }}>
                        {sessionUserId === userId ? <DeleteForeverTwoTone
                            color='error'

                            style={{ cursor: 'pointer' }}
                            onClick={async (evt) => {
                                evt.stopPropagation()
                                setSessionToDelete(sessionEntry)
                                setConfirmDelete(true)
                            }}
                        /> : null}
                        {/*getAccessType(accessType, user, async (updatedAccessType) => await updateSessionEntry(sessionEntry, updatedAccessType, ix))*/}
                    </div>
                </div>
                Q: {query}
            </div>
        )
    }

    const getAccessType = (accessType, user, updateAccess) => {
        if (user === userId) {
            switch (accessType) {
                case ACCESS_TYPES.PRIVATE:
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={(evt) => {
                            evt.stopPropagation()
                            updateAccess(ACCESS_TYPES.PROTECTED)
                        }} title='Click to allow access to this Session' >
                            <Lock />
                        </div>
                    )
                default:
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={(evt) => {
                            evt.stopPropagation()
                            updateAccess(ACCESS_TYPES.PRIVATE)
                        }} title='Click to prevent access to this Session' >
                            <LockOpen />
                        </div>
                    )
            }
        } else {
            return null
        }

    }

    /*
     * The server update does *not* return the Thoughts for the updated Session.
     * @param {*} sessionEntry 
     * @param {*} updatedAccessType 
     * @param {*} ix 
     *
    const updateSessionEntry = async (sessionEntry, updatedAccessType, ix) => {
        const { _id, thoughts } = sessionEntry
        const updatedSession = await updateSession({ _id, accessType: updatedAccessType }, accessToken)
        updatedSession.thoughts = thoughts
        const updatedSessions = [...sessions]
        updatedSessions.splice(ix, 1, updatedSession)
        setSessions(updatedSessions)
    }
    */

    const scrollToBottom = (targetRef) => {
        console.log(`scrollToBottom`, targetRef)
        if (targetRef.current) {
            const scroll = targetRef.current.scrollHeight -
                targetRef.current.clientHeight
            targetRef.current.scrollTo(0, scroll)
        }
    }

    const selectNextOrDeleted = async () => {
        const sess = roomId ? await getSessionsForRoom(roomId, 0, 50, accessToken)
            : await getSessionsForUser(0, 50, accessToken)
        logWithTime('selecteNextOrDeleted got Sessions', sess)
        sessionDeleted(sess)

    }
    /**
 * 
 * @param {*} checkForSessions If true set the display state depending on presence of Sessions
 */
    const initSessions = async () => {
        try {
            const sess = roomId ? await getSessionsForRoom(roomId, 0, 50, accessToken)
                : await getSessionsForUser(0, 50, accessToken)
            logWithTime('DisplaySessions got Sessions', sess)
            setSessions(sess)
            scrollToBottom(sessionsRef)
        } catch (error) {
            console.error(error)
            const { status } = error
            if (status === 401) {
                setAccessToken(null)
                history.push('/SignIn')
            } else {
                displayError(error, setError)
            }
        }
    }

    useEffect(() => {
        initSessions()
    }, [])

    return (
        <div>
            <ErrorLine error={error} />
            {displaySessions()}
            <ModalDialog
                title='Confirm Delete Session'
                message={`Are you sure you want to permanently delete this session?`}
                close={() => setConfirmDelete(false)}
                open={confirmDelete}
                noLabel='No'
                no={() => setConfirmDelete(false)}
                yesLabel='Yes'
                yes={async () => {
                    setConfirmDelete(false)
                    try {
                        setError('')
                        const { _id } = sessionToDelete
                        setSessionToDelete(null)
                        await deleteSession(_id, accessToken)
                        if (sessionDeleted) {
                            await selectNextOrDeleted()
                        } else {
                            await initSessions(true)
                        }

                    } catch (error) {
                        displayError(error, setError)
                    }
                }}
            />
        </div>
    )
}