import { logWithTime } from './screenUtils'


/**
 * 
 * @param {*} messageId 
 * @param {*} comment 
 * @param {*} userId 
 * @param {*} handle 
 * @param {*} socket 
 */
export const addComment = (messageId, comment, userId, handle, socket) => {
    console.log(`addComment ${messageId}, ${comment}, ${userId}, ${handle},`)
    socket.emit('addComment', { messageId, comment, userId, handle })
}

/**
 * Info about signed in user is required to confirm delete capability at Social Service.
 * @param {*} commentId 
 * @param {*} requestUserId 
 * @param {*} accessToken 
 * @param {*} socket 
 */
export const deleteComment = (commentId, requestUserId, accessToken, socket) => {
    socket.emit('deleteComment', { commentId, requestUserId, accessToken })
}

/**
 * 
 * @param {*} messageId 
 * @param {*} userId 
 * @param {*} socket 
 */
export const addLike = (messageId, userId, socket) => {
    logWithTime(`...addLike ${messageId} user ${userId}`)
    socket.emit('addLike', { messageId, userId })
}

/**
 * 
 * @param {*} messageId 
 * @param {*} requestUserId 
 * @param {*} socket 
 */
export const deleteLike = (messageId, requestUserId, socket) => {
    logWithTime(`...deleteLike ${messageId}`)
    socket.emit('deleteLike', { messageId, requestUserId })
}