import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import stripe from 'images/stripe.png'
import {
    Elements,
} from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { getPaymentIntent } from 'controllers/StripeController'

import { getStorageAccessToken } from 'redux/actions/accountActions'
import CheckoutForm from './CheckoutForm'
import Footer from 'components/Footer/Footer'
import { getStorageAccount } from 'redux/actions/accountActions'
import ErrorLine from 'components/ErrorLine'
import { TRANSACTION_TYPE } from 'util/transactionUtils'
import { useDispatch } from 'react-redux'
import { setTransactionType } from 'redux/actions/purchaseActions'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { displayError } from 'util/screenUtils'
import { Link } from 'react-router-dom'
import PriceList from 'components/harmonize/PriceList'
import { getAccountId } from 'redux/actions/accountActions'

/* 
   NOTE: Be sure to use the correct "Publishable key" from developer "API Keys" for test versus production
   
   Make sure to call `loadStripe` outside of a component’s render to avoid
   recreating the `Stripe` object on every render.
*/
const stripePromise = loadStripe(process.env.REACT_APP_TEST_PAYMENTS === 'true' ? process.env.REACT_APP_TEST_STRIPE_KEY : process.env.REACT_APP_STRIPE_KEY)

export default function BuyCredits() {

    const [clientSecret, setClientSecret] = useState()
    const [showOrderForm, setShowOrderForm] = useState(false)

    const [error, setError] = useState()
    const [pricing, setPricing] = useState()
    const [numCreditsPurchased, setNumCreditsPurchased] = useState(0)

    const accessToken = getStorageAccessToken()
    const userId = getAccountId()
    const dispatch = useDispatch()
    const getDisplayedPrice = (price) => {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(price / 100)
    }
    const displayOrderForm = () => {
        if (showOrderForm) {
            const currentPricing = pricing.find(p => p.numCredits === numCreditsPurchased)
            const { numCredits, price, planName } = currentPricing
            return (
                <div>
                    <div style={{ borderBottom: '0.5px solid black' }}>
                        Buy the {planName} package ({numCredits}) credits for only {getDisplayedPrice(price)}
                    </div>
                    <Elements stripe={stripePromise} options={{ clientSecret }} >
                        <CheckoutForm cancel={() => setShowOrderForm(false)} />
                    </Elements>
                    <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: '80%', justifyContent: 'center' }}>
                        <img alt='Stripe' src={stripe} style={{ height: '5vh' }} />
                    </div>
                </div>
            )
        }
    }

    const displayPricing = () => {
        if (!showOrderForm) {
            return (
                <>
                    <h3>Buy Harmonize Credits</h3>
                    Click to select your package of credits. Each credit is good for 1 minute of streaming audio.< p > Find out more about credits < Link to='/Pricing' > here</Link >.</p >

                    <PriceList clickToPurchase={(numCredits) => setNumCreditsPurchased(numCredits)}
                        setPriceList={(p) => setPricing(p)} />

                </>
            )
        }
    }

    const displayMain = () => {
        return (
            <div style={{ textAlign: 'center' }}>

                {displayPricing()}
                <br />
                {displayOrderForm()}
            </div>
        )
    }

    useEffect(() => {
        document.title = 'Buy Credits'
    }, [])
    useEffect(() => {
        if (numCreditsPurchased) {
            console.log(`BuyCredits useEffect ${userId}`)
            getPaymentIntent({ transactionType: TRANSACTION_TYPE.CREDIT, userId, numCreditsPurchased }, accessToken).then((secret) => {
                setClientSecret(secret.clientSecret)
                setShowOrderForm(true)
                dispatch(setTransactionType(TRANSACTION_TYPE.CREDIT))
            }).catch(error => displayError(error, setError))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numCreditsPurchased])

    return (
        <div>
            <HarmonizeHeader />
            <div style={{ paddingLeft: '1vw', paddingRight: '1vw', backgroundColor: 'white', width: '100%' }}
            >
                <ErrorLine error={error} />
                {displayMain()}
            </div>
            <Footer />
        </div >
    )

}
