import React from 'react'
import Button from 'components/CustomButtons/Button.js'
import { useState } from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Delete, DeleteForeverTwoTone } from '@material-ui/icons'
import ErrorLine from 'components/ErrorLine.js'
import { useSelector } from 'react-redux'
import { getStorageAccessToken } from 'redux/actions/accountActions.js'

import { displayError } from 'util/screenUtils.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader.js'
import Footer from 'components/Footer/Footer.js'
import { useHistory, useParams } from 'react-router-dom'
import ModalDialog from 'components/ModalDialog'
import { addCollection } from 'controllers/HarmonizeController'
import { updateCollection } from 'controllers/HarmonizeController'
import { useEffect } from 'react'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getCollectionById } from 'controllers/HarmonizeController'
import ListDND from 'components/dnd/ListDND'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { getMediaThumbnail } from 'util/imageUtils'
import { deleteCollectionById } from 'controllers/HarmonizeController'
import { ACCESS_TYPES } from 'components/vortex/CreateVortexRoom'
import { APPROVAL_STATUS } from 'util/postUtils'
import { addArtistStudios } from 'controllers/HarmonizeController'
import { PLAYER_MODES } from 'redux/reducers/harmonizeReducer'
import { getAccountId } from 'redux/actions/accountActions'

export default function CreateCollection() {
  const { collectionId, messageId } = useParams()
  const { currentRoom } = useSelector(state => state.messages)
  const isEditing = collectionId !== undefined
  const userId = getAccountId()
  const [error, setError] = useState()
  const [studios, setStudios] = useState([])
  const [collectionName, setCollectionName] = useState('')
  const [collectionMessages, setCollectionMessages] = useState()
  const [collectionCover, setCollectionCover] = useState()
  const [collectionPlayerMode, setCollectionPlayerMode] = useState(PLAYER_MODES.NONE)
  const [description, setDescription] = useState('')
  const [editCollection, setEditCollection] = useState()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [imageSrc, setImageSrc] = useState()

  const accessToken = getStorageAccessToken()

  const history = useHistory()

  const saveOrUpdate = async () => {
    try {
      if (isEditing) {
        const messages = []
        collectionMessages.forEach(msg =>
          messages.push({ messageId: msg._id }))
        const updatedEditCollection = {
          ...editCollection, name: collectionName, description, messages,
          media: collectionCover,
          playerMode: collectionPlayerMode
        }
        console.log(`updatedEditCollection`, updatedEditCollection)
        await updateCollection(updatedEditCollection, accessToken)
      } else {
        const { _id: roomId } = currentRoom
        await addCollection(collectionName, description, messageId, roomId, accessToken)
        closePost()
      }
    } catch (error) {
      displayError(error, setError)
    }
  }

  const closePost = () => {
    setCollectionName('')
    setDescription('')
    setError('')
    history.goBack()
  }

  const isDisabled = () => {
    return collectionName.length === 0 || description.length === 0
  }

  const canPublish = () => {
    if (isEditing && editCollection && editCollection.accessType === ACCESS_TYPES.PRIVATE) {
      let publishable = true
      for (const msg of collectionMessages) {
        const { status } = msg
        if (status !== APPROVAL_STATUS.APPROVED) {
          publishable = false
          break
        }
      }
      return publishable
    }
    return false
  }

  /** Reassign to the first PROTECTED studio in the studios list */
  const publish = async () => {
    const homeStudio = studios.find(s => s.accessType === ACCESS_TYPES.PROTECTED)
    if (homeStudio) {
      const { _id: roomId } = homeStudio
      const messages = []
      collectionMessages.forEach(msg =>
        messages.push({ messageId: msg._id }))
      const updatedCollection = { ...editCollection, messages, roomId, accessType: ACCESS_TYPES.PROTECTED }
      console.log('updatedCollection', updatedCollection)
      try {
        await updateCollection(updatedCollection, accessToken)
        await getCollectionToEdit()
      } catch (error) {
        displayError(error, setError)
      }

    }
  }

  const displayForm = () => {
    const saveLabel = isEditing ? 'Update' : 'Save'
    return (
      <>
        {isEditing ? <img
          src={imageSrc}
          alt={collectionName}
          title={collectionName}
          style={{ width: '80vw' }}
        /> : null}
        <TextField
          value={collectionName}
          onChange={(event) => {
            setCollectionName(event.target.value)
          }}
          fullWidth
          label={`Title (max ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH})`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH }}
        />

        <TextField
          value={description}
          onChange={(event) => {
            setDescription(event.target.value)
          }}
          fullWidth
          multiline
          label={`Description (max ${process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH} characters)`}
          inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_SUMMARY_LENGTH }}
        />
        {displayPlayerMode()}
        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Button
            color="primary"
            title='Go back'
            style={{ marginRight: '2em', cursor: 'pointer' }}
            onClick={() => closePost()}>
            Close
          </Button>
          <Button disabled={isDisabled()}
            color="success"
            title={saveLabel}
            style={{ marginRight: '2em', cursor: 'pointer' }}
            onClick={async () => await saveOrUpdate()}>
            {saveLabel}
          </Button>
          {canPublish() ? <Button disabled={isDisabled()}
            color="success"
            title={saveLabel}
            style={{ marginRight: '2em', cursor: 'pointer' }}
            onClick={async () => await publish()}>
            Publish
          </Button> : null}
          {isEditing ? <div title='Click to delete playlist'><DeleteForeverTwoTone
            color='error'
            style={{ fontSize: '2em', cursor: 'pointer' }}
            onClick={(evt) => {
              evt.stopPropagation()
              setConfirmDelete(!confirmDelete)
            }}
          /> </div> : null}
        </div>
      </>
    )
  }

  const displayCreate = () => {
    return (
      <>
        <h4 style={{ textAlign: 'center' }}>{collectionId ? `Edit ${collectionName}` : 'Create Playlist'}</h4>
        {displayForm()}
        {displayCollectionMessages()}
      </>
    )
  }
  const displayCreateCollection = () => {
    return (<div style={{ marginLeft: '2em', marginRight: '2em', paddingBottom: '3em' }}>
      <ErrorLine error={error} />
      {displayCreate()}
      <ModalDialog
        title='Confirm Delete'
        message={`Are you sure you want to permanently delete this playlist?`}
        close={() => setConfirmDelete(false)}
        open={confirmDelete}
        noLabel='No'
        no={() => setConfirmDelete(false)}
        yesLabel='Yes'
        yes={async () => {
          setConfirmDelete(false)
          try {
            await deleteCollectionById(collectionId, accessToken)
            history.goBack()
          } catch (error) {
            displayError(error, setError)
          }
        }}
      />

    </div>

    )
  }

  /**
   * Note the use of RadioGroup onClick and not onChange
   */
  const displayPlayerMode = () => {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center', fontWeight: 'bold', textAlign: 'center', paddingTop: '0.5em',
        paddingBottom: '0.5em'
      }}>
        <FormControl component="fieldset">
          <FormLabel component="legend" >Default Player Mode</FormLabel>
          <RadioGroup row aria-label="playerMode" name="playerMode" value={collectionPlayerMode}
          >
            <FormControlLabel value={PLAYER_MODES.NONE} onClick={() => setCollectionPlayerMode(PLAYER_MODES.NONE)} control={<Radio />} label="None" />
            <FormControlLabel value={PLAYER_MODES.POST} onClick={() => setCollectionPlayerMode(PLAYER_MODES.POST)} control={<Radio />} label="Post" />
            <FormControlLabel value={PLAYER_MODES.LIST} onClick={() => setCollectionPlayerMode(PLAYER_MODES.LIST)} control={<Radio />} label="List" />
            <FormControlLabel value={PLAYER_MODES.GRID} onClick={() => setCollectionPlayerMode(PLAYER_MODES.GRID)} control={<Radio />} label="Grid" />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  const removeFromPlaylist = (msg) => {
    const { messages } = editCollection
    const ix = messages.findIndex(m => m._id === msg._id)
    if (ix !== -1) {
      messages.splice(ix, 1)
      const updatedMessages = [...messages]
      const updatedCollection = { ...editCollection, messages: updatedMessages }
      setEditCollection(updatedCollection)
      console.log('removeFromPlaylist updated', updatedCollection)
    }
  }

  const displayCollectionMessage = (msg) => {
    const { messageId } = msg
    return (
      <div key={messageId} style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {
        setCollectionCover(msg.media)
        getMedia(msg.media)
      }}>{msg.name}
        <div style={{ cursor: 'pointer' }} title='Click to remove from playlist'
          onClick={(evt) => {
            evt.stopPropagation()
            removeFromPlaylist(msg)
          }}>
          <Delete />
        </div>
      </div>
    )
  }

  const displayCollectionMessages = () => {
    if (editCollection) {
      const { messages } = editCollection
      return (
        <div>
          <hr />
          <p style={{ fontStyle: 'italic', fontSize: '0.75em', lineHeight: 'normal', textAlign: 'center' }}>Drag and drop to rearrange playlist. Click to set Playlist cover. Click trash can to remove from playlist.</p>
          <ListDND sourceList={messages}
            updateList={(list) => setCollectionMessages(list)}
            renderLine={displayCollectionMessage} />
        </div>
      )
    }
  }

  const getMedia = async (media) => {
    const imageMedia = getMediaThumbnail(media)
    const { source } = imageMedia
    try {
      const data = await downloadCoreFile(source, 'image')
      const src = URL.createObjectURL(data)
      setImageSrc(src)
    } catch (error) {
      displayError(error, setError)
    }
  }

  const getCollectionToEdit = async () => {
    try {
      const collection = await getCollectionById(collectionId, userId)
      //console.log('collection', collection)
      setEditCollection(collection)
      const { name, description, messages, media, playerMode = PLAYER_MODES.NONE } = collection
      setCollectionName(name)
      setDescription(description)
      setCollectionMessages(messages)
      setCollectionPlayerMode(playerMode)
      console.log('collectionMessages', messages)
      let collectionMedia = media && media.length ? media : messages[0].media
      setCollectionCover(collectionMedia)
      await getMedia(collectionMedia)
    } catch (error) {
      displayError(error, setError)
    }

  }

  useEffect(() => {
    addArtistStudios(accessToken).then(s => setStudios(s.studios)).catch(error => displayError(error, setError))
  }, [])

  useEffect(() => {
    if (isEditing) {
      getCollectionToEdit()
    }
  }, [])

  return (
    <div >
      <HarmonizeHeader />
      {displayCreateCollection()}
      <Footer />
    </div>
  )
}
