import { Badge } from '@material-ui/core'
import { Notifications } from '@material-ui/icons'
import { getNotifications } from 'controllers/HarmonizeController'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'

export default function NotificationsBadge({ style = { fontSize: '1.25em', cursor: 'pointer', paddingRight: '0.5em' } }) {
    const accessToken = getStorageAccessToken()
    const [notificationsCount, setNotificationsCount] = useState(0)
    const history = useHistory()
    const displayBadge = () => {
        if (notificationsCount) {
            return (
                <div style={style} onClick={() => history.push('/Notifications')}>
                    <Badge badgeContent={1} >
                        <Notifications />
                    </Badge>
                </div>
            )

        }
    }
    const getUnreadNotifations = async () => {
        try {
            if (accessToken) {
                const unreadNotifications = await getNotifications(0, 20, accessToken)
                setNotificationsCount(unreadNotifications.length)
            }
        } catch (error) {
            console.error('Error getting unreadNotifications', error)
        }
    }

    useEffect(() => {
        getUnreadNotifations()
    }, [])
    return displayBadge()
}