import React, { useEffect, useState } from 'react'

import RoomLine from './RoomLine'
import ErrorLine from 'components/ErrorLine'
import { displayError } from 'util/screenUtils'
import { fetchRooms } from 'controllers/AssetController'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'

export default function RoomList({ ownerUsername, isOwner, handle, category }) {
  const account = getStorageAccount()
  const accessToken = getStorageAccessToken()
  const [rooms, setRooms] = useState()
  const [error, setError] = useState()

  const getOwnerRooms = async () => {
    try {
      const ownerRooms = await fetchRooms(ownerUsername, isOwner || (accessToken && account && account.username === ownerUsername))
      const actualRooms = category ? ownerRooms.filter(r => r.category === category) : ownerRooms
      setRooms(actualRooms)
    } catch (error) {
      displayError(error, setError)
    }

  }

  useEffect(() => {
    getOwnerRooms()
  }, [ownerUsername])
  const displayRooms = () => {
    if (rooms && rooms.length) {

      return (
        <div>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Studios</p>
          <ErrorLine error={error} />
          {rooms.map((room) => (
            <RoomLine
              key={room._id}
              asset={room}
              roomOwnerHandle={handle}
            />
          ))}
        </div>
      )
    } else {
      return null
    }
  }

  return displayRooms()
}
