import { useMediaQuery } from '@material-ui/core'
import Card from 'components/Card/Card'
import { displayError } from 'util/screenUtils.js'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getMediaThumbnail } from 'util/imageUtils'
import { isVeryNarrow } from 'util/screenUtils'
import ReactGA from 'react-ga4'
import ErrorLine from 'components/ErrorLine'
import { getMediaVideo } from 'util/imageUtils'
import HLSVideoPlayer from './HLSVideoPlayer'
import { isPreviews } from 'util/postUtils'
import { useSelector } from 'react-redux'
export default function SongCard({ song, fromPlaylist, selected }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)

    const styles = {
        imageCard: {
            position: 'relative',
            borderRadius: veryNarrow ? '1em' : '2em',
            color: 'white',
            width: veryNarrow ? '40vw' : '30vw',
            height: veryNarrow ? '40vw' : '30vw'
        },
        hovered: {
            margin: 'auto',
            transform: 'scale3d(1.05, 1.05, 1)',
        },
        notHovered: {
            margin: 'auto',
        }
    }
    const { currentRoom } = useSelector(state => state.harmonize)
    const [hovered, setHovered] = useState(false)
    const { name, _id: messageId } = song
    const [imageSrc, setImageSrc] = useState()
    const [videoSrc, setVideoSrc] = useState()
    const [error, setError] = useState()
    const history = useHistory()

    const displayVideo = () => {
        if (videoSrc) {
            const { highlightTime } = song
            //console.log(`highlightTime ${highlightTime}`, song)
            const startPosition = highlightTime ? highlightTime : 0
            const preview = isPreviews(currentRoom)
            const endPosition = preview ? startPosition + parseInt(process.env.REACT_APP_AUDIO_PREVIEW_LENGTH) : 0
            return (
                <HLSVideoPlayer autoPlay muted source={videoSrc}
                    startPosition={startPosition}
                    endPosition={endPosition}
                    preview={preview}
                    videoStyle={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            )
        }
    }

    const displayImageCard = () => {
        return (
            <div onClick={(evt) => {
                evt.stopPropagation()
                if (selected) {
                    //if (!videoSrc) {
                    selected(evt)
                    //}
                } else {
                    history.push(`/song/${messageId}`)
                    ReactGA.event({ category: 'click', action: 'song_card', label: name })
                }
            }}>
                <Card
                    className='plain'
                    onMouseOver={() => setHovered(true)}
                    onMouseOut={() => setHovered(false)}
                    style={{
                        ...styles.imageCard, ...hovered ? styles.hovered : styles.notHovered, ...videoSrc ? {} : {
                            backgroundImage: 'url(' + imageSrc + ')',
                            backgroundSize: 'cover',
                            opacity: 1
                        }
                    }}

                >
                    <ErrorLine error={error} />
                    {displayVideo()}
                    <span
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            fontSize: veryNarrow ? '0.75em' : '1em',
                            lineHeight: 'normal',
                            fontWeight: hovered ? 'bold' : 'normal',
                            color: hovered ? 'gold' : 'white',
                            backgroundColor: 'grey',
                            cursor: videoSrc ? 'pointer' : 'none'
                        }}

                    >
                        {name}

                    </span>
                </Card>
            </div>

        )
    }

    /**
     * The format of a Media entry differs when in the Playlist and when in
     * the original Message.
     */
    const getMedia = async () => {
        const media = fromPlaylist ? song.entryMedia : song.media
        try {
            const imageMedia = getMediaThumbnail(media)
            const { source } = imageMedia
            const data = await downloadCoreFile(source, 'image')
            const src = URL.createObjectURL(data)
            setImageSrc(src)
            const videoMedia = getMediaVideo(media)
            if (videoMedia) {
                if (fromPlaylist) {
                    const { source: videoSource } = videoMedia
                    setVideoSrc(videoSource)
                } else {
                    const { source, name } = videoMedia
                    const videoParameters = `${process.env.REACT_APP_API_KEY}/${source}/${name}`
                    //const videoEncryptedParams = await encryptString(videoParameters, pub)
                    const url = `${process.env.REACT_APP_CORE_SERVICE}media/video/${videoParameters}`
                    setVideoSrc(url)
                }

            }
        } catch (error) {
            displayError(error, setError)
        }

    }

    useEffect(() => {
        getMedia()
    }, [song])
    return (

        displayImageCard()
    )
}