import Hls from "hls.js"
import { useEffect, useRef } from "react"
import { getStorageAccessToken } from 'redux/actions/accountActions'

/** 
 * The video will start at startPosition.
 * 
 * If endPosition is provided, it will loop between startPosition and endPosition until the
 * player fullscreen or volume buttons are pressed. These interactions disable looping so
 * that the user can view the entire video.
 * 
 * Use hls.js for HLS because video.js doesn't work for HLS as of 11/20/2024.
 * @see https://github.com/video-dev/hls.js
 * @see https://stackoverflow.com/questions/76632259/how-to-use-hls-js-with-react-properly
 */

export default function HLSVideoPlayer({ source, startPosition = 0, endPosition, preview, videoStyle, ...props }) {
    //console.log(`HLSPlayer start ${startPosition} ${typeof (startPosition)} end ${endPosition} ${typeof (endPosition)}`)
    const videoRef = useRef()
    let interacted = false //Don't use state or it is lost when switching to fullscreen and back

    useEffect(() => {
        const hls = new Hls({
            "debug": false,
            startPosition
        })

        if (Hls.isSupported()) {
            hls.log = true
            hls.loadSource(source)
            hls.attachMedia(videoRef.current)
            hls.on(Hls.Events.ERROR, (err) => {
                console.log(err)
            })

        } else {
            console.error('load WITHOUT HLS')
        }
    }, [source])
    useEffect(() => {
        const videoElement = videoRef.current
        let interval
        if (videoElement) {

            const handlePlay = () => {
                console.log(`Video started playing preview ${preview}`)
                interval = setInterval(() => {
                    const elapsedTime = Math.round(videoElement.currentTime)
                    //console.log(`interval check elapsedTime ${elapsedTime} endPos ${endPosition} ${interacted}`)
                    if (!interacted && endPosition && elapsedTime > endPosition) {
                        videoElement.currentTime = startPosition
                    }
                }, 1000)
            }

            const handlePause = () => {
                console.log('Video paused')
                clearInterval(interval)
            }

            const handleEnded = () => {
                console.log('Video ended')
                clearInterval(interval)
            }

            const handleInteraction = (evt) => {
                console.log('Video interaction', evt)
                if (!preview) {
                    clearInterval(interval)
                    interacted = true
                }
            }

            videoElement.addEventListener('play', handlePlay)
            videoElement.addEventListener('pause', handlePause)
            videoElement.addEventListener('ended', handleEnded)
            videoElement.addEventListener('fullscreenchange', handleInteraction)
            videoElement.addEventListener('volumechange', handleInteraction)

            return () => {
                clearInterval(interval)
                videoElement.removeEventListener('play', handlePlay)
                videoElement.removeEventListener('pause', handlePause)
                videoElement.removeEventListener('ended', handleEnded)
                videoElement.removeEventListener('fullscreenchange', handleInteraction)
                videoElement.removeEventListener('volumechange', handleInteraction)
            }
        }
    }, [])

    return (
        <video
            ref={videoRef}
            controls={!preview}
            {...props}
            src={source}
            style={videoStyle}
        />
    )
}