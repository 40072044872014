import { useMediaQuery } from '@material-ui/core'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import ErrorLine from 'components/ErrorLine'
import Footer from 'components/Footer/Footer'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import UserImage from 'components/image/UserImage'
import { getNotifications } from 'controllers/HarmonizeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { isVeryNarrow } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'
import moment from 'moment'
import { getPublicUserById } from 'controllers/AccountController'
import { Comment, Email, Favorite } from '@material-ui/icons'
import { markNotificationRead } from 'controllers/HarmonizeController'

export const NOTIFICATION_TYPE = {
    SYSTEM: 0,
    LIKE: 1,
    COMMENT: 2,
    REACTION: 3
}
export default function Notifications() {
    const notificationStyles = {
        notificationLine: { display: 'flex', alignItems: 'center' }
    }
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const accessToken = getStorageAccessToken()
    const [error, setError] = useState()
    const [notifications, setNotifications] = useState([])
    const history = useHistory()

    const notificationRead = async (notificationId) => {
        try {
            await markNotificationRead(notificationId, accessToken)
            await listNotifications()
        } catch (error) {
            displayError(error, setError)
        }
    }
    const displayNotificationType = (notificationType) => {
        switch (notificationType) {
            case NOTIFICATION_TYPE.LIKE:
                return <Favorite />
            case NOTIFICATION_TYPE.COMMENT:
                return <Comment />
            default:
                return notificationType
        }

    }
    const displayNotificationLine = (notification, ix) => {
        const { _id: notificationId, createdAt, notificationType, messageId, user } = notification
        const { firstName, lastName, handle } = user
        const { _id, name } = messageId
        return (
            <div key={ix} style={{ display: 'flex', flex: 1, alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', width: veryNarrow ? '100%' : '50%' }}

            >
                <span style={{ ...notificationStyles.notificationLine, ...{ flex: 0.1, cursor: 'pointer' } }}
                    title='Click to mark as read'
                    onClick={async () => await notificationRead(notificationId)}
                ><Email /></span>
                <span style={{ ...notificationStyles.notificationLine, ...{ flex: 0.1 } }}>{moment.utc(createdAt).format('M/D/YY')}</span>
                <div style={{ ...notificationStyles.notificationLine, ...{ flex: 0.4 } }}><UserImage image={user} style={imagesStyles.bidProfileImageContainer} />{firstName} {lastName} @{handle}</div>
                <span style={{ ...notificationStyles.notificationLine, ...{ flex: 0.35, cursor: 'pointer' } }}
                    onClick={() => {
                        history.push(`/song/${_id}`)
                        notificationRead(notificationId)
                    }}
                    title={`Click to go to this song`}>{name}</span>
                <span style={{ ...notificationStyles.notificationLine, ...{ flex: 0.05 } }}> {displayNotificationType(notificationType)}</span>

            </div >
        )
    }
    const displayNotifications = () => {
        return (
            notifications.map((sl, ix) => displayNotificationLine(sl, ix))
        )
    }

    const getRelatedUser = async (notification) => {
        const { referenceUserId } = notification
        let user
        try {
            user = await getPublicUserById(referenceUserId)
        } catch (error) {
            console.error(`Missing user for ${referenceUserId}`, notification)
        }
        return { ...notification, user }

    }
    /**
     * Get the Messages to which the Credits refer.
     * @todo Use a lighter endpoint that just gets the name
     * @param {*} artistCredits 
     * @returns 
     */
    const getRelatedUsers = async (notifications) => {
        return Promise.all(
            notifications.map(async (ac) => getRelatedUser(ac))
        )
    }

    const listNotifications = async () => {
        try {
            const notifications = await getNotifications(0, 20, accessToken)
            const updatedNotifications = await (getRelatedUsers(notifications))
            setNotifications(updatedNotifications)
        } catch (error) {
            displayError(error, setError)
        }

    }
    useEffect(() => {
        document.title = 'Notifications'
        listNotifications()
    }, [])

    return (
        <div>
            <HarmonizeHeader />
            <div style={{ margin: '1em' }}>
                <h4 style={{ textAlign: 'center' }}>Notifications</h4>
                <ErrorLine error={error} />
                {displayNotifications()}
            </div>
            <Footer />
        </div>
    )
}