import * as REDUX_TYPES from '../types'
import { getStorageSettings, storeSettings } from './settingsActions'


export const setShowAiSignIn = (showAiSignIn) => {
  storeSettings({ ...getStorageSettings(), showAiSignIn })
  return {
    type: REDUX_TYPES.HELP.SHOW_AI_SIGN_IN,
    showAiSignIn,
  }
}
export const setShowAiWelcome = (showAiWelcome) => {
  storeSettings({ ...getStorageSettings(), showAiWelcome })
  return {
    type: REDUX_TYPES.HELP.SHOW_AI_WELCOME,
    showAiWelcome,
  }
}

export const setShowProfileHelp = (showProfileHelp) => {
  storeSettings({ ...getStorageSettings(), showProfileHelp })
  return {
    type: REDUX_TYPES.HELP.SHOW_PROFILE_HELP,
    showProfileHelp,
  }
}

export const setShowWelcome = (showWelcome) => {
  storeSettings({ ...getStorageSettings(), showWelcome })
  return {
    type: REDUX_TYPES.HELP.SHOW_WELCOME,
    showWelcome,
  }
}
