import { useDispatch, useSelector } from 'react-redux'
import { setForceSignIn } from 'redux/actions/harmonizeActions'
import SignInComponent from './SignInComponent'
import BlurDialog from './utility/BlurDialog'
import harmonySmall from '../images/harmony-small.jpg'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
export default function DisplaySignIn({ returnPath }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const { currentRoom } = useSelector(state => state.messages)
    const { roomOwner } = currentRoom
    const { forceSignIn } = useSelector(state => state.harmonize)
    const dispatch = useDispatch()
    const signInContent = () => {
        console.log('currentRoom', currentRoom)

        const { fullName } = roomOwner
        return (
            <div style={{ fontSize: veryNarrow ? '.75em' : '1em', lineHeight: 'normal', padding: veryNarrow ? '.75em' : '1em' }}>
                <div style={{ textAlign: 'center' }}>Sign in or create your free account to enjoy all of Harmonize.
                    <p>To listen to
                        full songs from {fullName}, <Link to={`/BuyCredits`} replace>click here</Link>
                    </p>
                </div>
            </div>
        )
    }

    /**
     * There was a click listener in the outer div; however this was triggered by clicking in
     * the Firebase signin dialog so we removed it.
     * @returns 
     */
    const displaySignIn = () => {

        return (<div>
            <BlurDialog
                content={() => <SignInComponent returnPath={returnPath} content={signInContent} />}
                image={harmonySmall}
                imageStyle={{ display: 'flex', width: '40vw', marginLeft: 'auto', marginRight: 'auto' }}
                hideHelp hideTitle close={() => dispatch(setForceSignIn(false))} closeIcon top={'5vh'} />
        </div>)

    }

    return forceSignIn && roomOwner ? displaySignIn() : null
}