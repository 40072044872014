import { useMediaQuery } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import ErrorLine from 'components/ErrorLine'
import Footer from 'components/Footer/Footer'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import UserImage from 'components/image/UserImage'
import { getSubscribers } from 'controllers/HarmonizeController'
import { useEffect } from 'react'
import { isValidElement } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { isVeryNarrow } from 'util/screenUtils'
import { displayError } from 'util/screenUtils'

export default function Subscribers() {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const accessToken = getStorageAccessToken()
    const [error, setError] = useState()
    const [subscribers, setSubscribers] = useState([])
    const history = useHistory()
    const columns = [
        { field: 'col1', headerName: 'Image', width: 150 },
        { field: 'col2', headerName: 'Name', width: veryNarrow ? 150 : 200 },
        { field: 'col3', headerName: 'Handle', width: 150 }
    ]
    const displaySubscriberLine = (subscriber, ix) => {
        const { firstName, lastName, handle, username } = subscriber
        return (
            <div key={ix} style={{ display: 'flex', flex: 1, alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer', width: veryNarrow ? '100%' : '50%' }}
                onClick={() => history.push(`/UserProfile/${username}`)}
                title={`Click to visit @${handle} profile`}
            >
                <span style={{ flex: 0.25 }}><UserImage image={subscriber} style={imagesStyles.bidProfileImageContainer} /></span>
                <span style={{ flex: 0.5 }}>{firstName} {lastName}</span>
                <span style={{ flex: 0.25 }}> {handle}</span>
            </div>
        )
    }
    const displaySubscribers = () => {

        return (
            subscribers.map((sl, ix) => displaySubscriberLine(sl, ix))
        )

    }

    const listSubscribers = async () => {
        try {
            const subscribers = await getSubscribers(0, 10, accessToken)
            console.log('subscribers', subscribers)
            setSubscribers(subscribers)
        } catch (error) {
            displayError(error, setError)
        }

    }
    useEffect(() => {
        document.title = 'Subscribers'
        listSubscribers()
    }, [])

    return (
        <div>
            <HarmonizeHeader />
            <div style={{ margin: '1em' }}>
                <h4 style={{ textAlign: 'center' }}>My Subscribers</h4>
                <ErrorLine error={error} />
                {displaySubscribers()}
            </div>
            <Footer />
        </div>
    )
}