import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import styles from 'assets/jss/material-kit-react/views/loginPage.js'

import { setAccessToken } from 'redux/actions/accountActions'
import { setAccount } from 'redux/actions/accountActions'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { googleSignIn } from 'controllers/AccountController'
import { getAuth, signOut } from 'firebase/auth'
import { getAndConnectRoom } from 'controllers/VortexController'
import { displayError } from 'util/screenUtils'
import { updateUser } from 'controllers/AccountController'
import harmonySmall from '../images/harmony-small.jpg'
import { setForceSignIn } from 'redux/actions/harmonizeActions'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import BlurDialog from './utility/BlurDialog'
import { setPostSignInPage } from 'redux/actions/harmonizeActions'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

/**
 * 
 * @param {*} success If provided this function is called on successful sign in  
 * @returns 
 */
export default function SignInComponent({ returnToHandle, returnToRoom, returnPath, fromBuyCredits, requestedRoom, content, success }) {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [error, setError] = useState()
  const [showInfo, setShowInfo] = useState(true)
  const { postSignInPage } = useSelector(state => state.harmonize)
  const { currentRoom } = useSelector(state => state.messages)
  //console.log(`SignIn requestedRoom ${requestedRoom}`)
  const dispatch = useDispatch()

  /* It seems that Firebase email sign in is effed up always shows First Name and Last Name fields unless you do this:
  https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection#disable

  because of this:

  https://github.com/firebase/firebaseui-web/issues/1040

  Always something...
  */
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['https://www.googleapis.com/auth/userinfo.email'],
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (result) => signinSuccess(result),
    },
    tosUrl: '/TermsOfService',
    privacyPolicyUrl: '/Privacy',
  }

  const history = useHistory()

  const classes = useStyles()
  const harmonizeSignOut = (action) => {
    console.log('harmonizeSignOut')
    setAccessToken(null)
    setAccount(null)

    const auth = getAuth()
    signOut(auth)
      .then(() => {
        if (action) {
          action()
        }
      })
      .catch((error) => {
        console.error(`Unable to sign out`, error)
      })
  }
  const defaultSignInContent = () => {
    return (
      <BlurDialog
        content={() => (
          <div style={{ fontSize: veryNarrow ? '.75em' : '1em', lineHeight: 'normal', padding: veryNarrow ? '.75em' : '1em', color: 'black' }}>
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              {fromBuyCredits ? 'To purchase Harmonize streaming credits, please sign in or create your free Account'
                : 'Please sign in or create your free account'}</p>

            {displayFixedContent()}
          </div>)}
        image={harmonySmall} hideHelp hideTitle close={() => history.goBack()}
        imageStyle={{ display: 'flex', width: '40vw', marginLeft: 'auto', marginRight: 'auto' }}
        closeIcon top={'10vh'} />
    )
  }
  /**
   * The signin callback tells us if the user email is verified or is new. It also gives us
   * the idtoken that we send to the Core Service to complete sign in with Harmonize. 
   * 
   * WRONG: Google has both an "id" that is the same for the account across all services and
   * a "uid" that is different for each client app. We need the "id" because we do not differentiate users by host platform. But that
   * only works for a "Google sign in" which is a server-side thing that we no longer use. We need
   * the Firebase user_id and that does not comeback with this call,
   * @param {*} result
   * @returns
   */
  const signinSuccess = (result) => {
    console.log('signinSuccess', result)
    const { additionalUserInfo, user } = result
    const { isNewUser, providerId } = additionalUserInfo
    const { emailVerified } = user
    console.log(
      `additionalUserInfo verified ${emailVerified}`,
      additionalUserInfo
    )
    if (
      additionalUserInfo &&
      providerId === 'password' &&
      (isNewUser || !emailVerified)
    ) {
      //If not verified, send email verification whether or not this is a new user
      //Note this uses a hideous email confirmed page until this is done:
      //https://firebase.google.com/docs/auth/custom-email-handler
      console.log('Sending email verification to', result.user)
      result.user.sendEmailVerification()
      harmonizeSignOut(() => history.replace('/ConfirmEmailAddress'))
    } else {
      console.log('Signed in existing user', user)
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idtoken) {
          await completeSignIn(idtoken, additionalUserInfo)
        })
        .catch(function (error) {
          console.error('signin FAILED', error)
          displayError(error, setError)
        })
    }
    return false
  }
  /**
   * Both the accessToken and some User info comes back in the response from googleSignIn. We
   * store the accessToken separately from the User account.
   *
   * When a Google profile photo changes, this sign in has the URL right away; however the server sign in does not.
   * So take what we get here and store it with the account.
   * @param {*} idtoken
   */
  const completeSignIn = async (idtoken, additionalUserInfo) => {
    try {
      //console.log(`completeSignIn ${idtoken}`)
      const response = await googleSignIn(
        idtoken
      )

      const { status } = response
      if (status !== 200) {
        const result = await response.json()
        console.error('auth response', result)
        displayError(result, setError)
      } else {
        const result = await response.json()
        //console.log('completeSignIn result', result)

        const { accessToken } = result
        setAccessToken(accessToken)
        delete result.accessToken

        const { picture } = additionalUserInfo.profile
        const { providerImgUrl } = result
        //console.log(`completeSignIn got ${picture} the server sign in got ${providerImgUrl}`)
        if (providerImgUrl && providerImgUrl !== picture) {
          const updatedAccount = { ...result, providerImgUrl: picture }
          setAccount(updatedAccount)
          await updateUser(updatedAccount, accessToken)
        } else {
          setAccount(result)
        }

        console.log(`SignIn success:\npostSignInPage ${postSignInPage} requestedRoom ${requestedRoom} returnPath ${returnPath} returnToHandle ${returnToHandle} 
          returnToRoom ${returnToRoom} currentRoom`, currentRoom)
        if (success) {
          success()
        }
        if (postSignInPage) {
          history.replace(postSignInPage)
          dispatch(setPostSignInPage(null))
        } else if (requestedRoom) {
          getAndConnectRoom(requestedRoom, accessToken, history, dispatch, (exc) => displayError(exc, setError))
        } else if (returnPath) {
          history.replace(returnPath)
        } else if (returnToHandle && returnToRoom) {
          console.log(`SignIn replace /@${returnToHandle}/${returnToRoom}`)
          history.replace(`/@${returnToHandle}/${returnToRoom}`)
        } else {
          history.replace('/')
        }
      }
    } catch (error) {
      console.error('completeSignIn FAILED', error)
      displayError(error, setError)
    }
    dispatch(setForceSignIn(false))
  }

  const displayFixedContent = () => {
    return (
      <div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          style={{ marginBottom: 0 }}
        />
        <p style={{ color: 'black', textAlign: 'center', fontSize: '0.75em', lineHeight: 'normal' }}>
          At Harmonize, we respect your privacy.<br />We never share your information, including email address, with any third party.</p>
        {error && (
          <h3 style={{ color: 'red', textAlign: 'center' }}>
            {error}
          </h3>
        )}
      </div>
    )
  }
  const displayDefaultOrCustom = () => {
    if (content && showInfo) {
      return (
        <div onClick={() => setShowInfo(false)}>
          {content()}
          {displayFixedContent()}
        </div>
      )
    } else {
      return (defaultSignInContent()

      )
    }
  }

  useEffect(() => {
    if (fromBuyCredits) {
      dispatch(setPostSignInPage('/BuyCredits'))
    }
  }, [])
  return (
    <div>
      <form className={classes.form}>
        {displayDefaultOrCustom()}
      </form>
    </div>

  )
}
