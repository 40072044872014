import React from 'react'
import Footer from 'components/Footer/Footer.js'
import HarmonizeHeader from 'components/Header/HarmonizeHeader'
import { useEffect } from 'react'
import MessageJsx from 'components/harmonize/MessageJsx'
import { Container } from '@material-ui/core'
import PriceList from 'components/harmonize/PriceList'
import { useHistory } from 'react-router-dom'

export default function Pricing() {
  const history = useHistory()
  useEffect(() => {
    document.title = 'Pricing'
  }, [])
  return (
    <div>
      <HarmonizeHeader />
      <div style={{ paddingBottom: '5em' }}>
        <Container maxWidth='md' component='main'>
          <h4 style={{ textAlign: 'center' }}>Current Pricing</h4>
          <PriceList />
          <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', fontWeight: 'bold', color: 'blue' }} onClick={() => history.push('/BuyCredits')}>Click here to buy credits</div>
          <MessageJsx messageId='66fb2d32f81fa131e2b25809' />
        </Container>
      </div>
      <Footer />
    </div>
  )
}
