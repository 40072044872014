import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { useEffect } from 'react'
import { useState } from 'react'

export default function ListDND({ sourceList, updateList, renderLine, grid = 1 }) {
    const [reorderedList, setReorderedList] = useState(sourceList)
    // a little function to help us with reordering the result
    const reorder = (startIndex, endIndex) => {
        const result = [...reorderedList]
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        console.log(`reorder ${startIndex} ${endIndex}`, result)
        setReorderedList(result)
        updateList(result)
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    })

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        padding: grid,
        width: '100%'
    })

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        reorder(
            result.source.index,
            result.destination.index
        )

    }

    useEffect(() => {
        setReorderedList(sourceList)
        updateList(sourceList)
    }, [sourceList])

    return (

        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {reorderedList.map((item, index) => (

                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {renderLine(item)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    )
}
