
import ReactPlayer from 'react-player/file'
//We had to take the scss from the Github lib and include it in our scss files.
//import 'react-h5-audio-player/lib/styles.css'
import { useState } from 'react'
import UserImage from 'components/image/UserImage'
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles'
import { primaryColor } from 'assets/jss/material-kit-react.js'
import { useHistory } from 'react-router-dom'
import { displayHomeIcon } from 'util/brandUtils'
import { getMessage } from 'controllers/VortexController'
import { useEffect } from 'react'
import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { useSelector } from 'react-redux'
import { getPublicUserById } from 'controllers/AccountController'
import { displayError } from 'util/screenUtils'
import { getMediaVideo } from 'util/imageUtils'
import { createRef } from 'react'
import { isPreviews } from 'util/postUtils'
import { useRef } from 'react'

const playerStyles = {

    normal: { marginLeft: 'auto', marginRight: 'auto' }
}

/** Displays a player for the video in the current playList entry.
 * 
 * Video does not play on mobile devices and this may never be fixed in react-player.
 * So don't call this on mobile (i.e. veryNarrow)
 *  * 
 */
export default function VideoPlayer() {
    const { currentRoom, playList, playListIndex } = useSelector(state => state.harmonize)
    const [user, setUser] = useState()
    const [currentMessage, setCurrentMessage] = useState()
    const [error, setError] = useState()
    const ref = useRef()
    const history = useHistory()

    const displayPlayerHeader = () => {
        if (user) {
            const { img, providerImgUrl, firstName, lastName, handle } = user
            const { name } = playList[playListIndex]
            return (
                <div style={{
                    display: 'flex', backgroundColor: primaryColor, color: 'gold', padding: '10px 15px', alignItems: 'center'
                }}>
                    {displayHomeIcon(history)}
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                        <UserImage
                            title={`${firstName}`}
                            clickTitle={`Go to ${firstName} ${lastName} music`}
                            image={{ img, providerImgUrl }}
                            style={imagesStyles.vortexProfileImageContainer}
                            click={() => {
                                history.replace(`/@${handle}`)
                            }}

                        /> <div>
                            <span style={{ fontSize: '0.75em' }}>{firstName}&nbsp;{lastName}</span>
                            {isPreviews(currentRoom) ? <div style={{ fontWeight: 'bold', color: 'darkorange', lineHeight: '0.75em' }}>Previews</div> : <br />}
                            <span style={{ fontWeight: 'bold' }}>{name}</span>
                        </div></div>
                    {/*displaySubscribed()*/}
                </div>
            )
        } else {
            return (
                <div style={{
                    display: 'flex', backgroundColor: primaryColor, color: 'gold', padding: '10px 15px', alignItems: 'center'
                }}>
                    {displayHomeIcon(history)}
                </div>
            )
        }
    }

    const jumpToHighlight = () => {
        if (isPreviews(currentRoom)) {
            const { highlightTime } = playList[playListIndex]
            if (ref.current && highlightTime) {
                ref.current.seekTo(highlightTime, 1)
            }
        }

    }
    const displayVideoPlayer = () => {
        console.log(`displayVideoPlayer ${playListIndex}`, playList[playListIndex])
        if (playList[playListIndex]) {
            const { entryMedia, highlightTime } = playList[playListIndex]
            const endPosition = isPreviews(currentRoom) ? highlightTime + parseInt(process.env.REACT_APP_AUDIO_PREVIEW_LENGTH) : 0
            const mediaEntry = getMediaVideo(entryMedia)
            if (mediaEntry) {
                const { source } = mediaEntry
                console.log(`displayVideoPlayer ${source}`)
                const { summary, description } = currentMessage
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ReactPlayer
                            ref={ref}
                            controls url={source} width='100%' height='100%'
                            onProgress={(progress) => endPosition && progress.playedSeconds > endPosition ? jumpToHighlight() : {}}
                            onPlay={() => jumpToHighlight()}
                            onError={(error) => console.error(`ERROR playing ${source}`, error)}
                            style={playerStyles.normal} />
                        <span style={{ paddingLeft: '1em', paddingRight: '1em' }}>{summary ? summary : description}</span>
                    </div>
                )
            } else {
                return <h4>Missing video for {currentMessage.name}</h4>
            }
        }
    }


    /*The video source references the actual server folder: e.g., video/cjmusic/cjvilla-photo/test
     * 
     * The url references: :username/:foldername/:subfoldername/:videoname 
     * 
     * @todo The video and its thumbnail are both currently retrived using the PUBLIC Api key. This must be changed to respect the
     * protection status of the Room.
     * @returns 
     *
    const getVideoUrl = () => {
        console.log('getVideoUrl', currentMessage)
        const { media } = currentMessage
        let mediaEntry
        for (const md of media) {
            if (md && md.mimeType === 'video/mp4') {
                mediaEntry = md
                break
            }
        }
        const { url } = mediaEntry
        return `${process.env.REACT_APP_CORE_SERVICE}media/video/${url}`
    }
    */

    /*
     * @todo The video and its thumbnail are both currently retrived using the PUBLIC Api key. This must be changed to respect the
     * protection status of the Room.
     * 
     * @see https://johnnyreilly.com/structured-data-seo-and-react
     * @returns 
     *
    const createStructuredData = () => {
        const { firstName, lastName } = user
        const { summary, message, createdAt } = currentMessage
        const thumbnailUrl = [`${process.env.REACT_APP_CORE_SERVICE}backblaze/download/${process.env.REACT_APP_PUBLIC_API_KEY}/${mediaSource}/image`]
        const contentUrl = getVideoUrl()
        const video = {
            "@context": "https://schema.org/",
            "@type": "VideoObject",
            name: `${title}`,
            author: {
                "@type": "Person",
                "name": `${firstName} ${lastName}`
            },
            thumbnailUrl,
            contentUrl,
            uploadDate: moment(createdAt).format(),
            description: message ? message : summary ? summary : undefined
        }
        console.log('createStructuredData', video)
        return video
    }
    */

    const getVideoMessage = async () => {
        const { messageId, userId } = playList[playListIndex]
        try {
            const message = await getMessage(messageId)
            if (message) {
                setCurrentMessage(message)
                const user = await getPublicUserById(userId)
                setUser(user)
            }
        } catch (error) {
            displayError(error, setError)
        }
    }

    useEffect(() => {
        getVideoMessage()
    }, [])

    if (currentMessage && user) {
        return (
            <div style={{ backgroundColor: 'white' }}>
                {/*
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(createStructuredData()),
                    }}
                />
                */}
                {displayPlayerHeader()}
                <ErrorLine error={error} />
                {displayVideoPlayer()}
            </div>

        )
    } else {
        return (
            <div>
                {displayPlayerHeader()}
                <ErrorLine error={error} />
                <TimerProgress />
            </div>
        )
    }
}