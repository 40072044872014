import { Add, Edit } from '@material-ui/icons'
import BlurDialog from 'components/utility/BlurDialog'
import { getCollectionsForUser } from 'controllers/HarmonizeController'
import { addToCollection } from 'controllers/HarmonizeController'
import { getCollections } from 'controllers/HarmonizeController'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { setCurrentCollection } from 'redux/actions/harmonizeActions'

export default function Collections({ userId, messageId, close }) {
    const [collections, setCollections] = useState([])
    const { currentRoom } = useSelector(state => state.messages)
    const { user, _id: roomId } = currentRoom
    const history = useHistory()
    const dispatch = useDispatch()
    const accessToken = getStorageAccessToken()

    const listCollections = () => {
        console.log('Collections', collections)
        return (
            <div>
                <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} title='Click to create a collection'
                    onClick={(evt) => {
                        evt.stopPropagation()
                        close()
                        history.push(`/CreateCollection/${messageId}`)
                    }}
                ><Add />Create Playlist</div>

                {collections.length ? <div style={{ fontStyle: 'italic' }}>Click name to add to collection</div> : null}
                {collections.map((c, ix) =>
                    <div key={ix} style={{ display: 'flex', alignItems: 'center' }}>
                        {user === userId ?
                            <Edit style={{ cursor: 'pointer', margin: '0.5em' }} onClick={() => {
                                dispatch(setCurrentCollection(c))
                                history.push(`/EditCollection/${c._id}`)
                            }} />
                            : null}
                        <div key={c.name} style={{ cursor: 'pointer' }} onClick={async () => {
                            await addToCollection(c._id, messageId, accessToken)
                        }}>{c.name}</div>
                    </div>
                )}
            </div>
        )
    }

    const displayCollections = () => {

        return (<div onClick={(evt) => {
            evt.stopPropagation()
            close()
        }}>
            <BlurDialog content={listCollections} hideHelp title='Playlists' closeIcon animationTime={200} />
        </div>)
    }

    const fetch = async () => {
        try {
            let coll
            if (user === userId) {
                coll = await getCollectionsForUser(accessToken)
            } else {
                coll = await getCollections(roomId)
            }
            setCollections(coll)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetch()
    }, [])

    return displayCollections()
}
