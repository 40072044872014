import React, { useEffect, useState } from 'react'
import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { DataGrid } from '@mui/x-data-grid'
import { getAccruedCredits } from 'controllers/HarmonizeController'
import { getMessage } from 'controllers/VortexController'
import { isVeryNarrow } from 'util/screenUtils'
import { useMediaQuery } from '@material-ui/core'
import { displayError } from 'util/screenUtils'
import { getAccruedCreditInterval } from 'util/creditUtils'

export const ListRevenue = ({ chartSong, listeners }) => {
  const veryNarrow = isVeryNarrow(useMediaQuery)
  const [loading, setLoading] = useState()
  const [accruedCredits, setAccruedCredits] = useState()
  const [error, setError] = useState('')
  const accessToken = getStorageAccessToken()

  const columns = [
    { field: 'col1', headerName: 'Song', width: 250 },
    { field: 'col2', headerName: 'Credits', width: 150 }
  ]

  /* Unused because if we get Credits by room for non-artists, we could have
  a very long list of rooms that e.g. are all named 'home' and we don't
  want to deal with that right now.
  const cellClicked = (event, artistUsage) => {
    const { field, id: songId } = event
    switch (field) {
      case 'col1':
        chartSong(songId, event.row.col1, artistUsage)
        break
      case 'col2':
        if (!artistUsage) {
          listeners(songId, event.row.col1)
        }
        break
    }
  }
  */

  const displayLoadingCredits = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4>Loading your accrued Credits...</h4>
        <TimerProgress />
      </div>
    )
  }

  const displayRevenue = (total) => {
    const artistRate = parseFloat(process.env.REACT_APP_ARTIST_CREDIT_RATE)
    return (
      <>
        <br />
        Total Revenue: {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(total * artistRate)}
      </>
    )
  }

  /**
   * To ensure the blocks do not overwrite each other wrap in parent with flexDirection column. 
   * @see https://stackoverflow.com/questions/71594629/i-dont-know-why-my-datagrid-mui-component-is-not-working-as-intended
   * @param {*} creditsBlock 
   * @param {*} artistUsage 
   * @returns 
   */
  const displayCreditsBlock = (creditsBlock, artistUsage) => {
    if (creditsBlock.length) {
      console.log(`displayCreditsBlock artistUsage ${artistUsage}`, creditsBlock)
      try {
        const dataRows = []
        let total = 0
        creditsBlock.forEach(rev => {
          const { count, messageId, message } = rev
          const fractionalCredit = count / getAccruedCreditInterval(artistUsage)
          const { name } = message
          const row = { id: messageId, col1: name, col2: Intl.NumberFormat('en-US', { minimumIntegerDigits: 3, minimumFractionDigits: 1, maximumFractionDigits: 2 }).format(fractionalCredit) }
          dataRows.push(row)
          total += parseFloat(fractionalCredit)
        })
        return (
          <div style={{ height: '50vh', width: '100%', paddingBottom: '2vh', display: 'flex', flexDirection: 'column' }}>
            <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>{artistUsage ? 'My' : 'Member'} usage</h5>
            Total Credits: {Intl.NumberFormat('en-US', { minimumFractionDigits: 1 }).format(total)}
            {!artistUsage ? displayRevenue(total) : null}

            <p style={{ fontSize: '0.75em', fontStyle: 'italic' }}>Click a song title to view daily song credits</p>
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'col2', sort: 'desc' }],
                },
              }}
              //onCellClick={(event) => cellClicked(event, artistUsage)}
              onRowClick={(event) => artistUsage ? chartSong(event.id, event.row.col1, artistUsage) : listeners(event.id, event.row.col1)}
              rows={dataRows} columns={columns}
              sx={{ cursor: 'pointer', fontWeight: '600', color: 'black', fontSize: veryNarrow ? '0.75em' : '1em' }} />

          </div>
        )
      } catch (error) {
        displayError(error, setError)
      }
    }
  }

  const displayAccruedCredits = () => {
    if (accruedCredits) {
      const { artistCredits, userCredits } = accruedCredits
      return (
        <div>
          {displayCreditsBlock(artistCredits, true)}
          {displayCreditsBlock(userCredits)}
        </div>
      )
    } else {
      return (
        <h4>You do not have any accrued credits</h4>
      )
    }
  }

  const getRelatedMessage = async (credit) => {
    const { count, messageId } = credit
    let name = 'Missing song'
    try {
      const message = await getMessage(messageId)
      name = message.name
    } catch (error) {
      console.error(`Missing song for ${messageId}`)
    }
    return { count, messageId, message: { name } }

  }
  /**
   * Get the Messages to which the Credits refer.
   * @todo Use a lighter endpoint that just gets the name
   * @param {*} artistCredits 
   * @returns 
   */
  const getRelatedMessages = async (creditsUsage) => {
    return Promise.all(
      creditsUsage.map(async (ac) => getRelatedMessage(ac))
    )
  }

  useEffect(() => {
    const listRevenue = async () => {
      try {
        setError()
        setLoading(true)
        const creditsUsage = await getAccruedCredits(accessToken)
        console.log('...creditsUsage', creditsUsage)
        const { artistChargedCredits, userChargedCredits } = creditsUsage
        const artistCredits = await getRelatedMessages(artistChargedCredits)
        const userCredits = await getRelatedMessages(userChargedCredits)
        setLoading(false)
        setAccruedCredits({ artistCredits, userCredits })
      } catch (error) {
        setLoading(false)
        console.error(error)
        displayError(error, setError)
      }
    }
    listRevenue()
  }, [accessToken])

  return (

    <div>
      <ErrorLine error={error} />

      {loading && displayLoadingCredits()}
      {displayAccruedCredits()}

    </div>

  )
}
