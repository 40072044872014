import * as REDUX_TYPES from '../types'

const INITIAL_STATE = {
  showAiSignIn: false,
  showAiWelcome: true,
  showProfileHelp: true,
  showWelcome: true,
}

const helpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDUX_TYPES.HELP.SHOW_AI_SIGN_IN:
      return {
        ...state,
        showAiSignIn: action.showAiSignIn,
      }
    case REDUX_TYPES.HELP.SHOW_AI_WELCOME:
      return {
        ...state,
        showAiWelcome: action.showAiWelcome,
      }
    case REDUX_TYPES.HELP.SHOW_PROFILE_HELP:
      return {
        ...state,
        showProfileHelp: action.showProfileHelp,
      }
    case REDUX_TYPES.HELP.SHOW_WELCOME:
      return {
        ...state,
        showWelcome: action.showWelcome,
      }
    default:
      return state
  }
}
export default helpReducer
