import ErrorLine from 'components/ErrorLine'
import TimerProgress from 'components/mint/TimerProgress'
import { BarChart, LineChart } from '@mui/x-charts'
import { useEffect } from 'react'
import { useState } from 'react'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import { getSongAccruedCredits } from 'controllers/HarmonizeController'
import { displayError } from 'util/screenUtils'
import moment from 'moment'
import { getAccruedCreditInterval } from 'util/creditUtils'

/** NOTE that we cannot display multiple charts on a single page because the overflow on mobile does
 * not work properly, meaning that yout cannot scroll to portions unseen.
 * @see https://github.com/mui/mui-x/issues/13885
 */
export default function ChartSong({ messageId, artistUsage }) {
    const accessToken = getStorageAccessToken()
    const [loading, setLoading] = useState()
    const [songCredits, setSongCredits] = useState()
    const [studioCreditsIx, setStudioCreditsIx] = useState(0)
    const [error, setError] = useState()
    const chartStyles = {
        notSelected: { cursor: 'pointer' },
        selected: { border: '1px solid blue' }
    }

    const displayLoadingSong = () => {
        return (
            <div style={{ width: '100%' }}>
                <h4>Loading song Credits...</h4>
                <TimerProgress />
            </div>
        )
    }

    const displayStudios = () => {
        if (songCredits) {
            return (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                >{songCredits.map((song, ix) => <span key={ix} style={ix === studioCreditsIx ? chartStyles.selected : chartStyles.notSelected}
                    onClick={() => setStudioCreditsIx(ix)}
                >{song.roomName}</span>)}</div>
            )
        }
    }
    const displayRoomCredits = () => {
        if (songCredits) {
            const { roomName, accruedCredits } = songCredits[studioCreditsIx]
            //console.log(`ChartSong roomCredits artistUsage ${artistUsage} roomName ${roomName}`, songCredits[studioCreditsIx])
            if (artistUsage || roomName !== 'private') {

                const formattedCredits = accruedCredits.map(sc => ({ count: sc.count / getAccruedCreditInterval(artistUsage), createdAt: moment.utc(sc.createdAt).format('M/D/YY') }))
                //console.log('formattedCredits', formattedCredits)
                return (
                    <div>

                        <LineChart
                            dataset={formattedCredits}
                            xAxis={[{ dataKey: 'createdAt', scaleType: 'band', tickPlacement: 'middle' }]}
                            series={[{ dataKey: 'count' }]}
                            height={300}
                        />
                    </div>
                )
            }
        }

    }

    /**
     * Gets array of:
     * {roomId,roomName,accruedCredits}
     * 
     * where accruedCredits is an array
     */
    const getSongCredits = async () => {
        try {
            setError()
            setLoading(true)
            const songAccruedCredits = await getSongAccruedCredits(messageId, artistUsage, accessToken)
            console.log('songAccruedCredits', songAccruedCredits)
            setSongCredits(songAccruedCredits)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            displayError(error, setError)
        }
    }
    useEffect(() => {
        getSongCredits()
    }, [])

    return (
        <div >
            <ErrorLine error={error} />
            {loading && displayLoadingSong()}
            {displayStudios()}
            {displayRoomCredits()}
        </div>

    )
}