import { useDispatch, useSelector } from 'react-redux'
import { isVeryNarrow } from 'util/screenUtils'
import { Grid, useMediaQuery } from '@material-ui/core'
import SongCard from './SongCard'
import { setPlayListIndex } from 'redux/actions/harmonizeActions'
import { useEffect } from 'react'
import { setPlayCurrentSong } from 'redux/actions/harmonizeActions'

export default function StudioGrid({ click }) {
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const { messages } = useSelector(state => state.messages)
    const dispatch = useDispatch()

    const displaySongs = () => {
        if (messages && messages.length) {
            return (
                <Grid container spacing={veryNarrow ? 2 : 3} style={{ width: '100%', paddingTop: '1em', margin: 0 }}  >
                    {messages.map((song, ix) => <Grid key={ix} item xs style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <SongCard song={song}
                            selected={(evt) => {
                                evt.stopPropagation()
                                if (click) {
                                    click(evt)
                                }
                                else {
                                    dispatch(setPlayListIndex(ix))
                                    dispatch(setPlayCurrentSong(song.name))
                                }
                            }}
                        /></Grid>)}
                </Grid>
            )
        }
    }

    useEffect(() => {
        console.log(`StudioGrid useEffect`, messages)
    }, [messages])
    return displaySongs()
}