import { TextField, useMediaQuery } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.js'
import { isIOS, isMacOs } from 'react-device-detect'
import { downloadCoreFile } from 'controllers/BackblazeController'
import { useEffect, createRef } from 'react'
import { useState } from 'react'
import roundBrain from 'images/round-brain.png'
import { isVeryNarrow } from 'util/screenUtils'
import AudioPlayer from 'react-h5-audio-player'
import { createRsaPublicKey } from 'util/encryptUtils'
import { encryptAudioUrl } from 'util/encryptUtils'
import { extractColors } from 'extract-colors'
import { displayError } from 'util/screenUtils'
import { checkCreditUsage } from 'util/creditUtils'
import { useDispatch, useSelector } from 'react-redux'
import { setCreditPlayTime } from 'redux/actions/harmonizeActions'
import { setAccruedCredits } from 'redux/actions/harmonizeActions'
import { getStorageAccount } from 'redux/actions/accountActions'
import { getStorageAccessToken } from 'redux/actions/accountActions'
import ErrorLine from 'components/ErrorLine'
import { getRoomById } from 'controllers/VortexController'
import { videoShort } from 'controllers/HarmonizeController'
import { AddCircle, ArrowLeft, ArrowRight, Cancel, HighlightOffTwoTone } from '@material-ui/icons'
import TimerProgress from 'components/mint/TimerProgress'
import { getMediaDisplay } from 'util/imageUtils'
import { aiChatStyles } from 'vortex/AIChat'
import AIChat from 'vortex/AIChat'
import { setShortClipStart } from 'redux/actions/shortActions'
import { setShortClipEnd } from 'redux/actions/shortActions'
import { setShortCaption } from 'redux/actions/shortActions'
import { setShortSummary } from 'redux/actions/shortActions'
import { MEDIA_TYPES } from 'util/postUtils'
import { getUploadImageSize } from 'util/imageUtils'
import { getUploadImageQuality } from 'util/imageUtils'
import { resize } from 'util/imageUtils'
import MediaDrop from 'components/media/MediaDrop'
import StandardSwitch from 'components/standard/StandardSwitch'
import { setShortImage } from 'redux/actions/shortActions'
import NumericInputField from 'components/NumericInputField'
import { setShortDescription } from 'redux/actions/shortActions'
import { setShortMediaIcons } from 'redux/actions/shortActions'
import MediaIcons from 'components/media/MediaIcons'

const videoStyles = {
    normal: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: '90vh'
    },
    narrow: {
        width: '100vw'
    }
}

/**
 * This is *not supported for legacy songs* that use Backblaze as the source of the MP3; i.e., the mp3 source must
 * reside in a local folder on the Core Service that creates the short.
 */
export default function CreateVideoShort({ song, currentRoom, onPlay, close }) {
    //console.log('CreateVideoShort', song)
    const MAX_CHARS_LINE = 30
    const { _id: messageId, summary, roomId, title } = song
    const { caption, clipEnd, clipStart, description, image, mediaIcons, shortSummary } = useSelector(state => state.short)
    const [captionLength, setCaptionLength] = useState(caption ? caption.length : 0)
    const [descriptionLength, setDescriptionLength] = useState(description ? description.length : 0)
    const [shortSummaryLength, setShortSummaryLength] = useState(shortSummary ? shortSummary.length : 0)
    const [fileToUpload, setFileToUpload] = useState()
    const [showUpload, setShowUpload] = useState(false)
    const [zoomVideo, setZoomVideo] = useState(false)
    const veryNarrow = isVeryNarrow(useMediaQuery)
    const [objectURL, setObjectURL] = useState(null)
    const [creatingShort, setCreatingShort] = useState(false)
    const [showAi, setShowAi] = useState(false)
    const account = getStorageAccount()
    const accessToken = getStorageAccessToken()
    const { accruedCredits, accruedCreditsRoom, creditPlayTime } = useSelector(state => state.harmonize)
    const [imageSrc, setImageSrc] = useState()
    const [imageMedia, setImageMedia] = useState()
    const [url, setUrl] = useState()
    const [error, setError] = useState()
    const [songRoom, setSongRoom] = useState()
    const dispatch = useDispatch()
    const player = createRef()
    const isApple = isIOS || isMacOs

    const checkCredits = async () => {
        try {
            const songForCredit = { ...song, messageId }
            const currentCredits = await checkCreditUsage(accruedCredits, creditPlayTime, songRoom, songForCredit,
                account, accessToken, dispatch)
            if (currentCredits < 1) {
                displayError(error, 'Unable to play: out of credits')
            }
        } catch (error) {
            displayError(error, setError, 5)
            //If applyCredit FAILS we still have to reset the accruedCredits array or we blow up the server
            //with a huge array. There should only be max 6 entries (with a 1 minute server update interval and a 10 second accruedCredit interval)
            dispatch(setCreditPlayTime(1))
            dispatch(setAccruedCredits([]))
        }
    }

    /**
     * When the image src is the dataUrl stored in the shortReducer, do this to get
     * a downloadable file.
     * @see https://stackoverflow.com/questions/61124866/how-do-convert-a-datauri-element-into-a-file-i-can-upload-via-react
     * @param {*} dataurl 
     * @param {*} filename 
     * @returns 
     */
    const dataURLtoBlob = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        //return new Blob([u8arr], { type: mime })
        return new File([u8arr], filename, { type: mime })
    }

    const getAudioMedia = () => {
        const { media } = song
        const mediaEntry = media.find(a => a.mimeType === 'audio/mpeg')
        const { source, sourceName, duration } = mediaEntry
        return { source, sourceName, duration }
    }

    const getUrl = async () => {
        const { source, sourceName } = getAudioMedia()
        const rsaKeyPair = await createRsaPublicKey()
        const songUrl = await encryptAudioUrl(source, sourceName, rsaKeyPair)
        setUrl(songUrl)
    }

    /** First split the shortSummary at new lines.
     * Then split each line at a maximum of 30 characters.
     */
    const splitSummary = () => {
        if (shortSummary) {
            let lines = ''
            const summaryLines = shortSummary.split('\n')
            summaryLines.forEach((ll, ix) => {
                const words = ll.split(' ')
                let line = ''
                words.forEach(w => {
                    //If the word itself is too long, output it on a single line
                    if (w.length >= MAX_CHARS_LINE) {

                        if (line.length) {
                            lines += (line + '\n')
                            line = ''
                        }
                        lines += (w + '\n')
                        line = ''
                    } else if (w.length + line.length >= MAX_CHARS_LINE) {

                        lines += (line + '\n')
                        line = w + ' '
                    } else {
                        line += w + ' '
                    }

                })
                if (line.length) {
                    lines += line
                }
                //Restore the original line break
                if (ix < summaryLines.length - 1) {
                    lines += '\n'
                }
            })
            return lines
        }
        return null
    }

    const assembleFilesToUpload = () => {
        const filesToUpload = []
        mediaIcons.forEach(mi => {
            const { name, dataUrl, mimeType, source } = mi
            if (source) {
                filesToUpload.push({ fileId: source })
            } else {
                const uploadFile = dataURLtoBlob(dataUrl, `${name}.jpg`)
                filesToUpload.push(uploadFile)
            }
        })
        return filesToUpload
    }

    const createVideoShort = async () => {
        try {
            setError('')
            const { username } = getStorageAccount()
            const accessToken = getStorageAccessToken()
            let fileId, uploadFile
            /*
            if (imageMedia) {
                fileId = imageMedia.source
            } else {
                if (fileToUpload) {
                    uploadFile = fileToUpload
                } else {
                    uploadFile = dataURLtoBlob(imageSrc, 'videoshort.jpg')
                }
            }
            */
            console.log('splitSummary', splitSummary())
            const filesToUpload = assembleFilesToUpload()
            console.log('filesToUpload', filesToUpload)

            setCreatingShort(true)
            const video = await videoShort(filesToUpload, {
                name: title, username, fileId,
                options: { duration: clipEnd - clipStart + 1, timeStart: clipStart, title: caption, zoom: zoomVideo, summary: splitSummary() },
                audioMedia: getAudioMedia()
            }, accessToken)

            if (isApple) {
                downloadVideo(video)
            } else {
                playVideo(video)
            }


        } catch (error) {
            setCreatingShort(false)
            displayError(error, setError)
        }
    }

    const playVideo = (video) => {
        const url = URL.createObjectURL(video)
        setCreatingShort(false)
        setObjectURL(url)
    }

    const downloadVideo = (video) => {
        setCreatingShort(false)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(video)
        link.download = `${title}`
        link.click()
        window.URL.revokeObjectURL(link.href)
    }

    const editCaption = () => {
        return (
            <TextField
                value={caption}
                onChange={(event) => {
                    dispatch(setShortCaption(event.target.value))
                    setCaptionLength(event.target.value.length)
                }}
                fullWidth
                label={`Caption (${captionLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH} characters)`}
                inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_NAME_LENGTH }}
            />
        )
    }

    const editDescription = () => {
        return (
            <TextField
                value={description}
                onChange={(event) => {
                    dispatch(setShortDescription(event.target.value))
                    setDescriptionLength(event.target.value.length)
                }}
                fullWidth
                multiline
                label={`Description (${descriptionLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters)`}
                inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
            />
        )
    }

    const editSummary = () => {
        return (
            <TextField
                value={shortSummary}
                onChange={(event) => {
                    dispatch(setShortSummary(event.target.value))
                    setShortSummaryLength(event.target.value.length)
                }}
                fullWidth
                multiline
                label={`Summary (${shortSummaryLength} of ${process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH} characters)`}
                inputProps={{ maxLength: process.env.REACT_APP_MAX_HARMONIZE_MESSAGE_LENGTH }}
            />
        )
    }

    const displayActions = () => {
        return (

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span style={{ fontSize: '0.75em' }}>{zoomVideo ? 'Zoom' : 'No zoom'}</span>
                    <StandardSwitch
                        isChecked={zoomVideo ? true : false}
                        onChange={() => setZoomVideo(!zoomVideo)}
                    />
                </div>
                <Button
                    onClick={async () => {
                        dispatch(setShortCaption(title))
                        dispatch(setShortSummary(summary))
                        dispatch(setShortImage(null))
                        setFileToUpload(null)
                        await getMedia()
                    }}
                    color="info"
                    title='Reset to song values'
                    style={{ cursor: 'pointer', }}>Reset</Button>
                <Button
                    onClick={() => createVideoShort()}
                    color="success"
                    title='Create video short'
                    style={{ cursor: 'pointer', }}>Create</Button>

                <div
                    title=
                    'Click to use AI'
                    style={{ ...aiChatStyles.button, paddingLeft: '1em' }}
                    onClick={(evt) => {
                        evt.stopPropagation()
                        setShowAi(!showAi)
                    }}
                >
                    <img
                        src={roundBrain}
                        alt=' '
                        style={{
                            height: '1.5em',
                        }}
                    />

                </div>
            </div>
        )
    }

    const getClipEnd = () => {
        return clipEnd
    }
    const displayClipControls = () => {
        const { duration } = getAudioMedia()
        return (

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: '1px solid white' }} title='Set clip start'
                    onClick={() => {
                        const currentTime = Math.round(player.current.audio.current.currentTime)
                        if (currentTime > clipEnd) {
                            dispatch(setShortClipEnd(currentTime + 1))
                        }
                        dispatch(setShortClipStart(currentTime))
                    }}>
                    <Button
                        color="info"
                        title='Set clip start'
                        style={{ cursor: 'pointer', }}>Start</Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '1em' }} title='Go to clip start'
                    onClick={() => player.current.audio.current.currentTime = clipStart}>
                    <ArrowRight style={{ fontSize: '2em' }} />
                    <NumericInputField getter={() => { return clipStart }}
                        integerOnly
                        fullWidth={false} setter={(value) => dispatch(setShortClipStart(value))}
                        step={1} min={0} max={duration} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '1em' }} title='Go to clip end'
                    onClick={() => player.current.audio.current.currentTime = clipEnd}>
                    <NumericInputField getter={getClipEnd}
                        integerOnly
                        fullWidth={false} setter={(value) => dispatch(setShortClipEnd(value))}
                        step={1} min={clipStart} max={duration} />
                    <ArrowLeft style={{ fontSize: '2em' }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', border: '1px solid white' }} title='Set clip end'
                    onClick={() => {
                        const currentTime = Math.round(player.current.audio.current.currentTime)
                        if (currentTime < clipStart) {
                            dispatch(setShortClipStart(0))
                        }
                        dispatch(setShortClipEnd(currentTime))
                    }
                    }>
                    <Button
                        color="info"
                        title='Set clip end'
                        style={{ cursor: 'pointer', }}>End</Button>
                </div>
            </div>
        )
    }


    const dropped = (filesReceived) => {
        console.log('filesReceived', filesReceived)
        if (filesReceived && filesReceived.length && filesReceived[0]) {
            let file = filesReceived[0]
            setError('')
            console.log('mediadrop file', file)
            const { type: mimeType, name } = file
            const imageSize = getUploadImageSize()
            const imageQuality = getUploadImageQuality()
            resize(file, imageSize, imageSize, imageQuality, async (result) => {
                //dispatch(setShortImage(result))
                setFileToUpload(file)
                setShowUpload(false)
                const { dataUrl } = result
                //setImageSrc(dataUrl)
                dispatch(setShortMediaIcons([...mediaIcons, { index: mediaIcons.length, name, dataUrl, mimeType, file }]))
            })

        } else {
            displayError('No file selected', setError, 2)
        }

    }
    const displayDropzone = () => {
        if (showUpload) {
            return (
                <div style={{ color: 'black', padding: '1em' }}>

                    <MediaDrop
                        mimeTypes={MEDIA_TYPES.IMAGE}
                        prompt={`To select your image, drag and drop or click here`}
                        onSelected={(files) => dropped(files)}
                        onError={setError}
                        maxFileSize={parseInt(process.env.REACT_APP_MAX_HARMONIZE_UPLOAD_BYTES)}
                        multiple={false}
                        uploadAction={() => { }}
                        dropWidth='100%'
                        mediaIcons={mediaIcons}
                        close={(evt) => { evt.stopPropagation(); setShowUpload(false) }}
                    />
                </div>
            )
        }
    }

    const displayShort = () => {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><MediaIcons mediaIcons={() => { return mediaIcons }} imageClicked={() => { }} />
                    <div
                        title='Click to add a song'
                        onClick={() => setShowUpload(true)}
                    >
                        <AddCircle
                            color='primary'
                            style={{
                                zIndex: 200,
                                cursor: 'pointer',
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                borderRadius: '4em',
                                fontSize: '4em',
                            }}
                        />
                    </div>
                </div>
                {editCaption()}
                {editSummary()}
                {editDescription()}
            </div>
        )
    }
    const displayImageAndSummary = () => {
        if (showUpload) {
            return displayDropzone()
        } else if (veryNarrow) {
            return (
                <div style={{ padding: '1em 1em 2em 1em' }}>
                    {/*<img src={imageSrc} style={{ height: '25vh', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                        onClick={() => setShowUpload(true)} />*/}
                    {displayShort()}
                </div>
            )
        } else {
            return (
                <div style={{ height: '100vh', display: 'flex', padding: '1em' }}>

                    {/*<img src={imageSrc} style={{ height: '50%', padding: '1em' }} onClick={() => setShowUpload(true)} />*/}
                    {displayShort()}
                </div>
            )
        }
    }

    const displayControlsAndActions = () => {
        return (
            <div>
                {displayClipControls()}
                {displayActions()}
            </div>
        )
    }
    const displayCreateShort = () => {
        if (showAi) {
            return displayAiChat()
        } else if (url && songRoom) {
            return (
                <div style={{ width: '100vw', position: 'relative', paddingTop: '0.5em' }}>
                    <div style={{ position: 'absolute', left: '0.5em', top: 0, cursor: 'pointer' }} title='Close Video Short'>
                        <Cancel style={{ fontSize: '2em' }} onClick={close} />
                    </div>

                    <ErrorLine error={error} />
                    {displayImageAndSummary()}
                    <div style={{

                        position: 'sticky',
                        bottom: '2em',

                        backgroundColor: 'grey'
                    }}>

                        <AudioPlayer
                            ref={player}
                            src={url}
                            header={displayControlsAndActions()}
                            showFilledVolume
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            onError={(error) => {
                                console.error('Error playing audio', error)
                                setError(`Unable to play `)
                            }}
                            onListen={() => {
                                if (onPlay) {
                                    onPlay()
                                }
                                if (Math.round(player.current.audio.current.currentTime) > clipEnd) {
                                    player.current.audio.current.pause()
                                }
                                //checkCredits()

                            }}
                        />
                    </div>
                </div>
            )
        }

    }

    const displayAiChat = () => {

        return (
            <div style={{ width: '100%' }}><div style={{ display: 'flex' }} title='Close AI'>
                <HighlightOffTwoTone style={{ fontSize: '2em' }} onClick={() => setShowAi(false)} />
            </div>
                <div style={{ backgroundColor: 'white', height: '70vh' }}>
                    <AIChat room={currentRoom} chatHeight='70vh' roomId={currentRoom._id} ownerOnly messageId={messageId} /> </div>
            </div>)


    }

    const displayMain = () => {
        //console.log(`CreateVideoShort isApple ${isApple} isIOS ${isIOS} isMacOs ${isMacOs}`)
        if (creatingShort) {
            return <TimerProgress label='Creating short...' />
        } else if (isApple) {
            return displayCreateShort()
        } else if (objectURL) {
            return (
                <div style={{ position: 'relative' }}>
                    <div style={{ zIndex: 1000, position: 'absolute', top: 0, cursor: 'pointer', display: 'flex', width: '100%', justifyContent: 'center' }}
                        onClick={() => setObjectURL(null)} >
                        <Cancel style={{ color: 'red', fontSize: '2em' }} />
                    </div>
                    <video controls style={veryNarrow ? videoStyles.narrow : videoStyles.normal}>
                        <source src={objectURL} type="video/mp4" />
                    </video>
                </div >
            )
        } else {
            return displayCreateShort()
        }
    }

    const getMedia = async () => {
        await getRoom()
        if (image) {
            console.log('getMedia image', image)
            const { file, dataUrl } = image
            setImageSrc(dataUrl)
            setFileToUpload(file)
        } else {
            const mediaArray = song.media
            const thumbnailMedia = getMediaDisplay(mediaArray)
            const { source } = thumbnailMedia
            const data = await downloadCoreFile(source, 'image')
            const src = URL.createObjectURL(data)
            setImageMedia(getMediaDisplay(mediaArray))
            setImageSrc(src)
        }
        /*
        try {
            const result = await extractColors(src)
            getDominantColor(result)
        } catch (error) {
            displayError(error, setError)
        }
        */
    }

    const getRoom = async () => {
        try {
            const room = await getRoomById(roomId)
            setSongRoom(room)
        } catch (error) {
            displayError(error, setError)
        }
    }

    useEffect(() => {
        getMedia()
        getUrl()
        if (!caption) {
            dispatch(setShortCaption(title))
            setCaptionLength(0)
        }
        if (!shortSummary) {
            dispatch(setShortSummary(summary))
            setShortSummaryLength(0)
        }
    }, [song])

    return (
        displayMain()
    )

}