import * as REDUX_TYPES from '../types'
import Cookies from 'js-cookie'

const ACCOUNT_COOKIE = 'account'
const ACCOUNT_IMG_COOKIE = 'accountImg'
const ACCESS_TOKEN_COOKIE = 'accessToken'
/**
 * OK bummer but because of inconsistent use of the id field in accountReducer 
 * versus the _id field in the account (aka User) instance returned from the server
 * we need to check for both when we want the account id used in this client.
 * 
 * @todo Clean up inconsistency with account id and _id
 * 
 * @returns 
 */
export const getAccountId = () => {
  const account = getStorageAccount()
  if (account) {
    const { _id, id } = account
    if (id) {
      return id
    } else if (_id) {
      return _id
    }
  }
  return null
}

export const getStorageAccount = () => {
  const acct = Cookies.get(ACCOUNT_COOKIE)
  if (acct) {
    const parsedAccount = JSON.parse(acct)
    const img = localStorage.getItem(ACCOUNT_IMG_COOKIE)
    if (img) {
      return { ...parsedAccount, img }
    } else {
      return parsedAccount
    }
  } else {
    return null
  }

}
/**
 * Get the localStorage account and return it as a {type,account} dispatch action
 * @returns
 */
export const reloadReduxAccount = () => {
  const account = getStorageAccount()
  return setAccount(account)
}

export const getStorageAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_COOKIE)
}

/**
 * Save the accessToken received from the Core Service with the secure and
 * sameSite flags, and with no expiration.
 * 
 * When deleting specify the same attributes. "IMPORTANT! When deleting a cookie and you're 
 * not relying on the default attributes, you must pass the exact same path, domain, secure 
 * and sameSite attributes that were used to set the cookie:"
 * 
 * @see https://github.com/js-cookie/js-cookie
 * @param {*} accessToken 
 */
export const setAccessToken = (accessToken) => {
  if (accessToken) {
    Cookies.set(ACCESS_TOKEN_COOKIE, accessToken, {
      //expires: 7, // Optional: Set expiration time (in days)
      secure: true, // Set to true if using HTTPS
      sameSite: 'strict' // Prevent CSRF attacks
    })
  } else {
    Cookies.remove(ACCESS_TOKEN_COOKIE, {
      //expires: 7, // Optional: Set expiration time (in days)
      secure: true, // Set to true if using HTTPS
      sameSite: 'strict' // Prevent CSRF attacks
    })
  }
}

/**
 * If the length of stringified account is too long (as of 2024 they say 4k bytes is max)
 * the Cookies.set is silently ignored. Therefore we split out the account img data into
 * localStorage.
 * @param {*} account 
 */
export const setAccount = (account) => {
  if (account) {
    localStorage.setItem(ACCOUNT_IMG_COOKIE, account.img)
    delete account.img
    Cookies.set(ACCOUNT_COOKIE, JSON.stringify(account), {
      //expires: 7, // Optional: Set expiration time (in days)
      secure: true, // Set to true if using HTTPS
      sameSite: 'strict'
    })
    console.log(`\n--------------> all cookies after setAccount`, Cookies.get())
  } else {
    Cookies.remove(ACCOUNT_COOKIE, {
      //expires: 7, // Optional: Set expiration time (in days)
      secure: true, // Set to true if using HTTPS
      sameSite: 'strict'
    })
    localStorage.removeItem(ACCOUNT_IMG_COOKIE)
  }
}

